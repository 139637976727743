import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Text from '../generics/Text';
import Divider from '../generics/Divider';
import ButtonLoader from '../generics/Button';
import Modal from '../generics/Modal';
import ColumnWrap from '../generics/ColumnWrap';
import lang from '../../lang';

class CardPlans extends Component {
  state = {
    show: false,
    showBankPayment: false,
  };

  hideModal = () => {
    this.setState({
      show: false,
    });
  };

  hideModalBankPayment = () => {
    this.setState({
      showBankPayment: false,
    });
  };

  getDataForPlan = planName => {
    switch (planName) {
      case 'Free':
        return (
          <Text color="#d90458" fontFamily="Metropolis-Medium" fontSize="30px">
            {planName}
          </Text>
        );
      case 'Pro I':
        return (
          <Text color="#6e00be" fontFamily="Metropolis-Medium" fontSize="30px">
            {planName}
          </Text>
        );
      case 'Pro II':
        return (
          <Text color="#333480" fontFamily="Metropolis-Medium" fontSize="30px">
            {planName}
          </Text>
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <Container>
        <BoxTitle>
          {this.getDataForPlan(this.props.item && this.props.item.name)}
        </BoxTitle>
        <div>
          {this.props.item &&
          this.props.item.name &&
          this.props.item.name === 'Free' ? (
            <div>
              <div style={{ height: '46px' }} />
              <WrapPrice>
                <Text
                  fontSize="13px"
                  textAlign="center"
                  fontFamily="Metropolis-Black"
                  color="#333333"
                >
                  $
                </Text>
                <Text
                  fontSize="47px"
                  textAlign="center"
                  fontFamily="Metropolis-Black"
                  color="#333333"
                >
                  0
                </Text>
                <Text
                  fontSize="13px"
                  textAlign="center"
                  fontFamily="Metropolis-Black"
                  color="#333333"
                >
                  00
                </Text>
              </WrapPrice>
              <Description>
                {this.props.item && this.props.item.quantityServices}{' '}
                {lang.simultaneousService}
              </Description>
              <Description>
                {this.props.item && this.props.item.quantityPlaces}{' '}
                {lang.placesPerMonth}
              </Description>
              <Description>
                {this.props.item && this.props.item.quantityPasses}{' '}
                {lang.passesPerMonth}
              </Description>
              <Description>
                {this.props.item && this.props.item.quantityGifts}{' '}
                {lang.giftPerMonth}
              </Description>
              <Description>
                {this.props.item && this.props.item.quantityScheduledPost}{' '}
                {lang.dailyPostProgramming}
              </Description>
              <Description>{lang.limitedStatistics}</Description>
              <Divider
                backgroundColor="#C8C8C8"
                width="100%"
                marginTop="20px"
                marginBottom="20px"
              />
              <Title>{lang.timeToReserve}</Title>
              <Description>{lang.monthly}</Description>
              <Title>{lang.homeDisplay}</Title>
              <Description>{lang.followers}</Description>
              <Title>{lang.seatsPassesAndAdditionalGifts}</Title>
              <Description>{lang.notApply}</Description>
              {this.props.planUser.name === this.props.item.name ? (
                <div
                  className="total-center flex-column"
                  style={{ marginTop: '20px' }}
                >
                  <img src="/assets/images/plan.svg" alt="icon" height="40px" />
                  <Title>{lang.currentPlan}</Title>
                </div>
              ) : null}
            </div>
          ) : null}
          {this.props.item.name === 'Pro I' ||
          this.props.item.name === 'Pro II' ? (
            <div>
              <div style={{}}>
                <Text
                  fontSize="12px"
                  textAlign="center"
                  marginTop="10px"
                  fontFamily="Roboto"
                  fontWeight="800"
                  color="#333333"
                >
                  {lang.quarterlyCost} $59.97
                </Text>
                <Text
                  fontSize="12px"
                  textAlign="center"
                  fontFamily="Roboto"
                  fontWeight="800"
                  color="#333333"
                >
                  {lang.semesterCost} $119,94
                </Text>
                <Text
                  fontSize="12px"
                  textAlign="center"
                  fontFamily="Roboto"
                  fontWeight="800"
                  color="#333333"
                >
                  {lang.annualCost} $239,88
                </Text>
              </div>
              <WrapPrice>
                <Text
                  fontSize="13px"
                  textAlign="center"
                  fontFamily="Metropolis-Black"
                  color="#333333"
                >
                  $
                </Text>
                <Text
                  fontSize="47px"
                  textAlign="center"
                  fontFamily="Metropolis-Black"
                  color="#333333"
                >
                  {this.props.item && this.props.item.pricePlan
                    ? this.props.item.pricePlan[1].price
                    : null}
                </Text>
                <div>
                  <Text
                    fontSize="13px"
                    textAlign="center"
                    fontFamily="Metropolis-Black"
                    color="#333333"
                  >
                    99
                  </Text>
                  <Text
                    fontSize="13px"
                    textAlign="center"
                    fontFamily="Metropolis-Black"
                    marginTop="10px"
                    marginLeft="10px"
                    color="#333333"
                  >
                    / {lang.month}
                  </Text>
                </div>
              </WrapPrice>
              <Description>
                {this.props.item && this.props.item.quantityServices}{' '}
                {lang.simultaneousService}
              </Description>
              <Description>
                {this.props.item && this.props.item.quantityPlaces}{' '}
                {lang.placesPerMonth}
              </Description>
              <Description>
                {this.props.item && this.props.item.quantityPasses}{' '}
                {lang.passesPerMonth}
              </Description>
              <Description>
                {this.props.item && this.props.item.quantityGifts}{' '}
                {lang.giftPerMonth}
              </Description>
              <Description>
                {this.props.item && this.props.item.quantityScheduledPost}{' '}
                {lang.dailyPostProgramming}
              </Description>
              <Description>{lang.detailedStatistics}</Description>
              <Divider
                backgroundColor="#C8C8C8"
                width="100%"
                marginTop="20px"
                marginBottom="20px"
              />
              <Title>{lang.timeToReserve}</Title>
              <Description>{lang.annual}</Description>
              <Title>{lang.homeDisplay}</Title>
              <Description>{lang.followersAndCityOfRegistration}</Description>
              <Title>{lang.seatsPassesAndAdditionalGifts}</Title>
              <Description>{lang.apply}</Description>
              {this.props.planUser.name === this.props.item.name ? (
                <div
                  className="total-center flex-column"
                  style={{ marginTop: '20px' }}
                >
                  <img src="/assets/images/plan.svg" alt="icon" height="40px" />
                  <Title>{lang.currentPlan}</Title>
                </div>
              ) : (
                <div className="total-center flex-column">
                   <Text
                    marginTop="5px"
                    color="#333680"
                    fontFamily="Roboto"
                    textAlign="Center"
                    marginRight="5px"
                    marginLeft="5px"
                    //className="opacity"
                  >
                    Para adquirir el plan contáctanos a través del chat 
                  </Text>

                {/*  <ButtonLoader
                    backgroundColor="#8cc63f"
                    maxWidth="150px"
                    height="35px"
                    onClick={() => this.setState({ show: true })}
                  >
                    {lang.acquirePlan}
                  </ButtonLoader>
                  <Text
                    marginTop="5px"
                    color="#66bbff"
                    fontFamily="Roboto"
                    className="opacity"
                  >
                    {lang.tryForFree}
                  </Text> */}
                </div>
              )}
            </div>
          ) : null}
          {this.props.pro2 ? (
            <div>
              <div style={{}}>
                <Text
                  fontSize="12px"
                  textAlign="center"
                  marginTop="10px"
                  fontFamily="Roboto"
                  fontWeight="800"
                  color="#333333"
                >
                  {lang.quarterlyCost} $59.97
                </Text>
                <Text
                  fontSize="12px"
                  textAlign="center"
                  fontFamily="Roboto"
                  fontWeight="800"
                  color="#333333"
                >
                  {lang.semesterCost} $119,94
                </Text>
                <Text
                  fontSize="12px"
                  textAlign="center"
                  fontFamily="Roboto"
                  fontWeight="800"
                  color="#333333"
                >
                  {lang.annualCost} $239,88
                </Text>
              </div>
              <WrapPrice>
                <Text
                  fontSize="13px"
                  textAlign="center"
                  fontFamily="Metropolis-Black"
                  color="#333333"
                >
                  $
                </Text>
                <Text
                  fontSize="47px"
                  textAlign="center"
                  fontFamily="Metropolis-Black"
                  color="#333333"
                >
                  19
                </Text>
                <div>
                  <Text
                    fontSize="13px"
                    textAlign="center"
                    fontFamily="Metropolis-Black"
                    color="#333333"
                  >
                    99
                  </Text>
                  <Text
                    fontSize="13px"
                    textAlign="center"
                    marginTop="10px"
                    marginLeft="10px"
                    fontFamily="Metropolis-Black"
                    color="#333333"
                  >
                    /{lang.month}
                  </Text>
                </div>
              </WrapPrice>
              <Description>30 {lang.simultaneousServices}</Description>
              <Description>1000 {lang.placesPerMonth}</Description>
              <Description>300 {lang.passesPerMonth}</Description>
              <Description>300 {lang.giftPerMonth}</Description>
              <Description>10 {lang.dailyPostProgramming}</Description>
              <Description>{lang.detailedStatistics}</Description>
              <Divider
                backgroundColor="#C8C8C8"
                width="100%"
                marginTop="20px"
                marginBottom="20px"
              />
              <Title>{lang.timeToReserve}</Title>
              <Description>{lang.annual}</Description>
              <Title>{lang.homeDisplay}</Title>
              <Description>{lang.followersAndCityOfRegistration}</Description>
              <Title>{lang.seatsPassesAndAdditionalGifts}</Title>
              <Description>{lang.apply}</Description>
              <div className="total-center flex-column">
                <ButtonLoader
                  backgroundColor="#8cc63f"
                  maxWidth="150px"
                  height="35px"
                  onClick={() => this.setState({ show: true })}
                >
                  {lang.acquirePlan}
                </ButtonLoader>
                <Text
                  marginTop="5px"
                  color="#66bbff"
                  fontFamily="Roboto"
                  className="opacity"
                >
                  {lang.tryForFree}
                </Text>
              </div>
            </div>
          ) : null}
        </div>
        <Modal alwaysShowClose show={this.state.show} onHide={this.hideModal}>
          <ContainerModal>
            <Text color="#232323" fontFamily="Roboto" textAlign="center">
              {lang.howDoYouWantToMakeYourPayment}
            </Text>
            <ColumnWrap marginTop="20px" justifyItems="center" colXs="1fr">
              <ButtonModal>{lang.onlinePayment}</ButtonModal>
              <ButtonModal
                onClick={() =>
                  this.setState({ showBankPayment: true, show: false })
                }
              >
                {lang.bankPayment}
              </ButtonModal>
            </ColumnWrap>
          </ContainerModal>
        </Modal>
        <Modal
          alwaysShowClose
          show={this.state.showBankPayment}
          onHide={this.hideModalBankPayment}
        >
          <ContainerModal minHeight="320px">
            <Text
              color="#232323"
              fontFamily="Roboto-Bold"
              textAlign="center"
              fontSize="18px"
            >
              {lang.bankPayment}
            </Text>
            <ColumnWrap marginTop="20px" justifyItems="center" colXs="1fr">
              <InputModal type="text" name="" id="" placeholder={lang.name} />
              <InputModal
                type="text"
                name=""
                id=""
                placeholder={lang.lastName}
              />
            </ColumnWrap>
            <Textarea
              placeholder={lang.paymentRemarks}
              style={{
                maxHeight: '80px',
                minHeight: '80px',
                maxWidth: '96%',
                minWidth: '96%',
              }}
            ></Textarea>
            {/* <Textarea placeholder={lang.paymentRemarks}></Textarea> */}
            <Text
              className="opacity"
              fontFamily="Roboto"
              fontWeight="300"
              fontSize="13px"
              color="#808080"
              onClick={() => document.getElementById('file_92').click()}
            >
              {lang.attachImage}
            </Text>
            <input
              type="file"
              name=""
              style={{ display: 'none' }}
              id="file_92"
            />
            <div className="total-center">
              <ButtonModal>{lang.iAgree}</ButtonModal>
            </div>
          </ContainerModal>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  console.log('state', state);
  return {
    planUser: state.planStore.planUser && state.planStore.planUser.plan,
  };
};

export default connect(
  mapStateToProps,
  {},
)(CardPlans);

const ButtonModal = styled.div`
    background-color: #ffffff;
    color: #808080;
    border: solid 1px #808080;
    font-family: 'Roboto',sans-serif;
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    max-width: 150px;
    height: 30px;
    border-radius: 20px;
    padding-left: 5px; 
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    :hover {
    opacity: 0.8;
  }
  :active {
    opacity: 0.1;
  }
}
`;
const ContainerModal = styled.div`
  width: 450px;
  min-height: ${props => props.minHeight || '150px'};
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media (max-width: 620px) {
    width: auto;
  }
  max-height: 400px;
  overflow-y: auto;
`;
const BoxTitle = styled.div`
  background-color: #f2f2f2;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const InputModal = styled.input`
  width: 100%;
  max-width: 200px;
  background: none;
  border: none;
  border-bottom: solid 1px #cccccc;
  margin-left: 5px;
  outline: none;
  :focus {
    border-bottom: solid 1px #66bbff;
  }
  ::placeholder {
    font-family: 'Roboto-LightItalic';
    font-size: 14px;
    color: #808080;
  }
`;
const Textarea = styled.textarea`
  width: calc(100% - 15px);
  height: 80px;
  padding-left: 15px;
  border-radius: 5px;
  border: solid 1px #b7b8b8;
  outline: none;
  text-align: left;
  background-color: #fff;
  :focus {
    border-style: solid;
    border-color: #66bbff;
    border-width: 1px;
  }
  ::placeholder {
    font-family: 'Roboto-LightItalic';
    font-size: 16px;
    color: #808080;
  }
`;
const Container = styled.div`
  width: 100%;
  max-width: 280px;
  height: 620px;
  /* border: solid 3px #CCC; */
  box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.42);
  padding-bottom: 20px;
`;
const Title = styled.p`
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 16px;
  color: #59595b;
  text-align: center;
  margin: 10px 0px;
`;
const Description = styled.p`
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 14px;
  color: #59595b;
  text-align: center;
  margin: 10px 0px;
`;
const WrapPrice = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: ${props => props.marginTop || '20px'};
  margin-bottom: ${props => props.marginBottom || '30px'};
`;
