import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import moment from 'moment';
import 'moment/locale/es';
import styled from 'styled-components';
import {
  MdEdit,
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdClear,
} from 'react-icons/md';
import IconButton from '@material-ui/core/IconButton';
import { Snackbar, SnackbarContent } from '@material-ui/core/';
import Text from '../generics/Text';
import { loginInit } from '../../ducks/access';
import {
  putEditProfilePictureUser,
  putEditProfileCoverUser,
  putUserId,
} from '../../ducks/user';
import lang from '../../lang';
import TextField from '@material-ui/core/TextField';
import './SideProfile.css';
import Button from '@material-ui/core/Button';
import AbouthMe from './AbouthMe'

moment.locale('es');

class SideProfile extends Component {
  state = {
    show: false,
    avatar:
      this.props.data && this.props.data.responseInfo
        ? this.props.data.responseInfo.user.profilePicture.highQuality
        : '',
    cover:
      this.props.data && this.props.data.responseInfo
        ? this.props.data.responseInfo.user.coverPicture.highQuality
        : '',
    showMessage: false,
    message: '',
    errorMessage: '',
    abouthMe: this.props.data && this.props.data.responseInfo
    ? this.props.data.responseInfo.user.abouthMe
    : '',
  };

  traer = input => {
    const AbouthMe = document.getElementById('AbouthMe');
    const reader = new FileReader();
      reader.onload = e => {
        this.setState({
          cover: e.target.result,
        });
      };
      reader.readAsDataURL(input.files[0]);
      const storageRef = firebase.storage().ref(`/user`);
      storageRef.put(AbouthMe).then(snapshot => {
        firebase
          .storage()
          .ref(`/${snapshot.metadata.fullPath}`)
          .getDownloadURL()
          .then(Response => {
            const abouthMe = Response;
            let data = {
              abouthMe: abouthMe,
              putUserId: this.props.data.responseInfo.user.id,
            };
            this.props.putUserId(data, Response => {
              if (Response.code >= 200 && Response.code <= 299) {
                this.setState({
                  showMessage: true,
                  message: lang.editAbouthMe,
                });
              } else {
                this.setState({
                  showMessage: true,
                  errorMessage: Response.message,
                });
              }
            });
          });
      });
    };

  readURL1 = input => {
    const file = document.getElementById('file').files[0];
    const reader = new FileReader();
    reader.onload = e => {
      this.setState({
        avatar: e.target.result,
      });
    };
    reader.readAsDataURL(input.files[0]);
    const storageRef = firebase.storage().ref(`/images/pass/${file.name}`);
    storageRef.put(file).then(snapshot => {
      firebase
        .storage()
        .ref(`/${snapshot.metadata.fullPath}`)
        .getDownloadURL()
        .then(Response => {
          const urlImg = Response;
          let data = {
            profilePicturehighQuality: urlImg,
            uid: this.props.data.responseInfo.user.id,
          };
          this.props.putEditProfilePictureUser(data, Response => {
            if (Response.code >= 200 && Response.code <= 299) {
              this.setState({
                showMessage: true,
                message: lang.theProfileImageWasSuccessfullyChanged,
              });
              const newProfilePicture = JSON.stringify(this.props.data);
              const parseNewProfilePicture = JSON.parse(newProfilePicture);
              parseNewProfilePicture.responseInfo.user.profilePicture.highQuality = urlImg;
              parseNewProfilePicture.responseInfo.user.profilePicture.lowQuality = urlImg;
              parseNewProfilePicture.responseInfo.user.profilePicture.miniature = urlImg;
              this.props.loginInit(parseNewProfilePicture);
            } else {
              this.setState({
                showMessage: true,
                errorMessage: Response.message,
              });
            }
          });
        });
    });
  };

  readURL2 = input => {
    const file = document.getElementById('file_2').files[0];
    const reader = new FileReader();
    reader.onload = e => {
      this.setState({
        cover: e.target.result,
      });
    };
    reader.readAsDataURL(input.files[0]);
    const storageRef = firebase.storage().ref(`/images/pass/${file.name}`);
    storageRef.put(file).then(snapshot => {
      firebase
        .storage()
        .ref(`/${snapshot.metadata.fullPath}`)
        .getDownloadURL()
        .then(Response => {
          const urlImg = Response;
          let data = {
            coverPicturehighQuality: urlImg,
            uid: this.props.data.responseInfo.user.id,
          };
          this.props.putEditProfileCoverUser(data, Response => {
            if (Response.code >= 200 && Response.code <= 299) {
              this.setState({
                showMessage: true,
                message: lang.theBackgroundImageWasSuccessfullyChanged,
              });
              const newProfilePicture = JSON.stringify(this.props.data);
              const parseNewProfilePicture = JSON.parse(newProfilePicture);
              parseNewProfilePicture.responseInfo.user.coverPicture.highQuality = urlImg;
              parseNewProfilePicture.responseInfo.user.coverPicture.lowQuality = urlImg;
              parseNewProfilePicture.responseInfo.user.coverPicture.miniature = urlImg;
              this.props.loginInit(parseNewProfilePicture);
            } else {
              this.setState({
                showMessage: true,
                errorMessage: Response.message,
              });
            }
          });
        });
    });
  };

  handleClose = () => {
    this.setState({
      showMessage: false,
    });
  };

  Button = () => {
    this.setState({
      ...this.state.abouthMe,
    });
  }; 

  render() {
    return (
      <div>
        <ContainerArrow
          left={this.state.show ? '270px' : null}
          onClick={() =>
            this.setState({
              show: !this.state.show,
            })
          }
        >
          {this.state.show ? <MdKeyboardArrowLeft /> : <MdKeyboardArrowRight />}
        </ContainerArrow>
        <Container
          left={this.state.show ? '0%' : '-100%'}
          style={
            this.props.data && this.props.data.responseInfo
              ? {
                  backgroundImage: `url('/assets/images/fondo.png')`,
                }
              : null
          }
        >
          <BoxAvatar>
            <WrapAvatar>
              {this.props.data && this.props.data.responseInfo ? (
                <Avatar src={this.state.avatar} alt="logo" />
              ) : null}
              <Edit>
                <WrapEdit marginTop="-20px">
                  <Text
                    color="#FFF"
                    fontFamily="Roboto"
                    fontWeight="300"
                    fontSize="17px"
                    marginRight="5px"
                    className="opacity"
                    onClick={() => document.getElementById('file').click()}
                  >
                    {lang.editPhoto}
                  </Text>
                  <input
                    type="file"
                    id="file"
                    accept="image/png,image/jpeg"
                    style={{ display: 'none' }}
                    onChange={obj => this.readURL1(obj.target)}
                    // onMouseOver={this.handleOverMouse}
                  />
                  <MdEdit color="#FFF" size="25px" />
                </WrapEdit>
              </Edit>
            </WrapAvatar>
          </BoxAvatar>
          <GradienteTop>
            <Text
              color="#FFF"
              fontFamily="Roboto-Bold"
              fontSize="24px"
              textAlign="center"
              marginTop="-20px"
            >
              {this.props.data && this.props.data.responseInfo
                ? this.props.data.responseInfo.user.userName
                : ''}
            </Text>
            {this.props.data && this.props.data.responseInfo ? (
              <div>
                {this.props.data.responseInfo.user &&
                this.props.data.responseInfo.user.name ? (
                  <Text
                    color="#FFF"
                    fontFamily="Roboto"
                    fontWeight="500"
                    fontSize="17px"
                    textAlign="center"
                  >
                    {this.props.data.responseInfo.user.name.first +
                      ' ' +
                      this.props.data.responseInfo.user.name.last}
                  </Text>
                ) : null}
                {this.props.data.responseInfo.user &&
                this.props.data.responseInfo.user.companyName ? (
                  <Text
                    color="#FFF"
                    fontFamily="Roboto"
                    fontWeight="300"
                    fontSize="17px"
                    textAlign="center"
                  >
                    {this.props.data.responseInfo.user.companyName}
                  </Text>
                ) : null}
              </div>
            ) : null}           
          <WrapIconText>
              <Icon
                src="/assets/images/icon-location.svg"
                alt="calendar"
                width="auto"
                height="15px"               
                marginTop="-15px"
              />
              <Text
                color="#FFF"
                fontFamily="Roboto"
                fontWeight="500"
                fontSize="14px"
                marginRight='20px'
              >
                {this.props.data && this.props.data.responseInfo
                  ? this.props.data.responseInfo.user.idState
                  : ''}
              </Text>
              <Icon
                src="/assets/images/icon-calendar.svg"
                alt="calendar"                
                width="auto"
                //justify-content="flex-end"
                height="15px"
                marginLeft='20px' 
              />
              <Text
                color="#fff"
                fontFamily="Roboto"
                fontWeight="500"
                fontSize="14px"        
              >                
                {lang.joinedIn}{' '}
                {this.props.data && this.props.data.responseInfo
                  ? moment(this.props.data.responseInfo.user.activeDate).format(
                      'YYYY',
                    )
                  : ''}
              </Text>
           </WrapIconText>           
          </GradienteTop>
                    
        {/* <GradienteBottom>    
         <Text
                    color="#1a1a1a"
                    fontFamily="Roboto"
                    fontWeight="600"
                    fontSize="14px"
                    textAlign="left"
                    marginLeft="20px"
                  >
                    Desde nuestra aplicación web podrás crear o editar los servicios que ofrecerás como proveedor y pagar la suscripción de tu plan. Luego, accede a nuestra aplicación móvil para interactuar con la comunidad de clientes y disfrutar todas las herramientas que tenemos para ti.
                  </Text>       
        </GradienteBottom> */}
          
          <form >
          {/*<TextField
                id="filled-multiline-static"
                label="Sobre mi"
                variant="filled"
               defaultValue={this.state.abouthMe}
              //defaultValue={ this.AbouthMe}
                id="AbouthMe"
                className="abouthme"
                multiline
                rows={5}
                name="abouthMe"
                // onChange={value => this.changeAbouthMe(value.target)}
              >
          </TextField>*/}
              {/* <Button 
              variant="contained" 
              type="submit" 
              color="primary" 
              className="ButonGuardar"
              onClick={this.Button}
              >
                Guardar
              </Button> */}
              </form>
          {/* <EditPhoto>
            <Text
              color="#FFF"
              fontFamily="Roboto"
              fontWeight="300"
              fontSize="17px"
              marginRight="5px"
              className="opacity"
              textAlign="right"
              onClick={() => document.getElementById('file_2').click()}
            >
              {lang.editCoverPhoto}
              <MdEdit color="#FFF" size="25px" />
            </Text>
            <input
              type="file"
              id="file_2"
              style={{ display: 'none' }}
              accept="image/png,image/jpeg"
              onChange={obj => this.readURL2(obj.target)}
            />
          </EditPhoto> */}
        </Container>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.showMessage}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <SnackbarContent
            style={
              this.state.errorMessage
                ? { backgroundColor: 'red' }
                : { backgroundColor: 'green' }
            }
            // className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
              // <span id="client-snackbar" className={classes.message}>
              <div>
                {this.state.message
                  ? this.state.message
                  : this.state.errorMessage}
              </div>
            }
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={this.handleClose}
              >
                <MdClear onClick={this.handleClose} />
              </IconButton>,
            ]}
          />
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = state => {
  // console.log(state.accessStore.loginStore.data);
  return {
    data: state.accessStore.loginStore.data,
  };
};
export default connect(
  mapStateToProps,
  { putUserId, loginInit, putEditProfilePictureUser, putEditProfileCoverUser },
)(SideProfile);


const EditPhoto = styled.div`
  width: 300px;
  height: 50px;
  position: fixed;
  bottom: 0px;
  background: rgba(0, 0, 0, 0);
  color: #fff;
  display: none;
  justify-content: flex-end;
  align-items: center;
  fontbackground: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.03) 2%,
    rgba(0, 0, 0, 0.52) 34%,
    rgba(0, 0, 0, 0.57) 37%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(2%, rgba(0, 0, 0, 0.03)),
    color-stop(34%, rgba(0, 0, 0, 0.52)),
    color-stop(37%, rgba(0, 0, 0, 0.57)),
    color-stop(100%, rgba(0, 0, 0, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.03) 2%,
    rgba(0, 0, 0, 0.52) 34%,
    rgba(0, 0, 0, 0.57) 37%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.03) 2%,
    rgba(0, 0, 0, 0.52) 34%,
    rgba(0, 0, 0, 0.57) 37%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.03) 2%,
    rgba(0, 0, 0, 0.52) 34%,
    rgba(0, 0, 0, 0.57) 37%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.03) 2%,
    rgba(0, 0, 0, 0.52) 34%,
    rgba(0, 0, 0, 0.57) 37%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );

  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 285px;
  }
`;
const GradienteBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
  width: calc(100% - 50px);
  height: auto;  
  margin-top: 65px;
  //margin: ${props => props.margin};
  //border: solid 2px #DA0458;
  border-radius: 8px;
  padding: 10px 10px;      

  //border-top: solid 1px #fff;
  background: rgba(0, 0, 0, 1);
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.57) 63%,
    rgba(0, 0, 0, 0.52) 66%,
    rgba(0, 0, 0, 0.03) 98%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 1)),
    color-stop(63%, rgba(0, 0, 0, 0.57)),
    color-stop(66%, rgba(0, 0, 0, 0.52)),
    color-stop(98%, rgba(0, 0, 0, 0.03)),
    color-stop(100%, rgba(0, 0, 0, 0))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.57) 63%,
    rgba(0, 0, 0, 0.52) 66%,
    rgba(0, 0, 0, 0.03) 98%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.57) 63%,
    rgba(0, 0, 0, 0.52) 66%,
    rgba(0, 0, 0, 0.03) 98%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.57) 63%,
    rgba(0, 0, 0, 0.52) 66%,
    rgba(0, 0, 0, 0.03) 98%,
    rgba(0, 0, 0, 0) 100%
  );
    //Gradiente inferior
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 63%,
    rgba(0, 0, 0, 0) 66%,
    rgba(0, 0, 0, 0) 98%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
`;
const GradienteTop = styled.div`
  width: calc(100% - 40px);
  height: 50px;
  margin-top: ${props => props.marginTop || '2px'};
  padding: 0px 20px 20px 20px;
  background: rgba(0, 0, 0, 0);
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.03) 2%,
    rgba(0, 0, 0, 0.52) 34%,
    rgba(0, 0, 0, 0.57) 37%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(2%, rgba(0, 0, 0, 0.03)),
    color-stop(34%, rgba(0, 0, 0, 0.52)),
    color-stop(37%, rgba(0, 0, 0, 0.57)),
    color-stop(100%, rgba(0, 0, 0, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.03) 2%,
    rgba(0, 0, 0, 0.52) 34%,
    rgba(0, 0, 0, 0.57) 37%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.03) 2%,
    rgba(0, 0, 0, 0.52) 34%,
    rgba(0, 0, 0, 0.57) 37%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.03) 2%,
    rgba(0, 0, 0, 0.52) 34%,
    rgba(0, 0, 0, 0.57) 37%,
    rgba(0, 0, 0, 1) 100%
  );
  //Gradiente superior
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 2%,
    rgba(0, 0, 0, 0) 34%,
    rgba(0, 0, 0, 0) 37%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
`;
const ContainerArrow = styled.div`
  width: calc(40px - 20px);
  height: 40px;
  background-color: #6e00bf;
  position: fixed;
  margin-top: 49vh;
  border-radius: 10px;
  left: ${props => props.left || '-15px'};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 30px;
  padding-left: 20px;
  display: none;
  @media (max-width: 768px) {
    display: flex;
  }
  cursor: pointer;
  :active {
    background-color: #6e00bf;
  }
`;
const Container = styled.div`
  width: 310px;
  max-width: 300px;
  height: calc(100vh - 70px);
  position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* @media (max-width: 1200px) {
    max-width: 250px;
  } */
  @media (max-width: 768px) {
    position: fixed;
    left: ${props => props.left || '-100%'};
    height: 100vh;
    max-width: 285px;
  }
  &:hover ${EditPhoto} {
    display: flex;
  }
`;
const Avatar = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 8px;
  object-fit: cover;
  background:linear-gradient(#ccc, #ccc) padding-box,
  linear-gradient(90deg, rgba(51,52,128,1) 0%, rgba(110,0,191,1) 100%) border-box;
  border: solid 3px transparent;
  @media (max-height: 600px) {
    width: 150px;
    height: 150px;
  }
`;
const Edit = styled.div`
  width: 250px;
  height: 200px;
  border-radius: 8px;
  object-fit: cover;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: none;
  align-items: flex-end;
  justify-content: center;
  @media (max-height: 600px) {
    height: 150px;
    width: 150px;
  }
  background: -moz-linear-gradient(
    top,
    rgba(101, 150, 241, 0) 0%,
    rgba(1, 2, 2, 1) 99%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(101, 150, 241, 0)),
    color-stop(99%, rgba(1, 2, 2, 1)),
    color-stop(100%, rgba(0, 0, 0, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(101, 150, 241, 0) 0%,
    rgba(1, 2, 2, 1) 99%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(101, 150, 241, 0) 0%,
    rgba(1, 2, 2, 1) 99%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(101, 150, 241, 0) 0%,
    rgba(1, 2, 2, 1) 99%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(101, 150, 241, 0) 0%,
    rgba(1, 2, 2, 1) 99%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6596f1', endColorstr='#000000', GradientType=0 );
`;

const WrapAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  &:hover ${Edit} {
    display: flex;
  }
  margin-top: 10px;
`;
const BoxAvatar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 270px;
  margin-bottom: 0px;
  @media (max-height: 600px) {
    height: 180px;
  }
`;
const WrapIconText = styled.div`
  display: flex;
  align-items: space-around;
  margin-top: ${props => props.marginTop || '5px'};
  margin-right: ${props => props.marginRight || '10px'};
  margin-left: ${props => props.marginLeft || '10px'};
`;
const WrapEdit = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  margin-top: -20px;
`;
const Icon = styled.img`
  width: auto;
  height: 20px;
  margin-right: 10px;
`;

