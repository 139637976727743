import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { MdAdd } from 'react-icons/md';
import Header from '../../components/Layout/Header';
import SideProfile from '../../components/home/SideProfile';
import ColumnWrap from '../../components/generics/ColumnWrap';
import Collapse from '../../components/home/Collapse';
import CardServices from '../../components/home/CardServices';
import Text from '../../components/generics/Text';
import ButtonLoader from './../../components/generics/Button';
import ButtonLoader2 from './../../components/generics/Button2';
import CardPlans from '../../components/home/CardPlans';
// import TransactionsTable from '../../components/home/TransactionsTable';
import ServiceModal from '../service/containers/ServiceModal';


import {
  getServicesUser,
  listServicesUser,
  errorListServicesUser,
} from '../../ducks/services';
import {
  getUserPlan,
  startPlanUser,
  detailPlanUser,
  errorPlanUser,
  getDetailPlan,
  startListPlan,
  listPlan,
  errorPlanList,
  getListPlan,
} from '../../ducks/plan';
import {
  getMethodPayment,
  getListPayment,
  getCategories,
  getListCategories,  
} from '../../ducks/general';

import lang from '../../lang'

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      service: false,
      plans: true,
      openService: false,
      message: '',
      showMessage: false,
    };
  }

  componentWillMount() {
    if (this.props.serviceList && this.props.serviceList.length > 0) {
      this.handleGetServicesUser();
    } else {
      this.props.listServicesUser([], true);
      this.handleGetServicesUser();
    }
    if (this.props.planUser && this.props.planUser.id) {
      this.handleGetPlanUser();
    } else {
      this.props.startPlanUser(true);
      this.handleGetPlanUser();
    }
    this.handleGetPayment();
    this.handleGetCategories();
    this.handleGetListPlan();
  }

  handleGetCategories = () => {
    this.props.getCategories(Response => {
      if (Response.code >= 200 && Response.code <= 299) {
        if (
          Response.data.responseInfo.length &&
          Array.isArray(Response.data.responseInfo)
        ) {
          this.props.getListCategories(Response.data.responseInfo);
        } else {
          this.props.getListCategories([]);
        }
      } else {
        this.props.getListCategories([]);
      }
    });
  };

  handleGetPayment = () => {
    const data = {
      uid: this.props.detail.responseInfo.user.id,
    };
    this.props.getMethodPayment(data, Response => {
      if (Response.code >= 200 && Response.code <= 299) {
        if (
          Response.data.responseInfo.length > 0 &&
          Array.isArray(Response.data.responseInfo)
        ) {
          this.props.getListPayment(Response.data.responseInfo);
        } else {
          this.props.getListPayment([]);
        }
      } else {
        this.props.getListPayment([]);
      }
    });
  };

  handleGetServicesUser = () => {
    this.props.getServicesUser(
      this.props.detail.responseInfo.user.id,
      Response => {
        if (Response.code >= 200 && Response.code <= 299) {
          if (
            Array.isArray(Response.data.responseInfo) &&
            Response.data.responseInfo.length > 0
          ) {
            this.props.listServicesUser(Response.data.responseInfo, false);
          } else {
            this.setState({
              service: true,
            });
            this.props.listServicesUser([], false);
          }
        } else {
          this.props.errorListServicesUser(Response.message);
        }
      },
    );
  };

  handleGetPlanUser = () => {
    const data = {
      uid: this.props.detail.responseInfo.user.id,
    };
    this.props.getUserPlan(data, Response => {
      if (Response.code >= 200 && Response.code <= 299) {
        let data = {
          id: Response.data && Response.data.responseInfo.idPlan,
          uid: Response.data && Response.data.responseInfo.uid,
        };
        if (data.id !== '') {
          this.props.getDetailPlan(data, result => {
            if (result.code >= 200 && result.code <= 299) {
              Response.data.responseInfo[`name`] =
                result.data.responseInfo.name;
              this.props.detailPlanUser(Response.data.responseInfo, false);
            } else {
              this.props.errorPlanUser(result.message);
            }
          });
        }
      } else {
        this.props.errorPlanUser(Response.message);
      }
    });
  };

  handleGetListPlan = () => {
    this.props.startListPlan(true);
    this.props.getListPlan(this.props.user, Response => {
      if (Response.code >= 200 && Response.code <= 299) {
        if (
          Array.isArray(Response.data.responseInfo) &&
          Response.data.responseInfo.length > 0
        ) {
          this.props.listPlan(Response.data.responseInfo, false);
        } else {
          this.props.listPlan([], false);
        }
      } else {
        this.props.errorPlanList(Response.message);
      }
    });
  };

  // handleCreateService = () => {
  //     this.setState({ openService: true });
  // }

  handleCreateService = () => {        
    if (this.props.planUser.balanceQuantityServices > 0) {
    this.setState({ openService: true });
  }
    else {      
      this.setState({ showMessage: true, message: lang.serviceError });
    }
  }

  handleCloseCreateService = () => {
      this.setState({ openService: false });
  }

  render() {
    return (
      <div>
        <ServiceModal
          open={this.state.openService}
          onClose={this.handleCloseCreateService}
          />

        <Header history={this.props.history} />
        <ColumnWrap
          col="310px 1fr"
          gridGrap="10px"
          colSm="1fr"
          marginTop="70px"
        >
          <SideProfile />
          <div>
            <Collapse title={lang.myPlan} plans>
              {this.state.plans ? (
                <div>
                  {this.props.loadingPlan ? (
                    <div className="total-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    <GridCardPlans>
                      {this.props.list && this.props.list.length > 0
                        ? this.props.list.map((element, key) => {
                            if (
                              element.name === 'Free' ||
                              element.name === 'Pro I' ||
                              element.name === 'Pro II'
                            ) {
                              return <CardPlans key={key} item={element} />;
                            }
                            return null;
                          })
                        : null}
                    </GridCardPlans>
                  )}
                  <br />
                  <br />
                  <br />
                  <br />
                  {/*<Collapse title="Transacciones" showCollapse>
                     <TransactionsTable />
                    <TransactionsTable />
                    <TransactionsTable />  esto no va hasta que esten
                    los metodos de pago 
                    <TextDownload>
                      <span>
                        <img
                          src="/assets/images/download-on.svg"
                          alt="icon"
                          height={15}
                        />
                      </span>{' '}
                      {lang.downloadSummaryPdf}
                    </TextDownload>
                  </Collapse>*/}
                </div>
              ) : null}
            </Collapse>
            <Collapse title={lang.myServices} showCollapse>
              {this.state.service ? (
                <div
                  className="total-center flex-column"
                  style={{ minHeight: '60vh' }}
                >
                  <img
                    src="/assets/images/service-off.svg"
                    alt=""
                    width="auto"
                    height="100px"
                  />
                  <Text
                    color="#59595B"
                    fontFamily="Roboto"
                    fontWeight="600"
                    textAlign="center"
                    marginTop="30px"
                  >
                    {lang.doClickInCreateService}
                  </Text>
                  <Text color="#59595B" fontFamily="Roboto" marginTop="-25">
                    {lang.toEnhanceBooking}
                  </Text>
                  <ButtonLoader2
                    onClick={this.handleCreateService}
                    maxWidth="180px"
                    height="30px"
                    marginTop="20px">
                    Crear servicio <MdAdd color="#FFF" size="25px" />

                  </ButtonLoader2>
                </div>
              ) : (
                <div>
                  <ColumnWrap col="1fr 190px">
                    <WrapIconText>
                      <Icon src="/assets/images/service.svg" alt="icon" />
                      <Text
                        color="#58595B"
                        fontFamily="Roboto-Bold"
                        fontSize="20px"
                      >
                        {this.props.serviceList &&
                          this.props.serviceList.length}{' '}
                        {lang.services}
                      </Text>
                    </WrapIconText>


                    <ButtonLoader2
                        maxWidth="150px"      
                        onClick={this.handleCreateService}>                        
                      Crear servicio <MdAdd color="#FFF" size="25px" />

                    </ButtonLoader2>     

                  </ColumnWrap>
                  {this.props.errorListSerives ? (
                    <div>
                      <p
                        className="error-message total-center"
                        style={{ minHeight: '200px' }}
                      >
                        {this.props.errorListSerives}
                      </p>
                    </div>
                  ) : null}
                  {this.props.loadServices ? (
                    <div
                      className="total-center"
                      style={{ minHeight: '200px' }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <GridCardService>
                      {this.props.serviceList &&
                      this.props.serviceList.length > 0
                        ? this.props.serviceList.map((element, key) => {
                            return (
                              <CardServices
                                key={key}
                                item={element}
                                index={key + 1}
                                totalItem={this.props.serviceList.length}
                              />
                            );
                          })
                        : null}
                    </GridCardService>
                  )}



                </div>
              )}
            </Collapse>
          </div>
        </ColumnWrap>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    detail: state.accessStore.loginStore.data,
    serviceList: state.servicesStore.servicesUser.list,
    loadServices: state.servicesStore.servicesUser.load,
    errorListSerives: state.servicesStore.servicesUser.error,
    categories: state.generalStore.categories.data,
    planUser: state.planStore.planUser.plan,
    user:
      state.accessStore.loginStore.data &&
      state.accessStore.loginStore.data.responseInfo
        ? state.accessStore.loginStore.data.responseInfo.user.id
        : {},
    loadingPlan: state.planStore.listPlan.load,
    errorPlan: state.planStore.listPlan.error,
    list: state.planStore.listPlan.list,
  };
};

export default connect(
  mapStateToProps,
  {
    getServicesUser,
    listServicesUser,
    errorListServicesUser,
    getUserPlan,
    startPlanUser,
    detailPlanUser,
    errorPlanUser,
    getDetailPlan,
    getMethodPayment,
    getListPayment,
    getCategories,
    getListCategories,
    startListPlan,
    listPlan,
    errorPlanList,
    getListPlan,
  },
)(Dashboard);
// const TextDownload = styled.p`
//   font-family: 'Roboto';
//   font-weight: 400;
//   font-size: 16px;
//   color: #66bbff;
//   /* text-align: center; */
//   margin-left: 25px;
//   margin-top: 10px;
//   cursor: pointer;
// `;
const GridCardPlans = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 20px;
  margin-top: 20px;
  width: 100%;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`;
const GridCardService = styled.div`
  display: grid;
  width: 100%;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 30px;
  /* @media (max-width: 1600px) {
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  } */
`;
const WrapIconText = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px;
`;
const Icon = styled.img`
  width: auto;
  height: 25px;
  margin-right: 5px;
`;
