import React from 'react';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

const MyMap = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyC_zg_8vc1B_BVJ73VOOqwMXnxUIE5v5mM&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '280px', width: '100%' }} />,
    mapElement: <div style={{ height: '100%' }} />,
    apiKey: 'AIzaSyC_zg_8vc1B_BVJ73VOOqwMXnxUIE5v5mM',
  }),
  withScriptjs,
  withGoogleMap,
)(props => {
  if (props.center) {
    return (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{ lat: props.center.lat, lng: props.center.lng }}
      >
        {/* <img src="/assets/images/location-on.svg" alt="icon" height="30px" /> */}
        <Marker
          position={{ lat: props.center.lat, lng: props.center.lng }}
          //   onClick={props.onMarkerClick}
        />
      </GoogleMap>
    );
  }
});

export default MyMap;
