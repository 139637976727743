import * as types from './types';

const loginInit = (data, load) => {
  return {
    type: types.LOGIN_SUCCESS,
    payload: {
      data,
      load,
    },
  };
};

const errorLoginInit = (error, load) => {
  return {
    type: types.ERROR_LOGIN_SUCCESS,
    payload: {
      error,
      load,
    },
  };
};

const getDetailUser = (data, load) => {
  return {
    type: types.GET_DETAIL_USER,
    payload: {
      data,
      load,
    },
  };
};

const getErrorDetailUser = (error, load, data) => {
  return {
    type: types.ERROR_GET_DETAIL_USER,
    payload: {
      error,
      load,
      data,
    },
  };
};

const loginGoogle = (data, load) => {
  return {
    type: types.LOGIN_GOOGLE,
    payload: {
      data,
      load,
    },
  };
};

const errorLoginGoogle = (error, load, data) => {
  return {
    type: types.ERROR_LOGIN_GOOGLE,
    payload: {
      error,
      load,
      data,
    },
  };
};

const logout = data => {
  return {
    type: types.LOGOUT,
    payload: data,
  };
};

export {
  loginInit,
  getDetailUser,
  getErrorDetailUser,
  logout,
  errorLoginInit,
  loginGoogle,
  errorLoginGoogle,
};
