import * as firebase from 'firebase/app';
import 'firebase/auth';
import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ButtonLoader from '../../components/generics/Button';
import Divider from '../../components/generics/Divider';
import Input from '../../components/generics/Input';
import InputPass from '../../components/generics/InputPass';
import Text from '../../components/generics/Text';
import { applicationForm } from '../../ducks/changeForm';
import {
  getEmail,
  loginInit,
  getDetailUser,
  getErrorDetailUser,
  postLogin,
  errorLoginInit,
  checkEmailAvailability,
  loginGoogle,
  errorLoginGoogle,
} from '../../ducks/access';
import { getUserId } from '../../ducks/user';
import lang from '../../lang';

const firebaseConfig = {
  apiKey: 'AIzaSyDuRTd7LR50bXrHXR2X22QeajHv-4NSJJU',
  authDomain: 'fibyapp-8ebd3.firebaseapp.com',
  databaseURL: 'https://fibyapp-8ebd3.firebaseio.com',
  projectId: 'fibyapp-8ebd3',
  storageBucket: 'fibyapp-8ebd3.appspot.com',
  messagingSenderId: '699475038295',
  appId: '1:699475038295:web:ce18adfc058c3ee8',
};

firebase.initializeApp(firebaseConfig);

class Login extends Component {
  constructor(props) {
    super(props);
    const obj = {
      email: '',
      errorEmail: '',
      password: '',
    };
    props.getDetailUser({}, false);
    props.applicationForm(obj);
    props.loginGoogle({}, false);
    props.errorLoginInit('', false);
    this.state = {
      scene: 'login',
    };
  }

  

  handleOnchangeEmail = (prop, errorProp) => event => {
    const onChangeForm = JSON.stringify(this.props.formChange);
    const parseChangeForm = JSON.parse(onChangeForm);
    parseChangeForm[prop] = event.target.value;
    parseChangeForm[errorProp] = '';
    const obj = {
      ...parseChangeForm,
    };
    this.props.applicationForm(obj);
    this.props.errorLoginInit('', false);
    this.props.getErrorDetailUser('', false, this.props.detailUser);
  };

  handleClickEmail = () => {
    const form = JSON.stringify(this.props.formChange);
    const formParse = JSON.parse(form);
    const validate = this.validate(formParse);
    if (validate) {
      this.props.getDetailUser({}, true);
      this.props.getEmail(this.props.formChange.email, Response => {
        if (Response.code >= 200 && Response.code <= 299) {
          if (Response.data !== '') {
            Response.data[`email`] = this.props.formChange.email;
            this.props.getDetailUser(Response.data, false);
            this.setState({
              scene: 'password',
            });
          } else {
            this.setState({
              scene: 'emailError',
            });
            const emailLength = this.props.formChange.email.indexOf('@');
            const user = {
              email: this.props.formChange.email,
              usuario: this.props.formChange.email.substr(0, emailLength),
            };
            this.props.loginGoogle(user, false);
            // this.props.history.push(`/register`);
          }
        } else {
          this.props.getErrorDetailUser(Response.message, false);
        }
      });
    }
  };

  validate = data => {
    if (!data) {
      return false;
    }
    if (!data.email) {
      data.errorEmail = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (data.email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(data.email).toLowerCase())) {
        data.errorEmail = lang.enterValidEmailAddress;
        const errors = {
          ...data,
        };
        this.props.applicationForm(errors);
        return false;
      }
    }
    return true;
  };

  handleClickLogin = () => {
    const form = JSON.stringify(this.props.formChange);
    const formParse = JSON.parse(form);
    const validate = this.validatePassword(formParse);
    if (validate) {
      this.props.loginInit({}, true);
      firebase
        .auth()
        .signInWithEmailAndPassword(
          this.props.formChange.email,
          this.props.formChange.password,
        )
        .then(Response => {
          const data = {
            token: Response.user.ra,
            uid: Response.user.uid,
          };
          localStorage.setItem('token_get_get_it', Response.user.ra);
          this.props.postLogin(data, result => {
            if (result.code >= 200 && result.code <= 299) {
              if (result.data !== '') {
                if (
                  result.data.responseInfo.user.idUserType === 1 ||
                  result.data.responseInfo.user.idUserType === 2
                ) {
                  this.props.getDetailUser({}, false);
                  this.props.loginInit(result.data, false);
                  this.props.history.push('/dashboard');
                } else {
                  this.props.errorLoginInit(
                    lang.exclusiveIncomeForSuppliers,
                    false,
                  );
                }
              } else {
                this.props.errorLoginInit('Error', false);
              }
            } else {
              this.props.errorLoginInit(result.message, false);
            }
          });
        })
        .catch(err => {
          console.log(err.code);
          if (err.code === 'auth/wrong-password')
            this.props.errorLoginInit(lang.passwordErrorMessage, false);
          else if (err.code === 'auth/network-request-failed')
            this.props.errorLoginInit(lang.networkErrorMessage, false);
          else this.props.errorLoginInit(err.message, false);
        });
    }
  };

  validatePassword = data => {
    if (!data) {
      return false;
    }
    if (!data.password) {
      data.errorPassword = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    return true;
  };

  handleLoginGoogle = () => {
    this.props.loginGoogle({}, true);
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(Response => {
        this.props.checkEmailAvailability(Response.user.email, result => {
          if (result.code >= 200 && result.code <= 299) {
            if (result.data.responseInfo) {
              const data = {
                token: Response.user.ra,
                uid: Response.user.uid,
              };
              this.props.getUserId(data, dataResult => {
                if (dataResult.code >= 200 && dataResult.code <= 299) {
                  dataResult.data.responseInfo = {
                    user: dataResult.data.responseInfo,
                  };
                  if (
                    dataResult.data.responseInfo.user.idUserType === 1 ||
                    dataResult.data.responseInfo.user.idUserType === 2
                  ) {
                    localStorage.setItem('token_get_get_it', data.token);
                    this.props.loginGoogle({}, false);
                    this.props.loginInit(dataResult.data, false);
                    this.props.history.push('/dashboard');
                  } else {
                    this.props.errorLoginGoogle(
                      lang.exclusiveIncomeForSuppliers,
                      false,
                      {},
                    );
                  }
                } else {
                  this.props.errorLoginInit(dataResult.message, false);
                }
              });
            } else {
              const emailLength = Response.user.email.indexOf('@');
              const user = {
                displayName: Response.user.displayName,
                email: Response.user.email,
                photoURL: Response.user.photoURL,
                phoneNumber: Response.user.phoneNumber,
                providerData: Response.user.providerData,
                uid: Response.user.uid,
                usuario: Response.user.email.substr(0, emailLength),
                password: '12345678',
              };
              this.props.loginGoogle(user, false);
              this.props.history.push(`/register`);
            }
          } else {
            this.props.errorLoginGoogle(result.message, false, {});
          }
        });
      })
      .catch(err => {
        this.props.errorLoginGoogle('', false, {});
      });
  };

  changeEmail = () => {
    this.props.errorLoginInit('', false);
    this.setState({
      scene: 'login',
    });
    const obj = {
      email: '',
      errorEmail: '',
      password: '',
    };
    this.props.getDetailUser({}, false);
    this.props.applicationForm(obj);
  };

  render() {
    return (
      <Background>
        <HeaderLogo src="/assets/images/logo-small.svg" alt="logo" />
        <div className="total-center" style={{ height: '70vh' }}>
          <div>
            <Text fontSize="30px" color="#FFF" textAlign="center">
              {lang.AchieveWhatYouImagineToReach}
            </Text>
            <Text
              marginTop="10px"
              fontFamily="Metropolis-Medium"
              fontSize="22px"
              color="#FFF"
              textAlign="center"
            >
              {lang.OfferYourServices}
            </Text>
            <div className="total-center">
              <div>
                <FormWrap>
                  <div className="total-center">
                    <img
                      src="/assets/images/logo.svg"
                      alt="logo"
                      width="auto"
                      height="70px"
                    />
                  </div>
                  {this.state.scene === 'login' ? (
                    <AlignInfo marginTop="50px">
                      <Input
                        width="353px"
                        sizeSm="420px"
                        widthSm="223px"
                        error={
                          this.props.formChange &&
                          this.props.formChange.errorEmail
                            ? true
                            : false
                        }
                        placeholder={lang.mail}
                        onChange={this.handleOnchangeEmail(
                          'email',
                          'errorEmail',
                        )}
                        message={
                          this.props.formChange &&
                          this.props.formChange.errorEmail
                            ? this.props.formChange.errorEmail
                            : ''
                        }
                        value={
                          this.props.formChange && this.props.formChange.email
                            ? this.props.formChange.email
                            : ''
                        }
                      />
                      {this.props.errorDetail ? (
                        <div>
                          <p className="error-message">
                            {this.props.errorDetail}
                          </p>
                        </div>
                      ) : null}
                      {this.props.loadingDetail ? (
                        <div>
                          <CircularProgress />
                        </div>
                      ) : (
                        <ButtonLoader
                          widthB="220px"
                          maxWidth="350px"
                          onClick={this.handleClickEmail}
                        >
                          {lang.next}
                        </ButtonLoader>
                      )}
                      <div
                        style={{
                          width: '100%',
                          marginTop: '30px',
                        }}
                      >
                        <LoginGoogle>
                          <Divider />
                          <div style={{ width: '200px' }}>
                            <Text
                              fontSize="15px"
                              color="#FFF"
                              textAlign="center"
                              width="150px"
                            >
                              {lang.loginWith}
                            </Text>
                          </div>
                          <Divider />
                        </LoginGoogle>
                        {this.props.loadingGoogle ? (
                          <div
                            className="total-center"
                            style={{ marginTop: '10px' }}
                          >
                            <CircularProgress />
                          </div>
                        ) : (
                          <div className="total-center">
                            <ButtonLoader
                              icon
                              widthB="220px"
                              backgroundColor="#d90458"
                              maxWidth="350px"
                              marginTop="20px"
                              onClick={this.handleLoginGoogle}
                            >
                              {lang.google}
                            </ButtonLoader>
                          </div>
                        )}
                        {this.props.errorGoogle ? (
                          <div
                            className="total-center"
                            style={{ marginTop: '10px' }}
                          >
                            <p className="error-message">
                              {this.props.errorGoogle}
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </AlignInfo>
                  ) : null}
                  {this.state.scene === 'password' ? (
                    <AlignInfo marginTop="50px">
                      <div>
                        <div className="total-center">
                          {this.props.detailUser &&
                          this.props.detailUser.responseInfo ? (
                            <Avatar
                              src={
                                this.props.detailUser.responseInfo
                                  .profilePicture.highQuality
                              }
                              alt="logo"
                            />
                          ) : null}
                        </div>
                        <Text
                          fontSize="15px"
                          color="#FFF"
                          textAlign="center"
                          marginTop="10px"
                        >
                          {this.props.detailUser && this.props.detailUser.email
                            ? this.props.detailUser.email
                            : null}
                          <span
                            className="link opacity"
                            onClick={this.changeEmail}
                          >
                            {' '}
                            {lang.change}
                          </span>
                        </Text>
                      </div>
                      <InputPass
                        width="353px"
                        sizeSm="420px"
                        widthSm="223px"
                        placeholder={lang.password}
                        onChange={this.handleOnchangeEmail(
                          'password',
                          'errorPassword',
                        )}
                        value={
                          this.props.formChange &&
                          this.props.formChange.password
                            ? this.props.formChange.password
                            : ''
                        }
                        error={
                          this.props.formChange &&
                          this.props.formChange.errorPassword
                            ? true
                            : false
                        }
                        message={
                          this.props.formChange &&
                          this.props.formChange.errorPassword
                            ? this.props.formChange.errorPassword
                            : ''
                        }
                        type="password"
                      />
                      {this.props.errorLogin ? (
                        <div>
                          <p
                            className="error-message"
                            style={{ textAlign: 'center' }}
                          >
                            {this.props.errorLogin}
                          </p>
                        </div>
                      ) : null}
                      {this.props.loadingLogin ? (
                        <div>
                          <CircularProgress />
                        </div>
                      ) : (
                        <ButtonLoader
                          maxWidth="350px"
                          widthB="220px"
                          onClick={this.handleClickLogin}
                        >
                          {lang.enter}
                        </ButtonLoader>
                      )}
                      <Text
                        fontFamily="Roboto"
                        color="#FFF"
                        width="100%"
                        maxWidth="350px"
                        textAlign="right"
                        className="opacity"
                        onClick={() =>
                          this.props.history.push('/recovery-password')
                        }
                      >
                        {lang.forgotMyPassword}
                      </Text>
                    </AlignInfo>
                  ) : null}
                  {this.state.scene === 'emailError' ? (
                    <div className="total-center">
                      <AlignInfo marginTop="50px">
                        <Text
                          fontSize="15px"
                          color="#FFF"
                          textAlign="center"
                          fontFamily="Roboto"
                        >
                          {this.props.formChange.email}{' '}
                          <span
                            style={{
                              color: '#66bbff',
                              fontSize: '14px',
                              cursor: 'pointer',
                            }}
                            onClick={this.changeEmail}
                          >
                            {lang.change}
                          </span>
                        </Text>
                        <Text
                          fontSize="15px"
                          color="#FFF"
                          textAlign="center"
                          marginTop="0px"
                          fontFamily="Roboto"
                        >
                          {lang.emailIsNotRegistered}
                        </Text>
                        <ButtonLoader
                          maxWidth="350px"
                          width="calc(100% - 20px)"
                          marginTop="10px"
                          onClick={() => this.props.history.push('/register')}
                        >
                          {lang.registerMe}
                        </ButtonLoader>
                        <div style={{ width: '100%', marginTop: '30px' }}>
                          <LoginGoogle>
                            <Divider />
                            <div style={{ width: '200px' }}>
                              <Text
                                fontSize="15px"
                                color="#FFF"
                                textAlign="center"
                                width="150px"
                              >
                                {lang.logInWhith}
                              </Text>
                            </div>
                            <Divider />
                          </LoginGoogle>
                          {this.props.loadingGoogle ? (
                            <div
                              className="total-center"
                              style={{ marginTop: '10px' }}
                            >
                              <CircularProgress />
                            </div>
                          ) : (
                            <div className="total-center">
                              <ButtonLoader
                                icon
                                backgroundColor="#d90458"
                                maxWidth="350px"
                                width="calc(100% - 20px)"
                                widthB="280px"
                                marginTop="10px"
                                onClick={this.handleLoginGoogle}
                              >
                                {lang.google}
                              </ButtonLoader>
                            </div>
                          )}
                        </div>
                      </AlignInfo>
                    </div>
                  ) : null}
                </FormWrap>
                {this.state.scene === 'login' ? (
                  <Text
                    fontSize="17px"
                    color="#FFF"
                    marginTop="15px"
                    fontFamily="Roboto-Bold"
                  >
                    {lang.youStillDontHaveAnAccount}{' '}
                    <span
                      className="link opacity"
                      onClick={() => this.props.history.push('/register')}
                    >
                      {lang.signUp}
                    </span>
                  </Text>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Background>
    );
  }
}

const mapStateToProps = state => {
  return {
    formChange: state.changeForm.form,
    loadingDetail: state.accessStore.detailStore.load,
    errorDetail: state.accessStore.detailStore.error,
    detailUser: state.accessStore.detailStore.data,
    loadingLogin: state.accessStore.loginStore.load,
    errorLogin: state.accessStore.loginStore.error,
    loadingGoogle: state.accessStore.loginGoogle.load,
    errorGoogle: state.accessStore.loginGoogle.error,
    dataUserGoogle: state.accessStore.loginGoogle.data,
  };
};

export default connect(
  mapStateToProps,
  {
    applicationForm,
    getEmail,
    loginInit,
    getDetailUser,
    getErrorDetailUser,
    postLogin,
    errorLoginInit,
    checkEmailAvailability,
    loginGoogle,
    errorLoginGoogle,
    getUserId,
  },
)(Login);

const Background = styled.div`
  background-image: url('/assets/images/background.png');
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100vh;
  @media (max-width: 768px) {
    padding-top: 12vh;
  }
`;
const HeaderLogo = styled.img`
  width: auto;
  height: 45px;
  position: relative;
  margin-top: 25px;
  margin-left: 40px;
  @media (max-width: 480px) {
    display: none;
  }
`;
const FormWrap = styled.div`
  margin-top: 20px;
  background: linear-gradient(#1e1e1e, #1e1e1e) padding-box,
  linear-gradient(90deg, rgba(51,52,128,1) 0%, rgba(110,0,191,1) 100%) border-box;
  background-opacity: 0.1;
  width: 450px;
  /* width: calc(100% - 40px); */
  max-width: 480px;
  min-height: 340px;
  border-radius: 5px;
  border: 5px solid transparent;
  border-width: 2px;
  padding: 30px 0px 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 480px) {
    width: 90vw;
  }
`;
const AlignInfo = styled.div`
  width: 100%;
  margin-top: ${props => props.marginTop};
  max-width: 370px;
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 10px;
  align-items: center;
  justify-items: center;
  @media (max-width: 420px) {
    width: 90%;
  }
  /* margin-top: 20px;
    margin-bottom: 30px; */
`;
const LoginGoogle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 420px) {
    /* width: 240px; */
  }
`;
const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
  background-color: #ccc;
`;

