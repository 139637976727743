import React from 'react';
import styled from 'styled-components';
import Tooltip from './Tooltip';

const InputArea = props => {
  return (
    <div>
      {props.error ? (
        <Tooltip
          message={props.message}
          error={props.error}
          style={props.style}
        />
      ) : null}
      <InputStyle
        placeholder={props.placeholder}
        rows={props.rows}
        cols={props.cols}
        value={props.value}
        name={props.name}
        border={props.error ? 'solid 1px #f80838' : null}
        onChange={props.onChange}
        onClick={props.onClick}
        onKeyPress={props.onKeyPress}
        minlength={props.minlength}
        maxlength={props.maxlength}        
        {...props}
      />
      
    </div>
  );
};
const InputStyle = styled.textarea`
  resize: none;
  width: ${props => props.width || '370px'};
  height: ${props => props.height || '40px'};
  padding-bottom: ${props => props.paddingBottom};
  padding-left: ${props => props.paddingLeft || '15px'};
  border-radius: ${props => props.borderRadius || '5px'};
  border: ${props => props.border || '1px solid  #b7b8b8'};
  border-bottom: ${props => props.borderBottom};
  border-top: ${props => props.borderTop};
  border-right: ${props => props.borderRight};
  border-left: ${props => props.borderLeft};
  outline: none;
  cursor: ${props => props.cursor};
  text-align: ${props => props.textAlign || 'left'};
  margin-top: ${props => props.marginTop};
  margin-right: ${props => props.marginRight};
  margin-left: ${props => props.marginLeft};
  margin-bottom: ${props => props.marginBottom};
  padding-bottom: ${props => props.paddingBottom};
  padding-left: ${props => props.paddingLeft};
  font-size: ${props => props.fontSize};
  color: ${props => props.fontColor};
  background-color: ${props => props.backgroundColor};
  @media (max-width: ${props => props.sizeSm || '480px'}) {
    width: ${props => props.width || '280px'};
  }
  :focus {
    border: ${props => props.borderFocus || '1px solid  #66bbff'};
    border-bottom: ${props => props.borderFocusBottom};
    border-top: ${props => props.borderFocusTop};
    border-right: ${props => props.borderFocusRight};
    border-left: ${props => props.borderFocusLeft};
    outline: none;
  }
  ::placeholder {
    font-family: 'Roboto-LightItalic';
    font-size: ${props => props.fontSize};
    color: ${props => props.placeHolderColor || '#808080' };
  }
`;

export default InputArea;
