import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {  Modal  } from '@material-ui/core';
import { MdClear } from 'react-icons/md';
import Service from '../../Service';

export default function ServiceModal(props) {

  return (
    <Modal
      open={props.open || false}
      //onClose={props.onClose}
    >
      <Wrapper>
        <Close /*onClick={props.onClose}*/ >
          <MdClear marginRigth={-10} size={25} onClick={props.onClose}/>
        </Close>
        <Service onClose={props.onClose} />        
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  position: absolute;
  width: calc(100vw * 0.6);
  overflow-y: auto;
  @media (max-width: ${props => props.sizeSm || '720px'}) {
    width: calc(100vw );
    height: calc(100vh);
    padding: 0px;
  } ;

  @media (min-width: 721px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);    
    padding: 10px;
  } ;


  background-color: rgb(255,255,255);

`;

const Close = styled.div`
  float: right;
  //margin-right: 20px;
  margin-left: 10px;
  position: relative;
  display: block;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  :hover {
    opacity: 0.8;
  }
`;

ServiceModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

ServiceModal.defaultProps = {
  open: false
};
