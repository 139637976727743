import React, {  useEffect, useState } from 'react';
import {Grid, Container} from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';
import Button from '../../components/Button';
import Text from '../../../../components/generics/Text';
import Input from '../../../../components/generics/Input';
import Modal from '../../../../components/generics/Modal';
import {randomStr} from '../../../../util/utils';
import * as COLORS from '../../../../util/colors';
import {MAX_DISPONIBILITY_ITEMS} from '../../../../constants';

const TYPE_PLACE='place';
const TYPE_UNIT='unit';


const initialRateState = {
  name: '',
  price: 0,
  type: TYPE_UNIT,
  id: randomStr(20),
  show: true,
  persisted: false,
  color: COLORS.blue
};

const initialScheduleState = {
  places: 1,
  units: 1,
  type: TYPE_UNIT,
  rates: [],
  name: '',
  persisted: false,
  color: COLORS.blue
};

const Disponibility =(props) => {
  const colorOrder = [COLORS.green, COLORS.purple, COLORS.orange];

  const [options, setOptions] = useState((props.schedule && props.schedule.disponibility
      && props.schedule.disponibility.schedules) || []);
  const [schedule, setSchedule] = useState(initialScheduleState);
  const [edit, setEdit] = useState(false);

  const stepValid = () => {
    return (
      options.length>0
    );
  };

  /*useEffect (()=>{
    setOptions((props.schedule && props.schedule.disponibility
      && props.schedule.disponibility.schedules) || []);
    setSchedule(initialScheduleState);
    setEdit(false);
  },[props]);*/

  const onSave = () => {
    const dataChange= {schedules : [...options]};
    props.onChange && props.onChange(dataChange);
    props.nextStep();
  };

  const onAddSchedule = () => {
    const newSch = {...initialScheduleState};
    newSch.id = randomStr(20);
    if (options && options.length==MAX_DISPONIBILITY_ITEMS) {
      return;
    }
    newSch.color=colorOrder[0];
    if (options.length>0) {
      const lastColor = options[options.length-1].color;
      let index = colorOrder.findIndex((color)=>color===lastColor);
      index+=1;
      if ( index >= colorOrder.length)
      {
        index=0;
      }
      newSch.color=colorOrder[index];
    }
    
    setSchedule(newSch);
    setEdit(true);
  };

  const onEdit = (item) => {
    setSchedule({...item});
    setEdit(true);
  };

  const onChangeForm = (data) => {
    setSchedule({...data});
  };

  const isNameValid = () => {
    const data = {...schedule };
    if (!data.name) return false;

    return  (data.name.length>=1 && data.name.length<=30) //Entre dos y 30 carectares
           //&& !(/^([0-9])*$/.test(data.name))             // No pueden ser todos numeros
           //&& !(/^([A-Za-z])*$/.test(data.name))             // No pueden ser todos numeros
           && (/(.*[A-Za-z]){1,}/.test(data.name) || /(.*[0-9]){1,}/.test(data.name) );        // Al menos debe un numero o una letra
  };

  
  const onSaveForm = (evt) => {
    evt.preventDefault();
      
    const data = {...schedule };
    if (data.rates.length==0) {
      const msg = {
        show: true,
        msg: 'Debe agregar precios',
        type: 'error'                  
      };

      props.onShowMessage(msg);
      return;
    }

    if (!isNameValid()) {
      const msg = {
        show: true,
        msg: 'El nombre de la clasificación no cumple con las condiciones, debe incluir al menos 1 número o 1 letra y tener un máximo de 30 caracteres',
        type: 'error'                  
      };

      props.onShowMessage(msg);
      return;      
    }

    let newOptions = [...options];
    const index = newOptions.findIndex((item)=>item.id===data.id);
    if (index>=0) {
      newOptions[index] = data;
    }
    else {
      newOptions.push(data);
    }
    //let duration =0;
    let duration = (parseInt(props.service.payment.hour) *60) + parseInt(props.service.payment.minute);
    
    let totalM = 0;

    if (props.service && props.service.schedules) {
      for(let i=0; i<props.service.schedules.length; i++) {
        let sch = props.service.schedules[i];
        let d1 = moment().set({hour: sch.schedule.fromTime.hour,minute:sch.schedule.fromTime.minute  });
        let d2 = moment().set({hour: sch.schedule.toTime.hour,minute:sch.schedule.toTime.minute  });

        if (d2.isBefore(d1)) {
          d2 = moment().add(1,'day').set({hour: sch.schedule.toTime.hour,minute:sch.schedule.toTime.minute  });          
        }
        let minutes = parseInt(d2.diff(d1) / (1000 * 60));
        minutes *= sch.schedule.dates.length;
        totalM +=minutes;
      }

    }
    let d1 = moment().set({hour: props.schedule.schedule.fromTime.hour,minute:props.schedule.schedule.fromTime.minute  });
    let d2 = moment().set({hour: props.schedule.schedule.toTime.hour,minute:props.schedule.schedule.toTime.minute  });
    if (d2.isBefore(d1)) {
      d2 = moment().add(1,'day').set({hour: props.schedule.schedule.toTime.hour,minute:props.schedule.schedule.toTime.minute  });
    }

    let minutes = parseInt(d2.diff(d1) / (1000 * 60));
    minutes *= props.schedule.schedule.dates.length;
    minutes+=totalM;

    totalM= parseInt(minutes/duration);

    const places = newOptions.map((currentValue)=>currentValue.units * currentValue.places);
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const total = places.reduce(reducer) * totalM;

    console.log(total,props.plan.balanceQuantityPlaces);
    if (total > props.plan.balanceQuantityPlaces) {
      const msg = {
        show: true,
        msg: 'Excede las plazas disponibles',
        type: 'error'
      };

      props.onShowMessage(msg);
      return;
    }


    setOptions(newOptions);
    setEdit(false);
    setSchedule(initialScheduleState);
  };
    
  const onCloseForm = () => {
    const msg = {
      show: true,
      msg: '¿Deseas descartar los cambios?',
      type: 'warning',
      callback: (isOk) =>{
        if (isOk) {
          setEdit(false);
          setSchedule(initialScheduleState);
        }
      }
    };

    props.onShowConfirmMessage(msg);


  };

  const onDelete = (data) => {

    const msg = {
      show: true,
      msg: '¿Deseas eliminar la clasificación?',
      type: 'warning',
      callback: (isOk) =>{
        if (isOk) {
 
          const newOptions = [...options];
          const index = newOptions.findIndex((item)=>item.id===data.id);
      
          if (index>=0) {
            newOptions.splice(index,1);
          }
      
          setOptions(newOptions);
          setEdit(false);
          setSchedule(initialScheduleState);
        }
      }
    };

    props.onShowConfirmMessage(msg);


  };


  return (
    <Container>
      <Grid container >
        <Grid item xs={12} >
          <DisponibilityContainer>
          <Text
          marginTop='10px'
          marginBottom='10px'
          fontFamily='Roboto'
          fontSize='12px'
          textAlign='center'
          color={COLORS.purple}
        > La clasificación de un servicio permite segmentar a sus clientes según su criterio.</Text>

            {!edit?<Text
              textAlign={'center'}
              fontFamily='Roboto'              
              fontSize='13px'
              color="#DA0458"
              fontWeight="bold"
              margin='0 auto'
              cursor='pointer'
              onClick = {onAddSchedule}
            >
                   11* Agrega la clasificación del servicio
            </Text>:null}

            {edit?
              <DisponibilityForm 
                onShowMessage={props.onShowMessage}
                onShowConfirmMessage={props.onShowConfirmMessage}
                onSave={onSaveForm}
                onClose={onCloseForm}
                schedule={schedule}
                onChange={onChangeForm}
                plan={props.plan}
                service={props.service}
                parentSchedule={props.schedule}
                isFibyPay={props.isFibyPay}
              />
              :''}
                  
            {options && options.map((option,index)=>{
              return (
                edit && (schedule.id == option.id)?'':
                  <DisponibilityOption
                    onShowMessage={props.onShowMessage} 
                    key={option.id}
                    open ={index===(options.length-1)}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    schedule={option}/>);
            })}
          </DisponibilityContainer>
        </Grid>           
      </Grid>   
      <Grid container >
        <Grid item xs={6} style={{textAlign:'center'}}>
          <Button
            context={'primary'}
            onClick={props.previousStep}
          >
                  Anterior
          </Button>
        </Grid>

        <Grid item xs={6} style={{textAlign:'center'}}>
          <Button
            disabled={!stepValid()}
            context={stepValid()?'primary':''}
            onClick={onSave}
          >
                Siguiente
          </Button>
        </Grid>
      </Grid>
    </Container>

  );

};

const DisponibilityOption = (props) => {
  const [open, setOpen] = useState(props.open);
  const [priceIndex, setPriceIndex] = useState(0);

  const onPrev = () =>{
    if ((priceIndex-1)<0) {
      return;
    }
    setPriceIndex(priceIndex-1);
  };

  const onNext = () =>{
    if ((priceIndex+1)== (props.schedule.rates.length)) {
      return;
    }
    setPriceIndex(priceIndex+1); 
  };

  return (
    <ContainerForm type={props.schedule.type} color={props.schedule.color}>
      <ContainerFormHeader type={props.schedule.type} color={props.schedule.color}>
        <div style={{flexGrow: '1'}}>
          <Text
            textAlign={'center'}
            fontFamily='Roboto'
            color={COLORS.white}
            margin='0 auto'
            width='100%'
          >
            {props.schedule.name}
          </Text>          
        </div>
        <IconEdit onClick={()=>props.onEdit(props.schedule)}/>
        <IconDelete onClick={()=>props.onDelete(props.schedule)} />
        {open?<IconUp onClick={()=>setOpen(false)}/>:<IconDown  onClick={()=>setOpen(true)}/>}
      </ContainerFormHeader>
      <Grid container style={{display: (open?'flex':'none')}}>
        <Grid item xs={4}  style={{textAlign: 'center'}}>
          <Text
            textAlign={'center'}
            fontFamily='Roboto'
            color={COLORS.darkGray}
            margin='0 auto'
            marginBottom='5px'
            marginTop='5px'
            fontSize='100%'
          >
                      Unidades
          </Text> 

          <Text
            textAlign={'center'}
            fontFamily='Roboto'
            color={COLORS.darkGray}
            margin='0 auto'
            marginBottom='5px'
            marginTop='5px'
            fontWeight='bold'
            fontSize='130%'
          >
            {props.schedule.units}
          </Text>                     
        </Grid>  
        <Grid item xs={4}  style={{textAlign: 'center'}}>
          <Text
            textAlign={'center'}
            fontFamily='Roboto'
            color={COLORS.darkGray}
            margin='0 auto'
            marginBottom='5px'
            marginTop='5px'
            fontSize='100%'
          >
                      Plazas por unidad
          </Text>
          <Text
            textAlign={'center'}
            fontFamily='Roboto'
            color={COLORS.darkGray}
            margin='0 auto'
            marginBottom='5px'
            marginTop='5px'
            fontWeight='bold'
            fontSize='130%'
          >
            {props.schedule.places}
          </Text>                    
        </Grid>
        <Grid item xs={4}  style={{textAlign: 'center'}}>
          <Text
            textAlign={'center'}
            fontFamily='Roboto'
            color={COLORS.darkGray}
            margin='0 auto'
            marginBottom='5px'
            marginTop='5px'
            fontSize='70%'
          >
                      Reserva por
          </Text>  
          <Text
            textAlign={'center'}
            fontFamily='Roboto'
            fontWeight='bold'
            color={COLORS.darkGray}
            margin='0 auto'
            marginBottom='5px'
            marginTop='15px'
            fontSize='130%'
          >
            {props.schedule.type===TYPE_UNIT?'Unidad':'Plaza'}
          </Text>                  
        </Grid>  
        {props.schedule.rates && props.schedule.rates.length>0?<Grid item xs={12}  style={{textAlign: 'center'}}>
          <ContainerPrice >
            <IconPrev onClick={onPrev}  variant={props.schedule.color} />
            <ContainerPriceItems>
              {props.schedule.rates.map((item,index)=>{
                return (index===(priceIndex) || index===(priceIndex+1) ?<PriceItem readonly={true} key={item.id}  price={item} />:'');
              })}
            </ContainerPriceItems>
            <IconNext onClick={onNext} variant={props.schedule.color}/>
          </ContainerPrice> 
        </Grid>:''}                                      
      </Grid>          
        
    </ContainerForm>
  );
};

const DisponibilityForm = (props) => {
  const [units, setUnits ] = useState(props.schedule.units);
  const [places, setPlaces ] = useState(props.schedule.places);
  const [rates, setRates ] = useState(props.schedule.rates);
  const [type, setType ] = useState(props.schedule.type);
  const [name, setName ] = useState(props.schedule.name);
  const [editPrice, setEditPrice ] = useState(false);
  const [price, setPrice ] = useState(initialRateState);
  const [priceIndex, setPriceIndex ] = useState(0);
  const [placesRequired, setPlacesRequired ] = useState(1);
  

  useEffect(()=>{
    setPrice({...price, type: type});
    calcPlacesRequired();
    const data = {id:props.schedule.id,units,places,rates,type,name, color: props.schedule.color};
    props.onChange && props.onChange(data);
  },[units,places,rates,type,name,priceIndex]);

  const calcPlacesRequired = () => {
    let duration = (parseInt(props.service.payment.hour) *60) + parseInt(props.service.payment.minute);
    let totalM = 0;

    if (props.service && props.service.schedules) {
      for(let i=0; i<props.service.schedules.length; i++) {
        let sch = props.service.schedules[i];
        let d1 = moment().set({hour: sch.schedule.fromTime.hour,minute:sch.schedule.fromTime.minute  });
        let d2 = moment().set({hour: sch.schedule.toTime.hour,minute:sch.schedule.toTime.minute  });
        if (d2.isBefore(d1) ) {
          d2 = moment().add(1,'day').set({hour: sch.schedule.toTime.hour,minute:sch.schedule.toTime.minute  });
        }
        let minutes = parseInt(d2.diff(d1) / (1000 * 60));
        minutes *= sch.schedule.dates.length;
        totalM +=minutes;
      }

    }
    let d1 = moment().set({hour: props.parentSchedule.schedule.fromTime.hour,minute:props.parentSchedule.schedule.fromTime.minute  });
    let d2 = moment().set({hour: props.parentSchedule.schedule.toTime.hour,minute:props.parentSchedule.schedule.toTime.minute  });
    let minutes = parseInt(d2.diff(d1) / (1000 * 60));
    if (d2.isBefore(d1) ) {
      d2 = moment().add(1,'day').set({hour: props.parentSchedule.schedule.toTime.hour,minute:props.parentSchedule.schedule.toTime.minute  });
    }

    minutes *= props.parentSchedule.schedule.dates.length;
    minutes+=totalM;

    totalM= parseInt(minutes/duration);
    const total = (units*places) * totalM;

    setPlacesRequired(total);
  };
  const onChangePrice = (data) => {
    setPrice({...data});
  };
  const onClosePrice = () => {
    const msg = {
      show: true,
      msg: '¿Deseas descartar los cambios?',
      type: 'warning',
      callback: (isOk) =>{
        if (isOk) {
    
          setEditPrice(false);
          setPrice(initialRateState);
        }
      }
    };

    props.onShowConfirmMessage(msg);
    
  };

  const isPriceNameValid = (n) => {
    if (!n) return false;
    return  (n.length>=1 && n.length<=10) //Entre dos y 10 carectares
           //&& !(/^([0-9])*$/.test(n))             // No pueden ser todos numeros
           //&& !(/^([A-Za-z])*$/.test(n))             // No pueden ser todos letras
           && (/(.*[A-Za-z]){1,}/.test(n) || /(.*[0-9]){1,}/.test(n) );        // Al menos debe un numero o una letra
  };

  const onSavePrice = (evt) => {
    const data = {...price };
    evt.preventDefault();
    if (!isPriceNameValid(data.name)) {
      const msg = {
        show: true,
        msg: 'El nombre del precio no cumple con las condiciones, debe incluir al menos 1 número o 1 letra y tener un máximo de 10 caracteres',
        type: 'error'                  
      };
  
      props.onShowMessage(msg);
      return;        
    }
    
    if (props.isFibyPay()) {
      if (data.price<=0) {
        const msg = {
          show: true,
          msg: 'Debes colocar un monto mayor a 0 si utilizas nuestra pasarela de pago',
          type: 'error'                  
        };
    
        props.onShowMessage(msg);
        return;
      }
        
    }

    data.type= type;
    const options = [...rates];
    const index = options.findIndex((item)=>item.id===data.id);
    if (index>=0) {
      options[index] = data;
    }
    else {
      options.push(data);
    }
    setRates(options);
    setEditPrice(false);
    setPrice(initialRateState);
  };
  const onRemovePrice = (data) => {

    const msg = {
      show: true,
      msg: '¿Deseas eliminar la tarifa?',
      type: 'warning',
      callback: (isOk) =>{
        if (isOk) {
          const options = [...rates];
          const index = options.findIndex((item)=>item.id===data.id);
      
          if (index>=0) {
            options.splice(index,1);
          }
          setRates(options);
          setEditPrice(false);
          setPrice(initialRateState);
          setPriceIndex(0);
        }
      }
    };

    props.onShowConfirmMessage(msg);


  };

  const onEditPrice = (data) => {
    setEditPrice(true);
    setPrice(data);
  };

  const onAddPrice = () => {
    if (rates && rates.length==MAX_DISPONIBILITY_ITEMS) {
      return;
    }

    const newSch = {...initialRateState};
    newSch.id = randomStr(20);
    newSch.type= props.schedule.type;
    newSch.color= props.schedule.color;
    setPrice(newSch);
    setEditPrice(true);
  };

  const onPrev = () =>{
    if ((priceIndex-1)<0) {
      return;
    }
    setPriceIndex(priceIndex-1);
  };

  const onNext = () =>{
    if ((priceIndex+1)== (rates.length)) {
      return;
    }
    setPriceIndex(priceIndex+1); 
    
  };

  const onChangeName = e => {
    let size = 0;
    if (name) {
      size = name.length;
    }
    size+=1;
    
    return  (size<=30); 
  };

  return (
    <ContainerForm type={props.schedule.type} color={props.schedule.color}>
      <Grid container >
        <form onSubmit={props.onSave} style={{width:'100%'}}>
          <ContainerFormHeader type={props.schedule.type} color={props.schedule.color}>
            <div style={{flexGrow: '1'}}>
              <Input
                onChange={(evt)=>{setName(evt.target.value);}}
                minlength={1}
                maxlength={30}
                onKeyPress={e => {
                  if (onChangeName(e)) {
                  } else {
                    e.preventDefault();
                  }
                }}
                value={props.schedule.name}
                placeholder="12* Nombre de la clasificación del servicio"
                fontSize='13px'
                border="0"
                fontFamily='Roboto'
                fontWeight='bold'                
                fontColor={COLORS.white}
                placeHolderColor={COLORS.white}    
                paddingLeft="1"
                backgroundColor="transparent"
                borderFocus="0"
                borderRadius="0"
                margin='0 auto'
                height="25px"
                width='100%'
                textAlign='center'
                required
              />                    
            </div>
            <IconOk type='submit'/>
            <IconDelete onClick={props.onClose} type='button' />                
          </ContainerFormHeader>
          <Grid container >
            <Grid item xs={12} md={6} style={{textAlign: 'center'}}>
              <Text
                textAlign={'center'}
                fontFamily='Roboto'
                fontSize='12px'
                fontWeight='bold'
                color={COLORS.blue}
                margin='0 auto'
                marginBottom='5px'
                marginTop='5px'
              >
            13* Cantidad de unidades
              </Text>      
              <Text
                textAlign={'center'}
                fontFamily='Roboto'
                fontSize='12px'
                color={COLORS.darkGray}
                margin='0 auto'
                marginBottom='10px'
                marginTop='5px'
              >
            Capacidad del servicio, por ejemplo: 5 vehículos
              </Text>      
              <Input 
                required
                margin='0 auto'
                height="25px"
                type='number'
                width='60px'
                min='1'
                fontColor='#808080'
                value={props.schedule.units}
                onChange={(evt)=>{setUnits(parseInt(evt.target.value));}}
              />  
            </Grid>
            <Grid item xs={12} md={6} style={{textAlign: 'center'}}>
              <Text
                textAlign={'center'}
                fontFamily='Roboto'
                color={COLORS.blue}
                fontSize='12px'
                fontWeight='bold'
                margin='0 auto'
                marginTop='5px'
                marginBottom='5px'
              >
            14* Cantidad de plazas por unidad
              </Text>   
              <Text
                textAlign={'center'}
                fontFamily='Roboto'
                fontSize='12px'
                color={COLORS.darkGray}
                margin='0 auto'
                marginBottom='10px'
                //marginTop='5px'
              >
               Capacidad de la unidad, por ejemplo: 20 asientos en cada vehículo
              </Text>      
              <Input 
                    required
                    type='number'
                    height="25px"
                    width='60px'
                    min='1'                    
                    fontColor='#808080'
                    value={props.schedule.places}
                    onChange={(evt)=>{setPlaces(parseInt(evt.target.value));}}
                  />                
            </Grid>      
          </Grid>

          <Grid item xs={12}>              
                  <Text
                    marginTop='5px'
                    textAlign={'center'}
                    fontFamily='Roboto'
                    fontSize='70%'
                    color={COLORS.green}                      
                    //margin='0 auto'
                  >
                Necesitas <span style={{border: 'solid 1px #6E00BF', padding: '3px', marginRight: '4px', textAlign:'center', fontWeight: 'bold'}}> {placesRequired} </span> plazas para tu servicio </Text> 
                  <Text
                    textAlign={'center'}
                    fontFamily='Roboto'
                    fontSize='70%'
                    color={COLORS.green}
                    marginTop='10px'
                    marginBottom='10px'
                    //margin='0 auto'
                  >
                Tienes <span style={{border: 'solid 1px #6E00BF', padding: '3px', marginRight: '4px', textAlign:'center', fontWeight: 'bold'}}> {props.plan.balanceQuantityPlaces} </span> plazas disponibles en tu plan
                  </Text>
                </Grid>

        </form>
        <Grid item xs={12}  style={{textAlign: 'center'}}>
          <Text
            textAlign={'center'}
            fontFamily='Roboto'
            margin='0 auto'
            marginTop='20px'
            marginBottom='20px'
            fontSize='12px'
            fontWeight='bold'
            color={COLORS.blue}
          >
                    15* ¿Tu servicio se reservará por unidad o plaza?
          </Text>       
          <ScheduleTypeContainer >
            <ScheduleType 
              variant={type===TYPE_UNIT?props.schedule.color:COLORS.white}
              active={type===TYPE_UNIT}
              onClick={()=>setType(TYPE_UNIT)}
            >
              <Text
                textAlign={'center'}
                fontFamily='Roboto'
                color={type===TYPE_UNIT?COLORS.white:COLORS.darkGray}
                margin='0 auto'
              >
                    Unidad
              </Text>                  
            </ScheduleType>
            <ScheduleType 
              onClick={()=>setType(TYPE_PLACE)}
              variant={type===TYPE_PLACE?props.schedule.color:COLORS.white}
              active={type===TYPE_PLACE}>
              <Text
                textAlign={'center'}
                fontFamily='Roboto'
                color={type===TYPE_PLACE?COLORS.white:COLORS.darkGray}
                margin='0 auto'
              >
                    Plaza
              </Text>                
            </ScheduleType>
          </ScheduleTypeContainer>
        </Grid>
        <Grid item xs={12}  style={{textAlign: 'center'}}>
          {editPrice?<PriceForm 
            onClose={onClosePrice}
            onChange={onChangePrice}
            onSave={onSavePrice}
            price={price} />:''}
        </Grid> 
        {rates && rates.length>0?<Grid item xs={12}  style={{textAlign: 'center'}}>
          <ContainerPrice >
            <IconPrev onClick={onPrev}  variant={props.schedule.color} />
            <ContainerPriceItems>
              {rates.map((item,index)=>{
                if (editPrice && item.id==price.id) {
                  return '';
                }
                else {
                  return (index===(priceIndex) || index===(priceIndex+1) ?<PriceItem key={item.id} onEdit={onEditPrice} onDelete={onRemovePrice}  price={{...item,type:props.schedule.type}} />:'');
                }   
              })}
            </ContainerPriceItems>
            <IconNext onClick={onNext} variant={props.schedule.color}/>
          </ContainerPrice> 
        </Grid>:''}
	  
        {!editPrice?<Text
          textAlign={'center'}
          fontFamily='Roboto'
          color={COLORS.blue}
          fontWeight='bold'
          fontSize='13px'
          margin='0 auto'
          marginBottom='10px'
          marginTop='5px'
          cursor='pointer'
          onClick={onAddPrice}
        >
              16* Agregar precio
        </Text> :null} 	  
      </Grid>
    </ContainerForm>);
};

const PriceForm = (props) => {

  const [name, setName ] = useState(props.price.name);
  const [price, setPrice ] = useState(props.price.price);

  useEffect(()=>{
    const data = {id:props.price.id,name,price, type: props.price.type, color: props.price.color };
    props.onChange && props.onChange(data);
  },[name,price]);

  const onChangeName = e => {
    let size = 0;
    if (name) {
      size = name.length;
    }
    size+=1;
    
    return  (size<=10); 
  };

  return (
    <ContainerPriceForm type={props.price.type} color={props.price.color} >
      <form onSubmit={props.onSave}>
        <ContainerPriceFormHeader type={props.price.type} color={props.price.color}>
          <div style={{flexGrow: '1'}}>
            <Input
              onChange={(evt)=>{setName(evt.target.value);}}
              value={props.price.name}
              placeholder="17* Nombre de la tarifa"
              fontWeight='bold'
              border="0"
              minlength={1}
              maxlength={10}
              onKeyPress={e => {
                if (onChangeName(e)) {
                } else {
                  e.preventDefault();
                }
              }}              
              fontFamily='Roboto'
              fontColor={COLORS.darkGray}
              placeHolderColor={COLORS.darkGray}
              paddingLeft="1"
              backgroundColor="transparent"
              borderFocus="0"
              borderRadius="8px"
              margin='0 auto'
              height="20px"
              width= '80%'
              textAlign='center'
              required
            />
          </div>
          <IconOk type='submit' size={'15px'} variant={props.price.color} />
          <IconDelete type='button' onClick={props.onClose} size={'15px'} variant={props.price.color}/>
        </ContainerPriceFormHeader>
        <Grid container >
          <Grid item xs={12} style={{textAlign: 'center'}}>  
            <Input 
              required
              margin='0 auto'
              height="25px"
              type='number'
              step='0.01'
              width='80%'
              min='0'
              border= '0'
              placeholder='Precio'
              fontColor={COLORS.darkGray}
              placeHolderColor={COLORS.darkGray}
              value={props.price.price}
              textAlign='center'
              onChange={(evt)=>{setPrice(parseFloat(evt.target.value));}}
            />  
          </Grid>
        </Grid>  
      </form>   
    </ContainerPriceForm>    
  );
};

const PriceItem = (props) => {

  return (<ContainerPriceItem type={props.price.type} color={props.price.color}>
    <ContainerPriceFormHeader type={props.price.type} color={props.price.color}>
      <div style={{flexGrow: '1'}}>
        <Text
          fontFamily='Roboto'
          color={COLORS.darkGray}                
          textAlign='left'
          paddingLeft='5px'
        >{props.price.name}</Text>
      </div>
      {!props.readonly?
        <React.Fragment>
          <IconEdit size={'15px'} onClick={()=>props.onEdit(props.price)} variant={props.price.color}/>
          <IconDelete size={'15px'} onClick={()=>props.onDelete(props.price)} variant={props.price.color}/>
        </React.Fragment>
        :''}
    </ContainerPriceFormHeader>
    <Grid container >
      <Grid item xs={12} >
        <Text
          fontFamily='Roboto'
          color={COLORS.darkGray}            
          textAlign='left'
          paddingLeft='5px'
        >{props.price.price}</Text>            
      </Grid>
    </Grid>    
  </ContainerPriceItem>);
};

const ContainerPrice= styled.div`
  background-color: ${COLORS.white};
  width: 70%;
  flex-direction: row;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 720px) {
      width: auto;
  } ;  
`;

const ContainerPriceItems= styled.div`
  background-color: ${COLORS.white};
  width: 100%;
  flex-direction: row;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  ::-webkit-scrollbar {    display: none;}
`;

const ContainerPriceItem = styled.div`
    background-color: ${COLORS.white};
    border-radius: 5px;
    flex-basis: 50%;
    margin-left: 5px;
    margin-right: 5px;
    padding-bottom: 0px;    
    border: 1px solid ${props=>props.color};
`;

const ContainerPriceFormHeader = styled.div`
    background-color: ${COLORS.white};
    width: 100%;
    margin: 0 auto;
    margin-top: 5px;
    padding-bottom: 0px;
    display: flex;
    
`;

const ContainerPriceForm = styled.div`
    background-color: ${COLORS.white};
    border-radius: 5px;
    width: 50%;
    margin: 0 auto;
    margin-top: 5px;
    padding-bottom: 0px;
    flex: 1;
    border: 1px solid ${props=>props.color};
`;

const ContainerForm = styled.div`
    background-color: ${COLORS.white};
    width: 95%;
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-bottom: 0px;
    border: 1px solid #333480;
    @media (max-width: 720px) {
      width: auto;
    } ;    
`;


const ContainerFormHeader = styled.div`
    background-color: #333480; 
    height: 25px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    @media (max-width: 720px) {
      width: auto;
    } ;      
`;

const DisponibilityContainer = styled.div`

    background-color: ${COLORS.lightGray};
    height: 400px;
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    margin: 0 auto;   
    @media (max-width: 720px) {
      width: auto;
    } ;      
`;

const ScheduleTypeContainer = styled.div`
    display: flex;
    width: 40%;
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 5px;
    flex-direction: row;
    border: 1px solid ${COLORS.darkGray};
    padding: 2px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;    
`;

const ScheduleType = styled.div`
    display: flex;
    flex-basis: 50%;
    padding: 3px;
    background-color:${props=> props.variant};
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;    
    cursor: pointer;
`;

const IconEdit = styled.button`
    color: ${props=> props.variant  || COLORS.white};
    background-color: transparent;
    border-radius: ${props=> '8px'|| '8px'};
    width: ${props=> '70px'|| '90px'};
    height: ${props=> props.size || '20px'};
    margin-left: 5px;
    margin-right: 5px;    
    float: right;
    cursor: pointer;
    position: relative;
    font-size: 70%;
    border: 1px solid ${props=> props.variant  || COLORS.white};
    cursor: pointer;
    ::before{
      // content: '✎';
      content: '✎Editar';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%)  ;      
    }
`;

const IconDelete = styled.button`
    color: ${props=> props.variant  || COLORS.white};
    background-color: transparent;
    border-radius: ${props=> '8px'|| '8px'};
    width: ${props=> '70px' || '90px'};
    height: ${props=> props.size || '20px'};
    margin-left: 5px;
    margin-right: 5px;    
    float: right;
    cursor: pointer;
    position: relative;
    font-size: 70%;
    border: 1px solid ${props=> props.variant  || COLORS.white};
    ::before{
      //content: '☓';
      content: '☓Eliminar';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%) 
    }
`;

const IconOk = styled.button`
    color: ${props=> props.variant  || COLORS.white};
    background-color: transparent;
    border-radius: ${props=> '8px'|| '8px'};
    width:${props=> '70px' || '90px'};
    height: ${props=> props.size || '20px'};
    margin-left: 5px;
    margin-right: 5px;    
    float: right;
    cursor: pointer;
    position: relative;
    font-size: 70%;
    border: 1px solid ${props=> props.variant  || COLORS.white};
    ::before{
      //content: '✓';
      content: '✓Guardar';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%)       
    }
`;
const IconDown = styled.button`
    color: ${props=> props.variant  || COLORS.white};
    background-color: transparent;
    border-radius: 50%;
    width: ${props=> props.size || '20px'};
    height: ${props=> props.size || '20px'};
    margin-left: 2px;
    margin-right: 2px;    
    float: right;
    cursor: pointer;
    position: relative;
    font-size: 70%;
    border: 1px solid ${props=> props.variant  || COLORS.white};
    ::before{
      content: '˅';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%)       
    }
`;

const IconUp = styled.button`
    color: ${props=> props.variant  || COLORS.white};
    background-color: transparent;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-left: 2px;
    margin-right: 2px;    
    float: right;
    cursor: pointer;
    position: relative;
    font-size: 70%;
    border: 1px solid ${props=> props.variant  || COLORS.white};
    ::before{
      content: '˄';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%)       
    }
`;

const IconPrev = styled.button`
    color: ${props=> props.variant  || COLORS.white};
    background-color: transparent;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-left: 2px;
    margin-right: 2px;    
    float: right;
    cursor: pointer;
    position: relative;
    font-size: 70%;
    border: 0px solid ${props=> props.variant  || COLORS.white};
    ::before{
      content: '〈';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%)       
    }
`;

const IconNext = styled.button`
    color: ${props=> props.variant  || COLORS.white};
    background-color: transparent;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-left: 2px;
    margin-right: 2px;    
    float: right;
    cursor: pointer;
    position: relative;
    font-size: 70%;
    border: 0px solid ${props=> props.variant  || COLORS.white};
    ::before{
      content: '〉';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%)       
    }
`;

export default Disponibility ;
