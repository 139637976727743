import React from 'react';
import styled from 'styled-components';
import { MdArrowDropDown } from 'react-icons/md';
import Text from './Text';

const Tooltip = props => {
  return (
    <div style={props.style}>
      {props.error ? (
        <Menssage>
          <Text
            fontFamily="Roboto"
            fontSize="14px"
            color="#838485"
            textAlign="center"
            paddingX="5px"
          >
            {props.message}
          </Text>
          <ContainerArrow>
            <MdArrowDropDown />
          </ContainerArrow>
        </Menssage>
      ) : null}
    </div>
  );
};

export default Tooltip;

const Menssage = styled.div`
  width: 100%;
  max-width: 300px;
  min-height: 60px;
  background-color: #fff;
  position: absolute;
  margin-top: -70px;
  margin-left: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 17px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 17px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 17px -6px rgba(0, 0, 0, 0.75);
`;

const ContainerArrow = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  font-size: 30px;
  margin-top: 25px;
  color: #fff;
  margin-left: -100px;
`;
