import React, { Component, useState } from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';
import {IconDelete} from '../../components/Icons';
import {Grid, Container} from '@material-ui/core';
import moment from 'moment';
import * as COLORS from '../../../../util/colors';
import Text from '../../../../components/generics/Text';
import {MAX_SCHEDULES_ITEMS} from '../../../../constants';

const  Resume= (props)=> {

  const onAddSchedule = () =>{
    if (props.service
        && props.service.schedules 
        && props.service.schedules.length==MAX_SCHEDULES_ITEMS ) return;

    props.onEditSchedule({});
    props.goToStep(3);
  };

  const onRemoveSchedule = (index) =>{

    const msg = {
      show: true,
      msg: '¿Deseas eliminar el horario?',
      type: 'warning',
      callback: (isOk) =>{
        if (isOk) {
          props.onRemoveSchedule(index);
        }
      }
    };

    props.onShowConfirmMessage(msg);
    
  };


  const stepValid = () => {
    const {service} = props ;
    const {schedules} = service;
    return (
      schedules.length>0
    );
  };

  const {service} = props ;

  if (!service.detail) return '';

  return (
    <Container style={{margin:'0 auto',width: '90%'}}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Grid item xs={12} >
            <DetailRow>
              <CoverImg img={'assets/images/service/service.png'} />
              <DetailColumn>
                <DetailRow>
                  <Text
                    fontFamily='Roboto'
                    fontSize='10px'
                    fontWeight={'bold'}
                    padding={0}
                    color={COLORS.darkGray}
                  >Nombre del servicio</Text> 
                  <Text
                    marginLeft= '5px'
                    fontFamily='Roboto'
                    fontSize='10px'
                    padding={0}
                    color={COLORS.darkGray}
                  >{ service.detail.name}</Text> 
                </DetailRow>
                <DetailRow>
                  <Text
                    fontFamily='Roboto'
                    fontSize='10px'
                    fontWeight={'bold'}
                    padding={0}
                    marging={0}
                    color={COLORS.darkGray}
                  >Categoria</Text> 
                  <Text
                    marginLeft= '5px'
                    fontFamily='Roboto'
                    fontSize='10px'
                    color={COLORS.darkGray}
                  >{ service.detail.category.text}</Text> 
                </DetailRow> 
                <DetailRow>
                  <Text
                    fontFamily='Roboto'
                    fontSize='10px'
                    padding={0}
                    fontWeight={'bold'}
                    color={COLORS.darkGray}
                  >Subcategoria</Text> 
                  <Text
                    marginLeft= '5px'
                    fontFamily='Roboto'
                    fontSize='10px'
                    padding={0}
                    color={COLORS.darkGray}
                  >{ service.detail.subcategory.text}</Text> 
                </DetailRow>                                                   
              </DetailColumn>

            </DetailRow>
          </Grid>
          <Grid item xs={12} style={{marginTop: '5px'}}>
            <DetailRow style={{alignItems: 'center'}}>
              <CoverImg img={'assets/images/service/location.png'} />
              <DetailColumn>
                <DetailRow>
                  <Text
                    fontFamily='Roboto'
                    fontSize='10px'
                    fontWeight={'bold'}
                    padding={0}
                    color={COLORS.darkGray}
                  >Ubicaci&oacute;n</Text> 
                  <Text
                    marginLeft= '5px'
                    fontFamily='Roboto'
                    fontSize='10px'
                    padding={0}
                    color={COLORS.darkGray}
                  >{ service.detail.address}</Text> 
                </DetailRow>        
              </DetailColumn>
            </DetailRow>
          </Grid> 

          <Grid item xs={12} style={{marginTop: '5px'}}>
            <DetailRow style={{alignItems: 'center'}}>
              <CoverImg img={'assets/images/service/check-type.png'} />
              <DetailColumn>
                <DetailRow>
                  <Text
                    fontFamily='Roboto'
                    fontSize='10px'
                    fontWeight={'bold'}
                    padding={0}
                    color={COLORS.darkGray}
                  >Tipo de reserva</Text> 
                  <Text
                    marginLeft= '5px'
                    fontFamily='Roboto'
                    fontSize='10px'
                    padding={0}
                    color={COLORS.darkGray}
                  >{ service.payment && service.payment.checkinSelected?'Check-in':'Check-in Check-out' }</Text> 
                </DetailRow> 
                <DetailRow>
                  <Text
                    fontFamily='Roboto'
                    fontSize='10px'
                    fontWeight={'bold'}
                    padding={0}
                    color={COLORS.darkGray}
                  >Duraci&oacute;n</Text> 
                  <Text
                    marginLeft= '5px'
                    fontFamily='Roboto'
                    fontSize='10px'
                    padding={0}
                    color={COLORS.darkGray}
                  >{ service.payment ?service.payment.hour:''}:{  service.payment?service.payment.minute:''} h</Text> 
                </DetailRow>
                                                                              
              </DetailColumn>
            </DetailRow>
          </Grid>  

          <Grid item xs={12} style={{marginTop: '5px'}}>
            <DetailRow style={{alignItems: 'center'}}>
              <CoverImg img={'assets/images/service/pay-method.png'} />
              <DetailColumn>
                <DetailRow>
                  <Text
                    fontFamily='Roboto'
                    fontSize='10px'
                    fontWeight={'bold'}
                    padding={0}
                    color={COLORS.darkGray}
                  >M&eacute;todos de pago</Text> 
                  { service.payment
                      && service.payment.paymentOption
                      && service.payment.paymentOption.length>0?
                    <Text
                      marginLeft= '5px'
                      fontFamily='Roboto'
                      fontSize='10px'
                      padding={0}
                      color={COLORS.darkGray}
                    >  
                      {service.payment.paymentOption.map((d)=>d.text).join(' , ')}       
                    </Text>:
                    <Text
                      marginLeft= '5px'
                      fontFamily='Roboto'
                      fontSize='10px'
                      padding={0}
                      color={COLORS.darkGray}
                    >FibyPay</Text>                       
                  }

                </DetailRow>                                           
              </DetailColumn>
            </DetailRow>
          </Grid>                                          
        </Grid>        
        <Grid item xs={12} md={6}  >
          <DetailRow style={{alignItems: 'center'}}>
            <CoverImg img={'assets/images/service/schedules.png'} />
            <Text
              fontFamily='Roboto'
              fontSize='16px'
              fontWeight={'bold'}
              padding={0}
              color={COLORS.darkGray}
            >Horarios</Text>                         
          </DetailRow>
          <div style={{maxHeight: '250px',height:'250px', overflowX:'hidden',overflowY:'scroll'}}>
            {service.schedules && service.schedules.map((schedule,index)=>{
              return (<ScheduleOption 
                key={index} 
                onDeleteSchedule={onRemoveSchedule}
                schedule={schedule} 
                index={index} />);
            })}  
          </div>
    
          <Text
            onClick={onAddSchedule}
            textAlign='center'  
            fontFamily='Roboto'
            fontWeight='bold'
            padding={0}
            fontSize='14px'
            color={COLORS.blue}
            cursor='pointer'
          >
                  Agregar horario     
          </Text>      
          <Text            
            textAlign='center'  
            fontFamily='Roboto'
            padding={0}
            marginTop='5px'
            fontSize='12px'
            color={COLORS.green}
          >
                  Si seleccionas esta opción, tendrás que agregar el nuevo horario para continuar con la creación del servicio
          </Text>      
        </Grid>        
      </Grid>
      <Grid container >
        <Grid item xs={6} style={{textAlign:'center'}}>
          <Button
            context={'primary'}
            onClick={props.previousStep}
          >
                  Anterior
          </Button>
        </Grid>

        <Grid item xs={6} style={{textAlign:'center'}}>
          <Button
            disabled={!stepValid()}
            context={'primary'}
            onClick={props.nextStep}
          >
                Siguiente
          </Button>
        </Grid>
      </Grid>        
    </Container>);
};

const ScheduleOption = (props) => {
  const [open, setOpen] = useState(props.open);
  const {schedule,index} = props;
  return (
    <ScheduleContainer > 
      <Text
        fontFamily='Roboto'
        padding={0}
        fontSize='14px'
        fontWeight='bold'
        color={COLORS.darkGray}
      >Horario #{index+1}
        <IconDelete size='10px' float='right' onClick={()=>props.onDeleteSchedule(index)} />
        {open?<IconUp onClick={()=>setOpen(false)}/>:<IconDown  onClick={()=>setOpen(true)}/>}
        
      </Text>
      <Text
        fontFamily='Roboto'
        padding={0}
        fontSize='14px'
        color={COLORS.darkGray}
      >{moment(schedule.schedule.fromDate).format('MMMM')+'-'+moment(schedule.schedule.toDate).format('MMMM')}</Text> 
      <Text
        fontFamily='Roboto'
        padding={0}
        fontSize='14px'
        color={COLORS.darkGray}
      >
        {!schedule.schedule.singleChecked && schedule.schedule.days.map((day,index)=>{                 
          return (day.checked?<span key={index} style={{fontWeight: 'bold'}}>{day.text+' '}</span>:'');
        })}

        {schedule.schedule.singleChecked && schedule.schedule.dates.map((date,index)=>{                 
          return (<span key={index} style={{fontWeight: 'bold'}}>{moment(date).format('DD-MM-YYYY')+' '}</span>);
        })}
        {moment().set(
          {hour:schedule.schedule.fromTime.hour,
            minute:schedule.schedule.fromTime.minute})
          .format('HH:mm')}h&nbsp;a&nbsp;
        {moment().set(
          {hour:schedule.schedule.toTime.hour,
            minute:schedule.schedule.toTime.minute})
          .format('HH:mm')}h                    
      </Text> 
                
      {!schedule.schedule.singleChecked && <Text
        fontFamily='Roboto'
        padding={0}
        fontSize='14px'
        color={COLORS.darkGray}
      >
          D&iacute;as sin servicio&nbsp;
        {schedule.schedule.days.map((day,index)=>{                 
          return (!day.checked?<span key={index} style={{fontWeight: 'bold'}}>{day.text+' '}</span>:'');
        })}
                            
      </Text> }

      <div style={{borderTop: '1px solid '+COLORS.lightGray}}></div>
      {open && schedule.disponibility.schedules.map((sch,index)=>{                 
        return(<div key={index}> 
          <hr />
          <Text           
            fontFamily='Roboto'
            padding={0}
            fontSize='14px'
            fontWeight='bold'
            color={COLORS.darkGray}
          >
            {sch.name}     
          </Text> 

          <Text           
            fontFamily='Roboto'
            padding={0}
            fontSize='14px'
            color={COLORS.darkGray}
          >
              Cantidad de unidades {sch.units}     
          </Text> 
          <Text
                
            fontFamily='Roboto'
            padding={0}
            fontSize='14px'
            color={COLORS.darkGray}
          >
              Cantidad de plazas {sch.places}     
          </Text>
          <Text
            fontFamily='Roboto'
            padding={0}
            fontSize='14px'
            fontWeight='bold'
            color={COLORS.darkGray}
          >
                Precio por plaza     
          </Text>                   	
          {sch.rates.map((rate,rIndex)=>{
            return (<div key= {rate.id || rIndex}>

              <Text
                fontFamily='Roboto'
                padding={0}
                fontSize='14px'
                color={COLORS.darkGray}
              >
                {rate.name} {rate.price}     
              </Text>

            </div>);                                            
          }) }</div>);
      })}

    </ScheduleContainer>    
  );
};

const IconDown = styled.button`
    color: ${COLORS.darkGray};
    background-color: transparent;
    width: 20px;
    height: 20px;
    margin-left: 2px;
    margin-right: 2px;    
    float: right;
    cursor: pointer;
    position: relative;
    font-size: 120%;
    border: 0};
    ::before{
      content: '﹀';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%)       
    }
`;

const IconUp = styled.button`
    color: ${COLORS.darkGray};
    background-color: transparent;
    width: 20px;
    height: 20px;
    margin-left: 2px;
    margin-right: 2px;    
    float: right;
    cursor: pointer;
    position: relative;
    font-size: 120%;
    border: 0;
    ::before{
      content: '︿';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%)       
    }
`;

const DetailRow = styled.div`
  display: flex;
  align-items: flex-start;
`;

const DetailColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScheduleContainer = styled.div`
  border: 1px solid ${COLORS.lightGray};
  box-shadow: 2px 2px ${COLORS.lightGray};
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const CoverImg = styled.div`
  width: 48px;
  height: 48px;
  border: 0;
  margin-left: 5px;
  margin-right: 5px;
  background-image: url(${props => props.img || '' });
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: contain;
`;  
export default Resume;
