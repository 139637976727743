import { API } from '../../api';

const getCountry = onSuccess => () => {
  API.get('/dmz/countries').then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

const getGender = onSuccess => () => {
  API.get('/dmz/genders').then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

const getCategories = onSuccess => () => {
  API.get('/dmz/categories').then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

const getMethodPayment = (data, onSuccess) => () => {
  API.get('/paymentMethods', {
    headers: {
      token: localStorage.getItem('token_get_get_it'),
      uid: data.uid,
      idChannel: 'web',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

export { getCountry, getGender, getCategories, getMethodPayment };
