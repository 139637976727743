import React, { useState, useEffect } from 'react';
import {Grid,Container} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import styled from 'styled-components';
import Button from '../../components/Button';
import * as COLORS from '../../../../util/colors';
import * as UTILS from '../../../../util/utils';
import moment from 'moment';
import Text from '../../../../components/generics/Text';
import Input from '../../../../components/generics/Input';
import Calendar from '../../../../components/generics/Calendar';
import DurationTimer from '../../components/DurationTimer';
const _ = require('lodash');

const Schedule =(props) => {
  
  const [singleChecked,setSingleChecked] = useState((props.schedule 
      && props.schedule.schedule 
      && props.schedule.schedule.singleChecked) || true);
  const [fromDate,setFromDate] = useState((props.schedule 
      && props.schedule.schedule 
      && props.schedule.schedule.fromDate) || moment().format('YYYY-MM-DD'));

  const [toDate,setToDate] = useState((props.schedule 
      && props.schedule.schedule
      && props.schedule.schedule.toDate) || moment().format('YYYY-MM-DD'));
  const [fromTime,setFromTime] = useState({
    hour: (props.schedule 
        && props.schedule.schedule
        && props.schedule.schedule.fromTime
        && props.schedule.schedule.fromTime.hour) || '',
    minute: (props.schedule 
        && props.schedule.schedule
        && props.schedule.schedule.fromTime
        && props.schedule.schedule.fromTime.minute) || ''
  });
  const [toTime,setToTime] = useState({
    hour: (props.schedule 
        && props.schedule.schedule
        && props.schedule.schedule.toTime
        && props.schedule.schedule.toTime.hour) || '',
    minute: (props.schedule 
        && props.schedule.schedule
        && props.schedule.schedule.toTime
        && props.schedule.schedule.toTime.minute) || ''
  });
  const [days,setDays] = useState((props.schedule 
      && props.schedule.schedule
      && props.schedule.schedule.days) || UTILS.days(true));
  const [excludedDates,setExcludedDates] = useState((props.schedule 
      && props.schedule.schedule
      && props.schedule.schedule.excludedDates) || []);
  const [dates,setDates] = useState([(props.schedule 
      && props.schedule.schedule
      && props.schedule.schedule.fromDate)  || moment().format('YYYY-MM-DD')]);


  useEffect(()=>{
    if (props.editSchedule) {
      onClickPeriodType(true);
    }
  },[props])  ;

  useEffect(()=>{
    generateDates();
  },[fromDate, toDate,excludedDates,days])  ;


  const generateDates = () => {
    let dates = [];
    if (singleChecked) return;
    
    if (
      (typeof fromDate!=='undefined' && null!==fromDate)
          && (typeof toDate!=='undefined' && null!==toDate)

    ){
      let begin = moment(fromDate).valueOf();
      let end = moment(toDate).valueOf();           
      while (begin<=end) {
        let d = moment(begin).format('YYYY-MM-DD');
        let dayOfWeek = moment(begin).day();
              
        let iExc = excludedDates.findIndex((exc)=>exc===d);
        let iDay = days.findIndex((di)=> (di.n===dayOfWeek && di.checked ));
        if (iExc==-1 && iDay>=0) {
          dates.push(d);
        }
             
        begin = moment(begin).add(1,'days').valueOf();
      }
    }
    setDates(dates);
  };

  const stepValid = () => {
    let isValid= (
      fromDate.length>0
        && fromTime.hour.length>0
        && fromTime.minute.length>0
        && toTime.hour.length>0
        && toTime.minute.length>0        
    );

    if (!singleChecked) {
      isValid &=toDate.length>0;
      isValid &= dates.length>=2;
    }

    if (singleChecked) {
      isValid &= dates.length>0;
    }

    let d1 = moment().set({hour:fromTime.hour, minute:fromTime.minute });
    let d2= moment().set({hour:toTime.hour, minute:toTime.minute });

    isValid &= d2.isAfter(d1) || d2.isBefore(d1);

    dates.forEach((newDate)=>{
      let newD1 = moment(newDate).set({hour:fromTime.hour ,minute: fromTime.minute, second:0});
      let newD2 = moment(newDate).set({hour:toTime.hour ,minute: toTime.minute, second:0});
      if (newD2.isBefore(newD1)) {
        newD2 = moment().add(1,'day').set({hour: toTime.hour,minute:toTime.minute,second:0  });
      }

      isValid &= newD1.isAfter(moment());
      isValid &= newD2.isAfter(moment());

    });

    return isValid;
  };

  const onDayClick = (day) => {
    const dayData = days.map((item)=>{
      if (day.id === item.id) {
        return {...day,checked:!day.checked};
      }
      return {...item};
    });
    setDays(dayData);      
  };

  const onChangeDate = (evt) => {
    
    if (!isDateAfterNow(evt)) {
      const msg = {
        show: true,
        msg: 'El servicio no puede iniciar en una fecha menor a la presente',
        type: 'error'                  
      };

      props.onShowMessage(msg);
      return;      
    }

    
    const date= moment(evt).format('YYYY-MM-DD');
    const mDate= moment().add(props.planUser.timeReserve,'months');
    
    if (moment(evt).isAfter(mDate)) {
      const msg = {
        show: true,
        msg: 'Excede el rango permitido de ' +props.planUser.timeReserve+' mes'+(props.planUser.timeReserve>1?'es':''),
        type: 'error'                  
      };

      props.onShowMessage(msg);
      return;
    }
    
    const index = _.findIndex(dates, (item)=> { return date === item; });
    let data = [];
    if (index>=0) {
      data = _.filter(dates, (item) =>{
        return date !== item;
      });
      data = data.sort((d1,d2)=>moment(d2).valueOf - moment(d1).valueOf);
      setDates(data);
    }
    else{
      data = [...dates,date];
      data = data.sort((d1,d2)=>moment(d2).valueOf - moment(d1).valueOf);
      setDates(data);
    }
    const maxDate = moment.max(data.map((d)=>moment(d)));
    const minDate = moment.min(data.map((d)=>moment(d)));
    setFromDate(minDate.format('YYYY-MM-DD'));
    setToDate(maxDate.format('YYYY-MM-DD'));

  };

  const onExcludeChangeDate = (evt) => {
    const date= moment(evt).format('YYYY-MM-DD');
    const index = _.findIndex(excludedDates, (item)=> { return date === item; });
    if (index>=0) {
      const data = _.filter(excludedDates, (item) =>{
        return date !== item;
      });
      setExcludedDates(data);
    }
    else{
      setExcludedDates([...excludedDates,date]);
    }
  };

  const onFromDateChange = (date) => {
    if (!isDateAfterNow(date)) {
      const msg = {
        show: true,
        msg: 'El servicio no puede iniciar en una fecha menor a la presente',
        type: 'error'                  
      };

      props.onShowMessage(msg);
      return;       
    }

    if (toDate) {
      if (!isDateBefore(date,toDate)) {
        const msg = {
          show: true,
          msg: 'La fecha de inicio del servicio no puede ser mayor a su fecha de culminación',
          type: 'error'                  
        };
  
        props.onShowMessage(msg);
        return;         
      }
    }

    const sdate= moment(date);
    const mDate= moment().add(props.planUser.timeReserve,'months');
    
    if (sdate.isAfter(mDate)) {
      const msg = {
        show: true,
        msg: 'Excede el rango permitido de ' +props.planUser.timeReserve+' mes'+(props.planUser.timeReserve>1?'es':''),
        type: 'error'                  
      };

      props.onShowMessage(msg);
      return;
    }

    setFromDate(moment(date).format('YYYY-MM-DD'));
  };

  const onToDateChange = (date) => {
    if (!isDateAfterNow(date)) {
      const msg = {
        show: true,
        msg: 'El servicio no puede iniciar en una fecha menor a la presente',
        type: 'error'                  
      };

      props.onShowMessage(msg);
      return;        
    }

    if (fromDate) {
      if (!isDateBefore(fromDate,date)) {
        const msg = {
          show: true,
          msg: 'Debes seleccionar una fecha y hora válidas',
          type: 'error'                  
        };
  
        props.onShowMessage(msg);
        return;          
      }
    }

    const sdate= moment(date);
    const mDate= moment().add(props.planUser.timeReserve,'months');
    
    if (sdate.isAfter(mDate)) {
      const msg = {
        show: true,
        msg: 'Excede el rango permitido de ' +props.planUser.timeReserve+' mes'+(props.planUser.timeReserve>1?'es':''),
        type: 'error'                  
      };

      props.onShowMessage(msg);
      return;
    }
        
    setToDate(moment(date).format('YYYY-MM-DD'));
  };

  const onFromDurationChange = (hour,minute) => {
    let dataChange = {...fromTime};
    dataChange = {hour, minute};
    setFromTime(dataChange);
  };

  const onToDurationChange = (hour,minute) => {
    let dataChange = {...toTime};
    dataChange = {hour, minute};
    setToTime(dataChange);  
  };

  const isDateAfterNow = (val) => {
    const realDate = moment(val).set({hour:0,minute: 0, second: 0});
    const now = moment().set({hour:0,minute: 0, second: 0});
    return (realDate.isAfter(now) || (realDate.format()===now.format()));
  };

  const isDateBefore = (d1,d2) => {
    const initDate = moment(d1).set({hour:0,minute: 0, second: 0});
    const endDate = moment(d2).set({hour:0,minute: 0, second: 0});
    return (initDate.isBefore(endDate)  || (initDate.format()===endDate.format()));
  };

  const onClickPeriodType = (single) =>{
    setSingleChecked(single);
    setFromDate((props.schedule 
        && props.schedule.schedule 
        && props.schedule.schedule.fromDate) || moment().format('YYYY-MM-DD'));
    setToDate((props.schedule 
        && props.schedule.schedule
        && props.schedule.schedule.toDate) || moment().format('YYYY-MM-DD'));
    setFromTime({
      hour: (props.schedule 
          && props.schedule.schedule
          && props.schedule.schedule.fromTime
          && props.schedule.schedule.fromTime.hour) || '0',
      minute: (props.schedule 
          && props.schedule.schedule
          && props.schedule.schedule.fromTime
          && props.schedule.schedule.fromTime.minute) || '0'
    });
    setToTime({
      hour: (props.schedule 
          && props.schedule.schedule
          && props.schedule.schedule.toTime
          && props.schedule.schedule.toTime.hour) || '0',
      minute: (props.schedule 
          && props.schedule.schedule
          && props.schedule.schedule.toTime
          && props.schedule.schedule.toTime.minute) || '0'
    });
    setDays((props.schedule 
        && props.schedule.schedule
        && props.schedule.schedule.days) || UTILS.days(true));
    setExcludedDates((props.schedule 
        && props.schedule.schedule
        && props.schedule.schedule.excludedDates) || []);
    setDates([(props.schedule 
        && props.schedule.schedule
        && props.schedule.schedule.fromDate)  || moment().format('YYYY-MM-DD')]);
  };

  const onSave = () => {
    let duration = (parseInt(props.service.payment.hour) *60) + parseInt(props.service.payment.minute);

    let d1 = moment().set({hour: fromTime.hour,minute:fromTime.minute ,second:0 });
    let d2 = moment().set({hour: toTime.hour,minute:toTime.minute ,second:0 });
    if (d2.isBefore(d1)) {
      d2 = moment().add(1,'day').set({hour: toTime.hour,minute:toTime.minute ,second:0 });
    }


    let minutes = parseInt(d2.diff(d1) / (1000 * 60));

    if (minutes%duration!==0) {
      const msg = {
        show: true,
        msg: 'El rango de tiempo no coincide con la duración colocada al servicio',
        type: 'error'                  
      };

      props.onShowMessage(msg);
      return;
    }
    
    let shValid = true; 
    dates.forEach((newDate)=>{
      let newD1 = moment(newDate).set({hour:fromTime.hour ,minute: fromTime.minute, second:0});
      let newD2 = moment(newDate).set({hour:toTime.hour ,minute: toTime.minute, second:0});
      if (newD2.isBefore(newD1)) {
        newD2 = moment().add(1,'day').set({hour: toTime.hour,minute:toTime.minute,second:0  });
      }

      shValid &= newD1.isAfter(moment());
      shValid &= newD2.isAfter(moment());

    });


    if (!shValid) {
      const msg = {
        show: true,
        msg: 'Debes seleccionar una fecha y hora válidas',
        type: 'error'                  
      };

      props.onShowMessage(msg);
      return;
    }

    if (props.service 
      && props.service.schedules
      && props.service.schedules.length) {

      let shValid = true; 
      props.service.schedules.forEach((sch) => {

        if (sch.id===props.schedule.id) {
          return;
        }

        sch.schedule.dates.forEach((date)=>{
          const sFromTime = sch.schedule.fromTime;
          const sToTime = sch.schedule.toTime;
          let d1 = moment(date).set({hour:sFromTime.hour ,minute: sFromTime.minute, second:0});
          let d2 = moment(date).set({hour:sToTime.hour ,minute: sToTime.minute, second:0});
          if (d2.isBefore(d1)) {
            d2 = moment().add(1,'day').set({hour: sToTime.hour,minute:sToTime.minute,second:0  });
          }

          dates.forEach((newDate)=>{
            let newD1 = moment(newDate).set({hour:fromTime.hour ,minute: fromTime.minute, second:0});
            let newD2 = moment(newDate).set({hour:toTime.hour ,minute: toTime.minute, second:0});
            if (newD2.isBefore(newD1)) {
              newD2 = moment().add(1,'day').set({hour: toTime.hour,minute:toTime.minute,second:0  });
            }

            shValid &= !(d1.isBetween(newD1,newD2) || d2.isBetween(newD1,newD2));
            shValid &= !(newD1.isBetween(d1,d2) || newD2.isBetween(d1,d2));

            shValid &= !(newD1.valueOf() === d1.valueOf());
            shValid &= !(newD1.valueOf() === d2.valueOf());
            shValid &= !(newD2.valueOf() === d2.valueOf());
            shValid &= !(newD2.valueOf() === d1.valueOf());

          });
        });
      });

      if (!shValid) {
        const msg = {
          show: true,
          msg: 'No se pueden colocar dos horarios iguales o que coinciden entre si',
          type: 'error'                  
        };
  
        props.onShowMessage(msg);        
        return;
      }
    }


    const dataChange= {
      singleChecked,
      fromDate,
      toDate,
      fromTime,
      toTime,
      days,
      excludedDates,
      dates
    };
    props.onChange && props.onChange(dataChange);
    props.nextStep();
  };

  return (
    <Container >
       <Text
          fontFamily='Roboto'
          fontSize='13px'
          textAlign='center'
          color="#DA0458"
          fontWeight="bold">
        10* Selecciona el tipo de servicio</Text> 
      <Grid container >
        <Grid item xs={12}>
          <ContainerCalendar>         
            <CalendarSingle 
              onClick={()=>{onClickPeriodType(true);}}
              checked={singleChecked}> 
              <Text
                fontFamily='Roboto'
                fontSize='12px'
                color={COLORS.lightBlue}
              >Eventual</Text>                           
            </CalendarSingle>              

            <CalendarMulti 
              tileClassName={''}
              onClick={()=>{onClickPeriodType(false);}}
              checked={!singleChecked}> 
              <Text
                fontFamily='Roboto'
                fontSize='12px'
                color={COLORS.lightBlue}
              >Periódico</Text>                              
            </CalendarMulti>            
          </ContainerCalendar> 
        </Grid>
        <Grid item xs={12}>
          {singleChecked?<SinglePeriod 
            fromDate={fromDate}
            fromTime={fromTime}
            toTime={toTime}
            dates={dates}
            onChangeDate={onChangeDate}
            onFromDurationChange ={onFromDurationChange}  
            onToDurationChange ={onToDurationChange}  
          />
            :<MultiPeriod 
              days={days}
              dates={dates}
              onFromDateChange={onFromDateChange}
              onToDateChange={onToDateChange}
              fromTime={fromTime}
              toTime={toTime}
              fromDate={fromDate}
              toDate={toDate}
              onChangeDate={onExcludeChangeDate}
              onDayClick={onDayClick}
              onFromDurationChange ={onFromDurationChange}  
              onToDurationChange ={onToDurationChange}                  
            />} 
        </Grid>
      </Grid>    

      <Grid container >
        <Grid item xs={6} style={{textAlign:'center'}}>
          <Button
            disabled={props.editSchedule}
            context={'primary'}
            onClick={props.previousStep}
          >
                  Anterior
          </Button>
        </Grid>

        <Grid item xs={6} style={{textAlign:'center'}}>
          <Button
            disabled={!stepValid()}
            context={stepValid()?'primary':''}
            onClick={onSave}
          >
                Siguiente
          </Button>
        </Grid>
      </Grid>          
    </Container>

  );
    
};

const SinglePeriod = (props) => {  
  return(   
  <Grid container style={{alignItems:'center',justifyContent:'space-between'}}>   
  <Text
    marginTop='15px'
    fontFamily='Roboto'
    fontSize='12px'
    marginLeft='10px'
    marginBottom='10px'
    textAlign='center'
    color={COLORS.purple}
  > El horario eventual es ideal para servicios que serán ofrecidos en un lapso de tiempo limitado y no perdurarán en el tiempo. </Text>   
    <Grid item xs={12} md={6} style={{'paddingBottom':'5px'}}>
      <Calendar
        headerText={'Marca las fechas en que prestaras tu servicio'}
        selectedDay={props.dates}
        onChange={props.onChangeDate}
      /> 
    </Grid>
    <Grid item xs={12} md={3}>
      <Text         
         fontFamily='Roboto'
         fontSize='13px'
         textAlign='center'
         color="#DA0458"
         fontWeight="bold">
      * Hora de inicio</Text>            
      <DurationTimer 
        hour={props.fromTime.hour}
        minute={props.fromTime.minute}
        onChange={props.onFromDurationChange}
        minutesRange = {UTILS.range(0,60,1)}
        width='100%'/>            
    </Grid>
    <Grid item xs={12} md={3}>
      <Text
         fontFamily='Roboto'
         fontSize='13px'
         textAlign='center'
         color="#DA0458"
         fontWeight="bold"
      >* Hora de culminación</Text>               
      <DurationTimer 
        hour={props.toTime.hour}
        minute={props.toTime.minute}
        onChange={props.onToDurationChange}
        minutesRange = {UTILS.range(0,60,1)}
        width='100%'/>          
    </Grid>
  </Grid>);
};

const MultiPeriod = (props) => {
  return(
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
     <Text
          marginTop='15px'
          fontFamily='Roboto'
          fontSize='12px'
          marginBottom='10px'
          textAlign='center'
          color={COLORS.purple}
        > El horario periódico está ideado para servicios que serán ofrecidos durante períodos perdurables en el tiempo. </Text>
      <Grid container style={{alignItems:'center',justifyContent:'space-between'}}>                   
        <Grid item xs={12} md={6} >
          <Text
            fontFamily='Roboto'
            fontSize='13px'
            marginBottom='10px'
            color="#DA0458"
            fontWeight="bold"
          >* Per&iacute;odo de atenci&oacute;n</Text> 

          <Grid container style={{alignItems:'center',justifyContent:'space-between'}} >
          <div>
              <Text
                fontFamily='Roboto'
                color={COLORS.darkGray}
                padding='2'
                margin='0'
              >Fecha de culminación</Text> 
              <Input 
                type='text'
                width='auto' 
                placeholder={props.toDate || ''}
                onClick={() =>document.getElementById('toDate').click()}
              /> 
              <DatePicker
                label=""
                value={props.toDate || ''}
                onChange={props.onToDateChange}
                animateYearScrolling
                id="toDate"
                style={{ display: 'none' }}
              />
            </div>              
            <div>
              <Text
                fontFamily='Roboto'
                color={COLORS.darkGray}
                margin='0'
              >Fecha de inicio</Text>           
              <Input 
                type='text' 
                width='auto' 
                placeholder={props.fromDate || ''}
                onClick={() =>document.getElementById('fromDate').click()}
              /> 
              <DatePicker
                label=""
                value={props.fromDate || ''}
                onChange={props.onFromDateChange}
                animateYearScrolling
                id="fromDate"
                style={{ display: 'none' }}
              />            
            </div>
            
          </Grid> 
          <Grid container style={{marginTop:'10px',justifyContent:'space-between'}}  >
            {props.days.map((item)=><ContainerDay 
              onClick={()=>{props.onDayClick(item);}}
              checked={item.checked} 
              key={item.id}>{item.text}</ContainerDay>)}
          </Grid>
    
          <Grid container  style={{marginTop:'5px'}}  >
            <Grid item xs={12} md={6}>
              <Text
                fontFamily='Roboto'
                fontSize='13px'
                textAlign='center'
                color="#DA0458"
                fontWeight="bold"
              >* Hora de inicio</Text>            
              <DurationTimer 
                hour={props.fromTime.hour}
                minute={props.fromTime.minute}
                onChange={props.onFromDurationChange}
                minutesRange = {UTILS.range(0,60,1)}
                width='90%'/>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text
                fontFamily='Roboto'
                fontSize='13px'
                textAlign='center'
                color="#DA0458"
                fontWeight="bold"
              >* Hora de culminación</Text>               
              <DurationTimer 
                hour={props.toTime.hour}
                minute={props.toTime.minute}
                onChange={props.onToDurationChange}
                minutesRange = {UTILS.range(0,60,1)}
                width='80%'/>             
            </Grid>
          </Grid>    
              
        </Grid>

        <Grid item xs={12} md={6} >
          <Calendar
            headerText={'Desmarca los días en los que no prestaras tu servicio'}
            margin='5px'
            selectedDay={props.dates}
            onChange={props.onChangeDate}
          /> 
        </Grid>
      </Grid></MuiPickersUtilsProvider>);    
};

const ContainerDay = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color:  ${props =>props.checked?COLORS.lightBlue:COLORS.darkGray};
  text-align: center;
  margin-left: 2px;
  margin-right: 2px;
  border: 1px solid ${props =>props.checked?COLORS.lightBlue:COLORS.darkGray};
  cursor: pointer;
`;

const ContainerCalendar = styled.div`
  display: flex;
`;


const CalendarSingle = styled.div`
  height: 50px; 
  cursor: pointer;
  background-image: url(${props => props.checked? 'assets/images/service/schedule.png':'assets/images/service/schedule-light.png' });
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: auto;
  margin: 0 auto;
  p{
      color: ${props => props.checked? COLORS.lightBlue:COLORS.purple };
      top: 50%;
      left: 100%;
      position: relative;
  }  
`;

const CalendarMulti = styled.div`
  height: 50px;
  cursor: pointer;
  background-image: url(${props => props.checked?'assets/images/service/schedule-multi.png':'assets/images/service/schedule-multi-light.png' });
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: auto;
  margin: 0 auto;
  p{
      color: ${props => props.checked? COLORS.lightBlue:COLORS.purple };
      top: 50%;
      left: 100%;
      position: relative;
  }  
`;

export default Schedule;
