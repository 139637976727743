import * as types from './types';

const applicationForm = (obj) => {
  return {
    type: types.ONCHANGE_FORM,
    payload: {
      obj
    }
  };
};

export { applicationForm };
