import * as types from './types';

const initialState = {
  countries: [],
  states: [],
  gender: [],
  categories: [],
  payments: [],
};

export default function generalStore(state = initialState, action) {
  switch (action.type) {
    case types.GET_LIST_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case types.GET_LIST_STATE:
      return {
        ...state,
        states: action.payload,
      };
    case types.GET_LIST_GENDER:
      return {
        ...state,
        gender: action.payload,
      };
    case types.GET_LIST_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case types.GET_LIST_PAYMENTS:
      return {
        ...state,
        payments: action.payload,
      };
    default:
      return state;
  }
}
