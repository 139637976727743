import React, { Component } from 'react';
import styled from 'styled-components';
import Text from '../generics/Text';
import ItemsPlan from './ItemsPlan';
import lang from '../../lang'

class Collapse extends Component {
  state = {
    open: this.props.showCollapse || false,
  };

  render() {
    return (
      <Container>
        <WrapIconText onClick={() => this.setState({ open: !this.state.open })}>
          {this.state.open ? (
            <IconArrow src="/assets/images/down-arrow.svg" alt="up-arrow" />
          ) : (
            <IconArrow src="/assets/images/up-arrow.svg" alt="up-arrow" />
          )}
          <Text fontFamily="Roboto-Bold" fontSize="30px" color="#1E1E1E">
            {this.props.title}
          </Text>
        </WrapIconText>
        {this.props.plans ? (
          <div>
            <ItemsPlan />
            <TextPlan onClick={() => this.setState({ open: true })}>
              {lang.changePlan}
            </TextPlan>
          </div>
        ) : null}
        {this.state.open ? (
          <Children height={this.state.open ? '100%' : 'calc(0px - 10px)'}>
            {this.props.children}
          </Children>
        ) : null}
      </Container>
    );
  }
}
const Container = styled.div`
  width: calc(100% - 20px);
  padding-right: 20px;
  height: auto;
`;
const WrapIconText = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;
const IconArrow = styled.img`
  width: 15px;
  height: auto;
  margin-right: 10px;
`;
const Children = styled.div`
  width: calc(100% - 20px);
  height: ${props => props.height};
  /* margin-right: 10px; */
  overflow: hidden;
  padding: 10px;
  /* background-color: red; */
`;
const TextPlan = styled.p`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  color: #333480;
  text-align: left;
  margin-left: 25px;
  margin-top: 5px;
  margin-bottom: 10px;
  cursor: pointer;
`;
export default Collapse;
