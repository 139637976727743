import React from 'react';
import styled from 'styled-components';
import Text from '../../../../components/generics/Text';

const  StepPagination = (props) => (
  <StepsContainer>
    {props.steps && props.steps.map((d,i)=> (
      <StepItem
        key={'Pagination-'.concat(i)}
        show={ (Math.ceil(props.step/props.size)===Math.ceil((i+1)/props.size))  ?'block':'none'}
      >
        <StepStatus
          dotWidth= {25}
          variant={( (i+1)<=props.step)?'#6E00BF':'#f2f2f2'}
          text={(i+1)}
        >
          <Text
            fontFamily="Roboto"
            fontSize="12px"
            marginTop="10px"
          >{d} </Text>
        </StepStatus>
      </StepItem>
    ))}
  </StepsContainer>
);


const StepsContainer = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const StepItem = styled.li`
  transition: all 200ms ease-in;
  margin-top: 20px;
  display: ${props => props.show};
`;

const StepStatus = styled.div`
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  border-top: 2px solid ${props => props.variant};
  position: relative;
  transition: all 200ms ease-in;
  &:before {
    content: '${props => props.text}';
    color:  #fff;
    top: -15px;
    position: absolute;
    transition: all 200ms ease-in ;
    width: ${props => props.dotWidth || 25}px;
    height: ${props => props.dotWidth || 25}px;
    border-radius: 50%;
    text-align: center;
    background-color: ${props => props.variant};
  };
  p{
    color:  ${props => props.variant};
  }

  @media (max-width: 767px) { padding: 0px 20px; }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) { }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {  }  
`;

export default StepPagination;