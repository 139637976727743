import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import Text from '../../components/generics/Text';
// import Input from '../../components/generics/Input';
import InputPass from '../../components/generics/InputPass';
import ButtonLoader from '../../components/generics/Button';
import { applicationForm } from '../../ducks/changeForm';
import { putChangePassword } from '../../ducks/access';
import lang from '../../lang'

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    const obj = {
      errorPassword: '',
      newPassword: '',
      repeatPassword: '',
      errorRepeatPassword: '',
    };
    props.applicationForm(obj);
    this.state = {
      scene: 'emailError',
      load: false,
      errorMessage: '',
      success: '',
    };
  }

  handleClickSend = () => {
    const form = JSON.stringify(this.props.formChange);
    const formParse = JSON.parse(form);
    const validate = this.validate(formParse);
    if (validate) {
      const data = {
        uid: this.props.detailUser.responseInfo.uid,
        pwd: this.props.formChange.newPassword,
      };
      this.setState({
        load: true,
      });
      this.props.putChangePassword(data, Response => {
        if (Response.code >= 200 && Response.code <= 299) {
          this.setState({
            success: lang.passwordChanged,
            errorMessage: '',
            load: false,
          });
          const obj = {
            errorPassword: '',
            newPassword: '',
            repeatPassword: '',
            errorRepeatPassword: '',
          };
          this.props.applicationForm(obj);
        } else {
          this.setState({
            success: '',
            errorMessage: Response.message,
            load: false,
          });
        }
      });
    }
  };

  validate = data => {
    if (!data) {
      return false;
    }
    if (!data.newPassword) {
      data.errorPassword = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    } else if (data.newPassword.length <= 5) {
      data.errorPassword = lang.thePasswordMustBeAtLeast;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    if (!data.repeatPassword) {
      data.errorRepeatPassword = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (!(data.repeatPassword === data.newPassword)) {
      data.errorRepeatPassword = lang.passwordsDoNotMatch;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    return true;
  };

  handleOnchangeEmail = (prop, errorProp) => event => {
    const onChangeForm = JSON.stringify(this.props.formChange);
    const parseChangeForm = JSON.parse(onChangeForm);
    parseChangeForm[prop] = event.target.value;
    parseChangeForm[errorProp] = '';
    const obj = {
      ...parseChangeForm,
    };
    this.props.applicationForm(obj);
  };

  render() {
    return (
      <Background>
        <HeaderLogo src="/assets/images/logo-small.svg" alt="logo" />
        <div className="total-center" style={{ height: '70vh' }}>
          <div>
            <Text fontSize="32px" color="#FFF" textAlign="center">
              {lang.AchieveWhatYouImagineToReach}
            </Text>
            <Text
              marginTop="10px"
              fontFamily="Metropolis-Medium"
              fontSize="22px"
              color="#FFF"
              textAlign="center"
            >
              {lang.OfferYourServices}
            </Text>
            <FormWrap>
              <div className="total-center">
                <img
                  src="/assets/images/logo.svg"
                  alt="logo"
                  width="auto"
                  height="35px"
                />
              </div>
              <AlignInfo>
                {this.state.success ? null : (
                  <div>
                    <InputPass
                      placeholder={lang.enterNewPassword}
                      value={
                        this.props.formChange &&
                        this.props.formChange.newPassword
                          ? this.props.formChange.newPassword
                          : ''
                      }
                      onChange={this.handleOnchangeEmail(
                        'newPassword',
                        'errorPassword',
                      )}
                      error={
                        this.props.formChange &&
                        this.props.formChange.errorPassword
                      }
                      message={
                        this.props.formChange &&
                        this.props.formChange.errorPassword
                      }
                      type={'password'}
                    />
                    <div style={{ marginTop: '10px' }}>
                      <InputPass
                        marginTop="10px"
                        placeholder={lang.confirmNewPassword}
                        value={
                          this.props.formChange &&
                          this.props.formChange.repeatPassword
                            ? this.props.formChange.repeatPassword
                            : ''
                        }
                        onChange={this.handleOnchangeEmail(
                          'repeatPassword',
                          'errorRepeatPassword',
                        )}
                        message={
                          this.props.formChange &&
                          this.props.formChange.errorRepeatPassword
                        }
                        error={
                          this.props.formChange &&
                          this.props.formChange.errorRepeatPassword
                        }
                        type={'password'}
                      />
                    </div>
                  </div>
                )}
                <div style={{ width: '100%' }} className="total-center">
                  {this.state.success ? (
                    <div style={{ width: '100%' }}>
                      <div className="total-center">
                        <p
                          style={{
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '18px',
                          }}
                        >
                          {this.state.success}
                        </p>
                      </div>
                      <div
                        style={{ width: '100%', marginTop: '20px' }}
                        className="total-center"
                      >
                        <ButtonLoader
                          maxWidth="370px"
                          marginTop="20px"
                          onClick={() => this.props.history.push('/')}
                        >
                          {lang.follow}
                        </ButtonLoader>
                      </div>
                    </div>
                  ) : (
                    <div style={{ width: '100%' }} className="total-center">
                      {this.state.load ? (
                        <div style={{ marginTop: '15px' }}>
                          <CircularProgress />
                        </div>
                      ) : (
                        <ButtonLoader
                          maxWidth="370px"
                          marginTop="20px"
                          onClick={this.handleClickSend}
                        >
                          {lang.save}
                        </ButtonLoader>
                      )}
                    </div>
                  )}
                </div>
              </AlignInfo>
            </FormWrap>
          </div>
        </div>
      </Background>
    );
  }
}

const mapStateToProps = state => {
  return {
    formChange: state.changeForm.form,
    detailUser: state.accessStore.detailStore.data,
  };
};

export default connect(
  mapStateToProps,
  { applicationForm, putChangePassword },
)(ChangePassword);

const Background = styled.div`
  background-image: url('/assets/images/background.png');
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100vh;
`;
const HeaderLogo = styled.img`
  width: auto;
  height: 45px;
  position: relative;
  margin-top: 25px;
  margin-left: 40px;
  @media (max-width: 480px) {
    height: 25px;
  }
`;
const FormWrap = styled.div`
  margin-top: 20px;
  background: background: linear-gradient(#1e1e1e, #1e1e1e) padding-box,
  linear-gradient(90deg, rgba(51,52,128,1) 0%, rgba(110,0,191,1) 100%) border-box;
  background-opacity: 0.1;
  width: calc(100% - 40px);
  width: 100%;
  max-width: 460px;
  height: 340px;
  border-radius: 5px;
  border: 5px solid transparent;
  border-width: 2px;
  padding: 20px;
  padding-top: 40px;
  @media (max-width: 480px) {
    width: 80vw;
  }
`;
const AlignInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90%;
`;
