import React, { Component,useState } from 'react';
import {Grid, Container} from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';
import Button from '../../components/Button';
import DurationTimer from '../../components/DurationTimer';
import Text from '../../../../components/generics/Text';
import Select from '../../components/Select';
import * as COLORS from '../../../../util/colors';
import * as UTILS from '../../../../util/utils';
import {MIN_SERVICES_TIME} from '../../../../constants';

const PaymentMethod = (props)=> {
  const [checkinSelected, setCheckin] = useState(props.checkinSelected || true);
  const [fibyPayChecked, setFibyPay] = useState(props.fibyPayChecked || false);
  const [hour, setHour] = useState(props.hour || '');
  const [minute, setMinute] = useState(props.minute || '');
  const [paymentOption, setPaymentOption] = useState(props.paymentOption || []);
  const [paymentOptions, setPaymentOptions] = useState(props.paymentOptions.map((payOpt)=>{return {id:payOpt.id,text: payOpt.name};}));


  const onClickCheck = (check) =>{
    setCheckin(check);
  };

  const onClickPay =(check) =>{
    let newPaymentOption = [];
    if (paymentOption && paymentOption.length>0) {
      newPaymentOption = [...paymentOption];
    }
    setFibyPay(check);
    setPaymentOption(check?[]:[...newPaymentOption]);
  };

  const onDurationChange = (hour,minute) => {
    setHour(hour);
    setMinute(minute);
  };

  const shopPaymentOptionChange = (evt) => {
    setPaymentOption(evt);
  };

  const stepValid = () => {
    let isValid = (
      hour.length>0
          && minute.length>0
    );

    if (isValid) {
      let d1 = moment().set({hour: hour, minute: minute, second:0});
      let d2 = moment().set({hour: 0, minute: 0, second:0});
      isValid &=parseInt(d1.diff(d2,'minutes'))>=MIN_SERVICES_TIME;
    }

    if (!fibyPayChecked) {      
      isValid &= (paymentOption && paymentOption.length>0);    
    }
    return isValid;    
  };

  const onSave = () => {

    const dataChange= {
      checkinSelected,
      fibyPayChecked,
      hour,
      minute,
      paymentOption,
      paymentOptions
    };
    props.onChange && props.onChange(dataChange);
    props.nextStep();
    console.log('actualizado payment');
  };


  return (<Container>
    <Grid container style={{width:'95%',margin: '0 auto'}}>
      <Grid item xs={12} sm={6}>
        <Text
          fontFamily='Roboto'
          fontSize='13px'
          marginBottom='10px'
          textAlign='center'
          color="#DA0458"
          fontWeight="bold">
        7* Tipo de reserva</Text> 

        <CheckInImg 
          onClick={()=>{onClickCheck(true);}}
          checked={checkinSelected}
          marginTop='10px'>        
          <span className='label' marginTop='10px'>
                    Check-in
          </span>
                                
        </CheckInImg>
        
      {/*   <CheckInOutImg 
          onClick={()=>{onClickCheck(false);}}
          checked={!checkinSelected}>
          <span className='label'>Check-in<br /></span>
          <span className='label'>Check-out</span> 
        </CheckInOutImg>  */}
        

      </Grid>
      <Grid item xs={2} sm={6}>    
      <Text
          fontFamily='Roboto'
          fontSize='13px'
          textAlign='center'
          marginBottom='10px'
          color="#DA0458"
          fontWeight="bold">
        8* Selecciona los m&eacute;todos de pago</Text>     
        {/* <FibyPayImg 
          onClick={()=>{onClickPay(true);}}
        checked={fibyPayChecked}/> */}
        <ShopPayImg 
         // onClick={()=>{onClickPay(true);}}
          checked={!fibyPayChecked} >
          <span className='label'>En establecimiento</span> 

        </ShopPayImg>
        <div style={{display: (!fibyPayChecked?'block':'none') }}>
          <Select
            key ='paymentoptions'
            onChange={shopPaymentOptionChange}
            items={paymentOptions}
            selected={paymentOption}
            multi={true}
            marginTop='10px'
            fontColor='#808080'
            placeholder="Métodos de pago"
          />

        </div>

      </Grid>
    </Grid>
    <Grid container style={{width:'70%',margin: '0 auto'}}>
      <Grid item xs={12} sm={12} >
        <Text
          fontFamily='Roboto'
          fontSize='13px'
          marginTop='40px'
          marginBottom='10px'
          textAlign='center'
          color="#DA0458"
          fontWeight="bold">
        9* Coloca la duraci&oacute;n del servicio</Text> 
        <DurationTimer 
          hour={hour}
          minute={minute}
          onChange={onDurationChange}
          minutesRange={UTILS.range(0,60,15)}
          width='40%'/>
          <Text
          marginTop='15px'
          fontFamily='Roboto'
          fontSize='12px'
          marginBottom='10px'
          textAlign='center'
          color={COLORS.purple}
        > El primer indicador corresponde a las horas y el segundo a los minutos. </Text> 
      </Grid>
    </Grid>
    <Grid container >
      <Grid item xs={6} style={{textAlign:'center'}}>
        <Button
          context={'primary'}
          onClick={props.previousStep}
        >
                  Anterior
        </Button>
      </Grid>

      <Grid item xs={6} style={{textAlign:'center'}}>
        <Button
          disabled={!stepValid()}
          context={stepValid()?'primary':''}
          onClick={onSave}
        >
                Siguiente
        </Button>
      </Grid>
    </Grid>
  </Container>
  );
};



const CheckInImg = styled.div`
  height: 50px; 
  cursor: pointer;
  background-image: url(${props => props.checked? 'assets/images/service/checkin.png':'assets/images/service/checkin-light.png' });
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: auto;
  margin: 0 auto;
  .label{
      font-family: 'Roboto';
      font-size: 12px;
      color: ${props => props.checked? COLORS.lightBlue:COLORS.lightGray };
      top: 50%;
      left: 60%;
      position: relative;
  }  
`;

const CheckInOutImg = styled.div`
  height: 50px;
  cursor: pointer;
  background-image: url(${props => props.checked?'assets/images/service/checkin-checkout.png':'assets/images/service/checkin-checkout-light.png' });
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: auto;
  margin: 0 auto;
  .label{
      font-family: 'Roboto';
      font-size: 12px; 
      color: ${props => props.checked? COLORS.lightBlue:COLORS.lightGray };
      top: 25%;
      left: 60%;
      line-height: 1;
      position: relative;
      clear: both;
  }  
`;

const FibyPayImg = styled.div`
  height: 50px;
  cursor: pointer;
  background-image: url(${props => props.checked? 'assets/images/service/fibypay.png':'assets/images/service/fibypay-light.png' });
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: auto;
  margin: 0 auto;
`;

const ShopPayImg = styled.div`
  height: 50px;
  cursor: pointer;
  background-image: url(${props => props.checked?'assets/images/service/shop-pay.png':'assets/images/service/shop-pay-light.png' });
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: auto;
  margin: 0 auto;
  .label{
      font-family: 'Roboto';
      font-size: 12px;     
      color: ${props => props.checked? COLORS.lightBlue:COLORS.lightGray };
      top: 50%;
      left: 60%;
      position: relative;
  }    
`;

export default PaymentMethod;
