import axios from 'axios';
import { BASE_URL } from '../constants';

const API = axios.create({
  timeout: 3000000000000000000,
  baseURL: BASE_URL,
});

API.interceptors.request.use(
  function(config) {
    if (!config.cancelToken && config.cancelToken !== undefined) {
    } else {
      let token = localStorage.getItem('token_get_get_it');
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  },
);

// Add a response interceptor
API.interceptors.response.use(
  function(response) {
    const data = {
      success: true,
      data: response.data,
      message: 'Petición exitosa!',
      code: response.status,
    };
    return data;
  },
  function(error) {
    const data = {
      success: false,
      data: error.response ? error.response.data : null,
      message: 'Petición fallida!',
      code: error.response ? error.response.status : -1,
    };
    var status = error.response ? error.response.status : -1;
    switch (status) {
      case 500:
        data.success = false;
        data.message = 'Disculpe, ha ocurrido un error interno.';
        /* ToastStore.error('Sorry, an internal error has ocurred', 8000, "bold-text white-text") */
        break;
      case -1:
        data.success = false;
        data.message = 'Por favor revise su conexión a internet.';
        /* ToastStore.warning('Check your network connection', 8000, "bold-text") */
        break;
      case 400:
        data.success = false;
        data.message = 'Disculpe, ha enviado una petición inválida.';
        break;
      case 401:
        data.success = false;
        data.message =
          'Disculpe, no está autorizado para realizar esta acción.';
        // window.location.href = '/login'
        break;
      default:
        break;
    }
    return data;
  },
);

export { API };
