import * as types from './types';

const startPlanUser = load => {
  return {
    type: types.START_GET_PLAN_USER,
    payload: load,
  };
};

const detailPlanUser = (planUser, load) => {
  return {
    type: types.DETAIL_GET_PLAN_USER,
    payload: {
      planUser,
      load,
    },
  };
};

const errorPlanUser = error => {
  return {
    type: types.ERROR_GET_PLAN_USER,
    payload: error,
  };
};

const startListPlan = load => {
  return {
    type: types.START_GET_PLAN_lIST,
    payload: load,
  };
};

const listPlan = (list, load) => {
  return {
    type: types.LIST_GET_PLAN,
    payload: {
      list,
      load,
    },
  };
};

const errorPlanList = error => {
  return {
    type: types.ERROR_GET_PLAN_LIST,
    payload: error,
  };
};

export {
  startPlanUser,
  detailPlanUser,
  errorPlanUser,
  startListPlan,
  listPlan,
  errorPlanList,
};
