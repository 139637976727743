import React from 'react';
import styled from 'styled-components';
import {
  MdClear,
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
} from 'react-icons/md';

const Modal = props => (
  <div>
    <Wrapper
      id="myModal"
      style={{ ...props.style, display: props.show ? 'flex' : 'none' }}      
    >
      <ContainerArrowLeft>
        {props.boolPrevArrow ? (
          <MdKeyboardArrowLeft
            color="#FFF"
            size={40}
            onClick={props.arrowPrev}
          />
        ) : null}
      </ContainerArrowLeft>
      <ContentModal fullWidth={props.fullWidth} style={props.contentStyle} className={props.className}>
        <BoxChildren fullWidth={props.fullWidth}>{props.children}</BoxChildren>
      </ContentModal>
      <ContainerArrowRigth>
        {props.boolNextArrow ? (
          <MdKeyboardArrowRight
            color="#FFF"
            size={40}
            onClick={props.arrowNext}
          />
        ) : null}
      </ContainerArrowRigth>
      <Close 
      alwaysShowClose={props.alwaysShowClose}
      onClick={() => props.onHide()}
      >
        <MdClear color="#FFF" size={30} />
      </Close>
    </Wrapper>
  </div>
);
const Wrapper = styled.div`
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContainerArrowLeft = styled.div`
  position: relative;
  margin-right: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  @media (max-width: 640px) {
    display: none;
  }
`;
const ContainerArrowRigth = styled.div`
  position: relative;
  margin-left: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  @media (max-width: 640px) {
    display: none;
  }
`;
const BoxChildren = styled.div`
  width: calc(100% - 20px);
  /* padding-left: ${props => props.paddingLeftChildren || '10px'};
  padding-right: ${props => props.paddingRightChildren || '10px'};
  padding-top: ${props => props.paddingTopChildren || '30px'} */
  padding-bottom: 10px;
  @media(max-width: 620px){
    width: ${props=>props.fullWidth? '90vw': '95vw'};
    position: relative;
  }
`;
const ContentModal = styled.div`
  width: 405px;
  background-color: #fefefe;
  border: 1px solid #888;
  width: auto;
  min-width: 20%;
  animation: MoveUpDown 0.5s linear;
  z-index: 100000;
  max-height: calc(100vh - 40px);
  /* border-radius: 10px; */
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #9e9e9e;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #525252;
    padding-top: 10px;
  }

  overflow-y: auto;
  overflow-x: hidden;
  @keyframes MoveUpDown {
    0% {
      margin-top: 100px;
    }
    100% {
      top: 15%;
    }
  }
  /* @media (max-height: 700px) {
    height: 90vh;
  } */
`;
const Close = styled.div`
  /* background-color: red; */
  width: 20px;
  height: 20px;
  top: 10px;
  right: 20px;
  position: absolute;
  font-size: 20px;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  :hover {
    opacity: 0.5;
  }
  @media (max-width: 620px) {
    display: ${props=>props.alwaysShowClose? 'block' : 'none'};
  }
`;

export default Modal;