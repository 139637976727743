import React, { useState } from 'react';
import styled from 'styled-components';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import Tooltip from './Tooltip';

const InputPass = props => {
  const [showEye, setShowEye] = useState(false);
  return (
    <div style={{ position: 'relative' }}>
      {props.error ? (
        <Tooltip
          message={props.message}
          error={props.error}
          style={props.style}
        />
      ) : null}
      <InputStyle
        width={props.width}
        widthSm={props.widthSm}
        placeholder={props.placeholder}
        value={props.value}
        name={props.name}
        border={props.error ? 'solid 1px #f80838' : null}
        onChange={props.onChange}
        onClick={props.onClick}
        type={showEye === true ? 'text' : props.type}
        onKeyPress={props.onKeyPress}
        minlength={props.minlength}
        maxlength={props.maxlength}
        min={props.min}
        max={props.max}

      />
      {props.type === 'password' ? (
        <BoxIconVisibility>
          {showEye ? (
            <MdVisibilityOff
              color="#bdbdbd"
              size={20}
              onClick={() => setShowEye(!showEye)}
            />
          ) : (
            <MdVisibility
              color="#bdbdbd"
              size={20}
              onClick={() => setShowEye(!showEye)}
            />
          )}
        </BoxIconVisibility>
      ) : null}
    </div>
  );
};
const InputStyle = styled.input`
  width: ${props => props.width || '370px'};
  height: ${props => props.height || '40px'};
  padding-bottom: ${props => props.paddingBottom};
  padding-left: ${props => props.paddingLeft || '15px'};
  border-radius: ${props => props.borderRadius || '5px'};
  border: ${props => props.border || '1px solid  #b7b8b8'};
  border-bottom: ${props => props.borderBottom};
  border-top: ${props => props.borderTop};
  border-right: ${props => props.borderRight};
  border-left: ${props => props.borderLeft};
  outline: none;
  cursor: ${props => props.cursor};
  text-align: ${props => props.textAlign || 'left'};
  margin-top: ${props => props.marginTop};
  margin-right: ${props => props.marginRight};
  margin-left: ${props => props.marginLeft};
  margin-bottom: ${props => props.marginBottom};
  padding-bottom: ${props => props.paddingBottom};
  padding-left: ${props => props.paddingLeft};
  font-size: ${props => props.fontSize};
  color: ${props => props.fontColor};
  background-color: ${props => props.backgroundColor};
  @media (max-width: ${props => props.sizeSm || '480px'}) {
    width: ${props => props.widthSm || '280px'};
  }
  :focus {
    border: ${props => props.borderFocus || '1px solid  #66bbff'};
    border-bottom: ${props => props.borderFocusBottom};
    border-top: ${props => props.borderFocusTop};
    border-right: ${props => props.borderFocusRight};
    border-left: ${props => props.borderFocusLeft};
    outline: none;
  }
  ::placeholder {
    font-family: 'Roboto-LightItalic';
    font-size: ${props => props.fontSize};
    color: ${props => props.placeHolderColor || '#808080' };
  }
`;
const BoxIconVisibility = styled.div`
  position: absolute;
  margin-top: -32px;
  /* margin-left: 360px; */
  right: 5px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;
export default InputPass;
