export const en = {
  lang: 1,
  downloadSummaryPdf: 'Download PDF summary',
  doClickInCreateService: 'Click on "Create service"',
  toEnhanceBooking: 'to enhance booking opportunities',
  createService: 'Create service',
  services: 'Services',
  AchieveWhatYouImagineToReach: 'Achieve what you imagine to achieve.',
  OfferYourServices: 'Offer your services!',
  save: 'Save',
  theFieldIsRequired: 'The field is required',
  passwordChanged: 'Password changed',
  thePasswordMustBeAtLeast: 'The password must have a minimum of 6 characters',
  passwordsDoNotMatch: 'Passwords do not match',
  enterNewPassword: 'Enter new password',
  confirmNewPassword: 'Confirm new password',
  enterValidEmailAddress: 'Enter a valid email address',
  exclusiveIncomeForSuppliers: 'Exclusive revenue for suppliers',
  email: 'Email',
  loginWith: 'Login with',
  change: 'Change',
  google: 'Google',
  password: 'Password',
  enter: 'Enter',
  forgotMyPassword: 'I forgot my password',
  emailIsNotRegistered: 'Email is not registered',
  registerMe: 'Register',
  logInWhith: 'Login with',
  youStillDontHaveAnAccount: "You still don't have an account?",
  signUp: 'Sign up',
  follow: 'Segir',
  next: 'Next',
  weHaveSentYouAnEmail:
    'We have sent you an email to reset your password. If you have not received it, click',
  emailDoesNotExist: 'Email does not exist',
  here: 'here.',
  restorePassword: 'Reset password',
  send: 'Send',
  mustBeAtLeast2Characters: 'Must have a minimum of 2 characters',
  mustBeAtLeast3Characters: 'Must have a minimum of 3 characters',
  mustHaveaMaximumOf15Characters: 'Must have a maximum of 15 characters',
  mustHaveaMaximumOf30Characters: 'Must have a maximum of 30 characters',
  blankSpacesAreNotAllowed: 'Blank spaces are not allowed',
  creationDate: 'Creation date',
  yearsOfCreation: 'Years of creation',
  theEmailIsDuplicated: 'Email is duplicated',
  theUserIsDuplicated: 'The user is duplicated',
  youMustHaveAtLeastTwoLetters: 'You must have at least two letters',
  thePasswordMustBeAtLeast8: 'The password must have at least 8 characters',
  thePasswordMustHaveAMaximumOf:
    'The password must have a maximum of 20 characters',
  thePasswordMustBeBetween8And20:
    'The password must have between 8 and 20 characters, at least one digit, at least one lowercase letter and at least one uppercase letter',
  theAddressCannotHaveMoreThan20Characters:
    'The address cannot have more than 20 characters',
  theFieldMustHaveAMinimumOf2Characters:
    'The field must have a minimum of 2 characters',
  theFieldMustHaveAMinimumOf4Characters:
    'The field must have a minimum of 4 characters',
  theFieldMustHaveAMaximumOf6Characters:
    'The field must have a maximum of 6 characters',
  theFieldMustHaveAMaximumOf10Characters:
    'The field must have a maximum of 10 characters',
  theFieldMustHaveAMaximumOf30Characters:
    'The field must have a maximum of 30 characters',
  onlyNumbersAreAllowed: 'Only numbers are allowed',
  theCreationDateMustBeLessThanTheCurrentOne:
    'The creation date must be less than the current one',
  iDIsIncorrect: 'The ID is incorrect',
  thePhoneFieldMustHaveAMinimumOf11Characters:
    'The Phone field must have a minimum of 9 characters',
  enterAValidPhoneNumber: 'Enter a valid Phone Number *',
  mustContainNameAndSurname: 'Must contain (First and Last Name)',
  theFieldMustStartAtLeastInOneLetterAndHaveANumber:
    'The field must start at least in one letter and have a number',
  youMustBeOfLegalAge: 'Must be of legal age',
  youMustSelectTheGender: 'You must select the genre',
  user: 'User',
  country: 'Country',
  city: 'City',
  address: 'Address',
  zipCode: 'Zip code',
  phone: 'Phone',
  nameContact: 'Contact person name',
  organizationIdExample: "Organization ID 'Example: Jxxxxxxxx'",
  numberOfEmployees: 'Number of Employees',
  byRegisteringYouWillBeAcceptingOur:
    'By registering you will be accepting our',
  conditions: 'Conditions',
  and: 'y',
  privacyPolicies: 'Privacy Policy',
  professional: 'Professional',
  freelancer: 'Freelancer',
  entrepreneur: 'Entrepreneur',
  job: 'Craft',
  particular: 'Private',
  organization: 'Organization',
  name: 'Name',
  lastName: 'Surname',
  dateOfBirth: 'Date of birth',
  female: 'Female',
  male: 'Male',
  other: 'Other',
  coachUser: 'User coach (Optional)',
  organizationName: 'Name of the organization',
  logOut: 'Log out',
  theProfileImageWasSuccessfullyChanged:
    'The profile image was successfully changed',
  theBackgroundImageWasSuccessfullyChanged:
    'The background image was successfully changed',
  editPhoto: 'Edit Photo',
  joinedIn: 'Joined in',
  editCoverPhoto: 'Edit cover photo',
  currentPlan: 'Current Plan',
  totalSeats: 'Total seats',
  available: 'Available',
  totalPasses: 'Total passes',
  totalServices: 'Total services',
  totalGift: 'Total gifts',
  changePlan: 'Change plan',
  yourTermsAndConditionsWereSuccessfullyChanged:
    'Your terms and conditions were successfully changed',
  theDescriptionWasEditedSuccessfully:
    'The description was edited successfully',
  theNameOfTheServiceWasSuccessfullyEdited:
    'The service name was edited successfully',
  theImageWasSuccessfullyEdited: 'The image was edited successfully',
  yourPaymentMethodWasSuccessfullyEdited:
    'Your payment method was successfully edited',
  itsMandatoryToHaveAPaymentMethod: 'It is mandatory to have a payment method',
  theCategoryWasSuccessfullyChanged: 'The category was successfully changed',
  theAddressWasSuccessfullyChanged: 'The address was changed successfully',
  ok: 'OK',
  location: 'Location',
  price: 'Price',
  paymentMethods: 'Payment methods',
  termsAndConditions: 'Terms and Conditions',
  simultaneousService: 'Simultaneous service',
  simultaneousServices: 'Simultaneous service',
  placesPerMonth: 'Squares per month',
  passesPerMonth: 'Passes per month',
  giftPerMonth: 'Gifts per month',
  dailyPostProgramming: 'Post daily programming',
  limitedStatistics: 'Limited statistics',
  detailedStatistics: 'Detailed statistics',
  timeToReserve: 'Time to book',
  monthly: 'Monthly',
  annual: 'Annual',
  homeDisplay: 'Visualization in the home',
  followers: 'Followers',
  seatsPassesAndAdditionalGifts: 'Additional seats, passes and gifts',
  notApply: 'Does not apply',
  apply: 'Apply',
  quarterlyCost: 'Quarterly cost',
  semesterCost: 'Semester cost',
  annualCost: 'Annual cost',
  month: 'month',
  followersAndCityOfRegistration: 'Followers and city of registration',
  acquirePlan: 'Acquire Plan',
  tryForFree: 'Try for free',
  howDoYouWantToMakeYourPayment: 'How do you want to make your payment?',
  onlinePayment: 'Online payment',
  bankPayment: 'Bank payment',
  attachImage: 'Attach image',
  iAgree: 'Accept',
  myPlan: 'My Plan',
  myServices: 'My Services',
  categories: 'Categories',
  subCategories: 'Subcategories',
  selectAll: 'Select All',
  paymentRemarks: 'Payment Remarks',
  passwordErrorMessage: 'The password is invalid',
  networkErrorMessage: 'Please check your internet connection',
  private: 'Private',
  ong: 'N.G.O',
  public: 'Public',
  serviceError: 'You does not have available services',
};
