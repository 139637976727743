import { API } from '../../api';

const getUserPlan = (data, onSuccess) => () => {
  API.get(`/user-plan/uid/${data.uid}`, {
    headers: {
      token: localStorage.getItem('token_get_get_it'),
      uidLoggedIn: data.uid,
      idChannel: 'web',
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

const getDetailPlan = (data, onSuccess) => () => {
  API.get(`/plans/${data.id}`, {
    headers: {
      token: localStorage.getItem('token_get_get_it'),
      uidLoggedIn: data.uid,
      idChannel: 'web',
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

const getListPlan = (uid, onSuccess) => () => {
  API.get('/plans', {
    headers: {
      token: localStorage.getItem('token_get_get_it'),
      uid: uid,
      idChannel: 'web',
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

export { getUserPlan, getDetailPlan, getListPlan };
