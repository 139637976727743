
import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
import Text from '../../../../components/generics/Text';
import * as COLORS from '../../../../util/colors';
const _ = require('lodash');

const init = (options,selected) => {
  let opts = [];
  const index = _.findIndex(options, (item)=> { return selected === item; });
  opts.push('');
  opts.push('');
  opts.push('');
  if (index>=0 ) {
    opts[1] = options[index];
  }
  return opts;
};

const DurationTrigger = (props) => {
  const [options,setOptions] = useState(init(props.options,props.selected));
  const [selected,setSelected] = useState(props.selected);

  useEffect (()=>{
    props.onChange && props.onChange(selected);
  },[options,selected]);

  const handleUpperClick = () => {
    const index = _.findIndex(props.options, (item)=> { return props.selected === item; });
    let opts = [];
    if (index>=0 && index<(props.options.length-1)) {
      opts.push(props.options[index]);
      opts.push(props.options[index+1]);
      opts.push(props.options[index+2]);
      setSelected(opts[1]);
      setOptions(opts);
    }
  };

  const handleLowerClick = () => {
    const index = _.findIndex(props.options, (item)=> { return selected === item; });
    let opts = [];
    if (index>0 ) {
      opts.push(props.options[index]);
      opts.push(props.options[index-1]);
      opts.push(props.options[index-2]);
      setSelected(opts[1]);
      setOptions(opts);      
    }

  };


  return (<Container width={props.width}>
    <Button upper onClick={handleUpperClick}/>
    <TimerWrapper>
      {options.map((item,index)=>
        <Text key={index}
          fontFamily='Roboto'
          fontSize='16px'
          textAlign='center'
          color={COLORS.darkGray}
        >{item}</Text>
      )}
    </TimerWrapper>
    <Button  onClick={handleLowerClick}/>
  </Container>);
};

const Container = styled.div`
  width:${props => props.width};
  overflow: hidden;
  flex-direction: column;
  display: flex;
`;

const Button = styled.div`
  height: 25px;
  display: block;
  width: 100%;
  margin: 0 auto;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  :hover {
    cursor: pointer;
  };
  &:before {
      content: '${props => props.upper ? '︿':'﹀' }';
      width: 25px;
      height: 25px;
      position: absolute;
      border-radius: 8px;     
      color: #333480;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      transform: translate(-50%, 0);
      box-shadow: 2px 3px 2px #fff;
  }
`;

const TimerBlock = styled.div`
  width: 100%;
  height: 30px;
  text-align: center;
  margin: 0 auto;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const TimerWrapper = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 1px solid ${COLORS.lightGray};
  border-bottom: 1px solid ${COLORS.lightGray};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  margin: 0 auto;
  p:nth-child(1) {
    display: none;
    opacity: 0.5;
  };
  p:nth-child(2) {
    display: block;
    opacity: 1;
  };
  p:nth-child(3) {
    display: none;
    opacity: 0.5;
  };
`;


export default DurationTrigger;