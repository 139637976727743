import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import thunk from 'redux-thunk';
import accessStore from './access';
import changeForm from './changeForm';
import generalStore from './general';
import servicesStore from './services';
import planStore from './plan';

const reducers = combineReducers({
  accessStore,
  changeForm,
  generalStore,
  servicesStore,
  planStore,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    localStorage.clear();
    state = undefined;
  }
  return reducers(state, action);
};

const persistConfig = {
  key: 'get_get_it_web',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const store = createStore(
    persistedReducer,
    undefined,
    compose(applyMiddleware(thunk)),
  );
  const persistor = persistStore(store);
  return { store, persistor };
};
