import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import moment from 'moment';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { CircularProgress, InputBase } from '@material-ui/core';
import { MdEdit, MdCheckCircle, MdDone, MdClear } from 'react-icons/md';
import IconButton from '@material-ui/core/IconButton';
import { Snackbar, SnackbarContent } from '@material-ui/core/';
import Text from '../generics/Text';
import Modal from '../generics/Modal';
import ColumnWrap from '../generics/ColumnWrap';
import Divider from '../generics/Divider';
import ButtonLoader from './../generics/Button';
import ItemsCategory from './ItemsCategory';
import ButtonCategory from './ButtonCategoty';
import ItemsPayments from './ItemsPayments';
import ItemsCripto from './ItemsCripto';
import {
  getDetailServices,
  getStartDetailServices,
  detailServices,
  errorDetailServices,
  patchServicePrice,
  patchTermsAndConditions,
  putDetailService,
  getServicesUser,
  listServicesUser,
  errorListServicesUser,
  putMethodPayment,
  getSubCategoryUser,
  patchServiceLocation,
} from '../../ducks/services';
import { applicationForm } from '../../ducks/changeForm';
import Tooltip from '../generics/Tooltip';
import { getListPayment, getMethodPayment } from '../../ducks/general';
import GoogleMaps from '../home/GoogleMaps';
import lang from '../../lang';

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

class CardServices extends Component {
  constructor(props) {
    super(props);
    props.detailServices({}, false);
    props.applicationForm({});
    this.state = {
      show: false,
      showCategory: false,
      activeAll: false,
      editName: false,
      editPrice: false,
      editDescription: false,
      editTermsAndConditions: false,
      showMessage: false,
      message: '',
      errorMessage: '',
      coverPicture: '',
      edit: false,
      editPayment: false,
      address: '',
      newAddress: '',
      editChangeAddres: false,
      geolocalisation: '',
      allPayment: [],
      center: {
        lat: 59.95,
        lng: 30.33,
      },
      zoom: 11,
      editActive: false,
      nextArrow: false,
      prevArrow: false,
      index: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loadDetail !== this.props.loadDetail) {
      this.setState({
        // show: true,
        coverPicture:
          nextProps.detail.servicePicture && nextProps.detail.servicePicture[0]
            ? nextProps.detail.servicePicture[0].highQuality
            : '',
        editPayment: false,
        address: nextProps.detail && nextProps.detail.address,
        center: {
          lat:
            nextProps.detail && nextProps.detail.location
              ? nextProps.detail.location.latitude
              : 30.3,
          lng:
            nextProps.detail && nextProps.detail.location
              ? nextProps.detail.location.longitude
              : 30.3,
        },
        zoom: 15,
      });
      const obj = {
        nameService:
          nextProps.detail && nextProps.detail.nameService
            ? nextProps.detail.nameService
            : '',
        price:
          nextProps.detail && nextProps.detail.minPrice
            ? nextProps.detail.minPrice
            : '',
        editDescriptionText:
          nextProps.detail && nextProps.detail.description
            ? nextProps.detail.description
            : '',
        termsAndConditions:
          nextProps.detail && nextProps.detail.termsAndConditions
            ? nextProps.detail.termsAndConditions
            : '',
        dataPayment:
          nextProps.detail && nextProps.detail.servicePaymentMethod
            ? nextProps.detail.servicePaymentMethod
            : [],
        idCategory:
          nextProps.detail && nextProps.detail.servicePaymentMethod
            ? nextProps.detail.idCategory
            : null,
        idSubCategory:
          nextProps.detail && nextProps.detail.servicePaymentMethod
            ? nextProps.detail.idSubCategory
            : null,
        center: {
          lat:
            nextProps.detail && nextProps.detail.location
              ? nextProps.detail.location.latitude
              : 30.3,
          lng:
            nextProps.detail && nextProps.detail.location
              ? nextProps.detail.location.longitude
              : 30.3,
        },
        errorNameService: '',
        errorPrice: '',
        errorDescription: '',
        errorTermsAndConditions: '',
        errorDataPayment: '',
      };
      if (nextProps.detail && nextProps.detail.servicePaymentMethod) {
        const methodPayment = JSON.stringify(nextProps.payment);
        const parseMethodPayment = JSON.parse(methodPayment);
        parseMethodPayment.forEach(element => {
          nextProps.detail.servicePaymentMethod.forEach(item => {
            if (element.id === item.idPaymentMethod) {
              element[`select`] = true;
            }
          });
        });
        nextProps.getListPayment(parseMethodPayment);
      }
      if (nextProps.detail && nextProps.detail && nextProps.categories) {
        nextProps.categories.forEach(item => {
          if (item.parentId !== null) {
            if (item.id === nextProps.detail.idSubCategory) {
              const detailService = JSON.stringify(nextProps.detail);
              const parseDetailService = JSON.parse(detailService);
              parseDetailService.subColor = item.backgroundColor;
              this.props.detailServices(parseDetailService, false);
            }
          }
        });
      }
      nextProps.applicationForm(obj);
      // console.log('nextProps', nextProps);
    }
  }

  handleOnChangeService = (prop, errorProp) => event => {
    if (event.target.value !== ' ') {
      const onChangeForm = JSON.stringify(this.props.formChange);
      const parseChangeForm = JSON.parse(onChangeForm);
      parseChangeForm[prop] = event.target.value;
      parseChangeForm[errorProp] = '';
      const obj = {
        ...parseChangeForm,
      };
      this.props.applicationForm(obj);
    }
  };

  hideModal = () => {
    this.setState({
      show: false,
      showCategory: false,
      activeAll: false,
      editName: false,
      editPrice: false,
      editDescription: false,
      editTermsAndConditions: false,
      showMessage: false,
      message: '',
      errorMessage: '',
      coverPicture: '',
      edit: false,
      editPayment: false,
      address: '',
      newAddress: '',
      editChangeAddres: false,
      geolocalisation: '',
      index: '',
    });
    this.props.detailServices({}, false);
    this.handleGetPayment();
  };

  handleGetPayment = () => {
    const data = {
      uid: this.props.detail.uid,
    };
    this.props.getMethodPayment(data, Response => {
      if (Response.code >= 200 && Response.code <= 299) {
        if (
          Response.data.responseInfo.length > 0 &&
          Array.isArray(Response.data.responseInfo)
        ) {
          this.props.getListPayment(Response.data.responseInfo);
        } else {
          this.props.getListPayment([]);
        }
      } else {
        this.props.getListPayment([]);
      }
    });
  };

  handleClickSelect = (value, item) => {
    let add = true;
    this.setState({
      editActive: true,
    });
    if (this.props.detail.servicePaymentMethod !== null) {
      this.props.formChange.dataPayment.forEach(element => {
        if (element.idPaymentMethod === item.id) {
          add = false;
        }
      });
      if (add) {
        const detailService = JSON.stringify(this.props.formChange);
        const parseDetailService = JSON.parse(detailService);
        let array = parseDetailService.dataPayment;
        array.push({
          idPaymentMethod: item.id,
          enabled: true,
        });
        parseDetailService.dataPayment = array;
        this.props.applicationForm(parseDetailService);
        this.handleOnChangeServicePayment(
          'dataPayment',
          'errorDataPayment',
          parseDetailService.dataPayment,
        );
      } else {
        const methodPayment = JSON.stringify(this.props.payment);
        const parseMethodPayment = JSON.parse(methodPayment);
        parseMethodPayment.forEach(element => {
          if (element.id === item.id) {
            element[`select`] = false;
          }
        });
        this.props.getListPayment(parseMethodPayment);

        if (this.props.detail.servicePaymentMethod.length === 1) {
          const valor = JSON.stringify(this.props.formChange);
          const parseValor = JSON.parse(valor);
          parseValor.dataPayment = [];
          this.props.applicationForm(parseValor);
          this.handleOnChangeServicePayment(
            'dataPayment',
            'errorDataPayment',
            parseValor.dataPayment,
          );
        } else {
          const valor = JSON.stringify(this.props.formChange);
          const parseValor = JSON.parse(valor);
          let indexValue = '';
          parseValor.dataPayment.forEach((element, index) => {
            if (element.idPaymentMethod === item.id) {
              indexValue = index;
            }
          });
          const array = parseValor.dataPayment;
          array.splice(indexValue, 1);
          this.props.applicationForm(parseValor);
          this.handleOnChangeServicePayment(
            'dataPayment',
            'errorDataPayment',
            parseValor.dataPayment,
          );
        }
      }
    } else {
      const detailService = JSON.stringify(this.props.formChange);
      const parseDetailService = JSON.parse(detailService);
      parseDetailService.dataPayment = [];
      parseDetailService.dataPayment.push({
        idPaymentMethod: item.id,
        enabled: true,
      });
      this.props.applicationForm(parseDetailService);
      this.handleOnChangeServicePayment(
        'dataPayment',
        'errorDataPayment',
        parseDetailService.dataPayment,
      );
    }

    this.setState({
      active: value,
    });
  };

  handleOnChangeServicePayment = (prop, errorProp, event) => {
    const onChangeForm = JSON.stringify(this.props.formChange);
    const parseChangeForm = JSON.parse(onChangeForm);
    parseChangeForm[prop] = event;
    parseChangeForm[errorProp] = '';
    const obj = {
      ...parseChangeForm,
    };
    this.props.applicationForm(obj);
  };

  handleClickDetail = (item, bool, index, totalItem) => () => {
    const diseableNextArrow = totalItem > index ? true : false;
    const diseablePrevArrow = index > 1 ? true : false;
    this.setState({
      loadDetail: true,
      show: true,
      prevArrow: diseablePrevArrow,
      nextArrow: diseableNextArrow,
      index,
    });
    this.props.getStartDetailServices(bool);
    this.props.getDetailServices(item.id, Response => {
      if (Response.code >= 200 && Response.code <= 299) {
        this.setState({
          loadDetail: false,
          errorMessage: '',
        });
        this.props.detailServices(Response.data.responseInfo, false);
      } else {
        this.setState({
          show: false,
          showMessage: true,
          errorMessage: Response.message,
          loadDetail: false,
        });
        this.props.errorDetailServices(Response.message);
      }
    });
  };

  handleClickEditName = () => {
    this.setState({
      editName: false,
    });
  };

  handleClickPatchPrice = () => {
    this.setState({
      editPrice: false,
    });
  };

  handleClickPatchTermsAndConditions = () => {
    const detailService = JSON.stringify(this.props.detail);
    const parseDetailService = JSON.parse(detailService);
    this.setState({
      editTermsAndConditions: false,
    });
    if (
      this.props.formChange.termsAndConditions ===
      this.props.detail.termsAndConditions
    ) {
      this.setState({
        editTermsAndConditions: false,
      });
    } else {
      let data = {
        termsAndConditions: this.props.formChange.termsAndConditions,
        uid: this.props.detail.uid,
        id: this.props.detail.id,
      };
      this.props.patchTermsAndConditions(data, Response => {
        if (Response.code >= 200 && Response.code <= 299) {
          this.setState({
            showMessage: true,
            message: lang.yourTermsAndConditionsWereSuccessfullyChanged,
            errorMessage: '',
            editTermsAndConditions: false,
          });
          parseDetailService.termsAndConditions = this.props.formChange.termsAndConditions;
          this.props.detailServices(parseDetailService, false);
          this.handleGetServicesUser();
        } else {
          this.setState({
            showMessage: true,
            errorMessage: Response.message,
            message: '',
            editTermsAndConditions: true,
          });
        }
      });
    }
  };

  handleClose = () => {
    this.setState({
      showMessage: false,
    });
  };

  handleClickEditDescription = () => {
    const formService = JSON.stringify(this.props.formChange);
    const parseFormService = JSON.parse(formService);
    const validate = this.validateDescriptionService(parseFormService);
    if (validate) {
      this.setState({
        editDescription: false,
      });
      if (
        this.props.detail.description ===
        this.props.formChange.editDescriptionText
      ) {
        this.setState({
          editDescription: false,
        });
      } else {
        const detailService = JSON.stringify(this.props.detail);
        const parseDetailService = JSON.parse(detailService);
        const data = {
          description: this.props.formChange.editDescriptionText,
          idCategory: this.props.detail.idCategory,
          idService: this.props.detail.id,
          idSubCategory: this.props.detail.idSubCategory,
          nameService: this.props.detail.nameService,
          servicePicture: [
            {
              highQuality: this.props.detail.servicePicture[0].highQuality,
              lowQuality: this.props.detail.servicePicture[0].lowQuality,
              miniature: this.props.detail.servicePicture[0].miniature,
            },
          ],
          uid: this.props.detail.uid,
        };
        parseDetailService.description = this.props.formChange.editDescriptionText;
        this.props.detailServices(parseDetailService, false);
        this.props.putDetailService(data, Response => {
          if (Response.code >= 200 && Response.code <= 299) {
            this.handleGetServicesUser();
            this.props.getDetailServices(this.props.detail.id, result => {
              if (result.code >= 200 && result.code <= 299) {
                this.setState({
                  showMessage: true,
                  message: lang.theDescriptionWasEditedSuccessfully,
                  errorMessage: '',
                  editDescription: false,
                });
                this.props.detailServices(result.data.responseInfo, false);
              } else {
                this.setState({
                  showMessage: true,
                  errorMessage: result.message,
                  message: '',
                  editDescription: true,
                });
              }
            });
          } else {
            this.setState({
              showMessage: true,
              errorMessage: Response.message,
              message: '',
              editDescription: true,
            });
          }
        });
      }
    }
  };

  validateDescriptionService = data => {
    if (!data) {
      return false;
    }
    if (!data.editDescriptionText) {
      data.errorDescription = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (data.editDescriptionText.length <= 10) {
      data.errorDescription = lang.theFieldMustHaveAMaximumOf10Characters;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    return true;
  };

  handleClickNameService = () => {
    const formService = JSON.stringify(this.props.formChange);
    const parseFormService = JSON.parse(formService);
    const validate = this.validateNameService(parseFormService);
    if (validate) {
      this.setState({
        editName: false,
      });
      if (this.props.detail.nameService === this.props.formChange.nameService) {
        this.setState({
          editName: false,
        });
      } else {
        const detailService = JSON.stringify(this.props.detail);
        const parseDetailService = JSON.parse(detailService);
        const data = {
          description: this.props.detail.description,
          idCategory: this.props.detail.idCategory,
          idService: this.props.detail.id,
          idSubCategory: this.props.detail.idSubCategory,
          nameService: this.props.formChange.nameService,
          servicePicture: [
            {
              highQuality: this.props.detail.servicePicture[0].highQuality,
              lowQuality: this.props.detail.servicePicture[0].lowQuality,
              miniature: this.props.detail.servicePicture[0].miniature,
            },
          ],
          uid: this.props.detail.uid,
        };
        parseDetailService.nameService = this.props.formChange.nameService;
        this.props.detailServices(parseDetailService, false);
        this.props.putDetailService(data, Response => {
          if (Response.code >= 200 && Response.code <= 299) {
            this.setState({
              showMessage: true,
              message: lang.theNameOfTheServiceWasSuccessfullyEdited,
              errorMessage: '',
              editName: false,
            });
            this.handleGetServicesUser();
          } else {
            this.setState({
              showMessage: true,
              errorMessage: Response.message,
              message: '',
              editName: true,
            });
          }
        });
      }
    }
  };

  validateNameService = data => {
    if (!data) {
      return false;
    }
    if (!data.nameService) {
      data.errorNameService = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (data.nameService.replace(/ /g, '').length <= 1) {
      data.errorNameService = lang.theFieldMustHaveAMinimumOf2Characters;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (data.nameService.replace(/ /g, '').length > 30) {
      data.errorNameService = lang.theFieldMustHaveAMaximumOf30Characters;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    const noValido = /[a-zA-záéíóúabcdefghijklmnñopqrstuvwxyz]{2}/;
    const replaceCaracter = data.nameService
      .replace(/[^a-z0-9\s]/gi, '')
      .replace(/[_\s0-9]/g, '');
    if (!noValido.test(replaceCaracter)) {
      data.errorNameService = lang.youMustHaveAtLeastTwoLetters;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    return true;
  };

  readURL3 = input => {
    const detailService = JSON.stringify(this.props.detail);
    const parseDetailService = JSON.parse(detailService);
    const file = document.getElementById('file_3').files[0];
    const reader = new FileReader();
    reader.onload = e => {
      this.setState({
        coverPicture: e.target.result,
      });
    };
    reader.readAsDataURL(input.files[0]);
    const storageRef = firebase.storage().ref(`/images/pass/${file.name}`);
    storageRef.put(file).then(snapshot => {
      firebase
        .storage()
        .ref(`/${snapshot.metadata.fullPath}`)
        .getDownloadURL()
        .then(Response => {
          const urlImg = Response;
          let data = {
            uid: this.props.detail.uid,
            description: this.props.detail.description,
            idCategory: this.props.detail.idCategory,
            idService: this.props.detail.id,
            idSubCategory: this.props.detail.idSubCategory,
            nameService: this.props.detail.nameService,
            servicePicture: [
              {
                highQuality: urlImg,
                lowQuality: urlImg,
                miniature: urlImg,
              },
            ],
          };
          this.props.putDetailService(data, result => {
            if (result.code >= 200 && result.code <= 299) {
              this.setState({
                showMessage: true,
                message: lang.theImageWasSuccessfullyEdited,
                errorMessage: '',
              });
              this.handleGetServicesUser();
              this.props.detailServices(parseDetailService, false);
            } else {
              this.setState({
                showMessage: true,
                errorMessage: result.message,
                message: '',
              });
            }
          });
        });
    });
  };

  handleGetServicesUser = () => {
    this.props.getServicesUser(this.props.detail.uid, Response => {
      if (Response.code >= 200 && Response.code <= 299) {
        if (
          Array.isArray(Response.data.responseInfo) &&
          Response.data.responseInfo.length > 0
        ) {
          this.props.listServicesUser(Response.data.responseInfo, false);
        } else {
          this.props.listServicesUser([], false);
        }
      } else {
        this.props.errorListServicesUser(Response.message);
      }
    });
  };

  handleEditPayment = () => {
    this.setState({
      editPayment: true,
    });
  };

  handleClickEditPayment = () => {
    const formService = JSON.stringify(this.props.formChange);
    const parseFormService = JSON.parse(formService);
    const validate = this.validatePayment(parseFormService);
    if (validate) {
      if (this.state.editActive) {
        this.setState({
          editPayment: false,
        });
        const data = {
          ftsPayments: true,
          servicePaymentMethod: this.props.formChange.dataPayment,
        };
        const methodPayment = JSON.stringify(this.props.payment);
        const parseMethodPayment = JSON.parse(methodPayment);
        parseMethodPayment.forEach(element => {
          this.props.formChange.dataPayment.forEach(item => {
            if (element.id === item.idPaymentMethod) {
              element[`select`] = true;
            }
          });
        });
        this.props.getListPayment(parseMethodPayment);
        this.props.putMethodPayment(
          this.props.detail.id,
          this.props.detail.uid,
          data,
          Response => {
            if (Response.code >= 200 && Response.code <= 299) {
              this.setState({
                showMessage: true,
                message: lang.yourPaymentMethodWasSuccessfullyEdited,
                errorMessage: '',
                editPayment: false,
                editActive: false,
              });
            } else {
              this.setState({
                showMessage: true,
                errorMessage: Response.message,
                message: '',
                editPayment: true,
              });
            }
          },
        );
      } else {
        this.setState({
          editPayment: false,
        });
      }
    }
  };

  handleClickSelectAll = value => {
    this.setState({
      activeAll: value,
    });
    if (value === true) {
      let data = [];
      this.props.payment.forEach(item => {
        data.push({
          idPaymentMethod: item.id,
          enabled: true,
        });
      });
      this.setState({
        editActive: true,
      });
      const methodPaymentAllSelect = JSON.stringify(this.props.formChange);
      const parseMethodPaymentAllSelect = JSON.parse(methodPaymentAllSelect);
      parseMethodPaymentAllSelect.dataPayment = data;
      this.props.applicationForm(parseMethodPaymentAllSelect);
    } else {
      this.setState({
        editActive: false,
      });
      const methodPaymentAllSelect = JSON.stringify(this.props.detail);
      const parseResetPayment = JSON.parse(methodPaymentAllSelect);
      const methodPaymentAllSelectForm = JSON.stringify(this.props.formChange);
      const parseMethodPaymentAllSelectForm = JSON.parse(
        methodPaymentAllSelectForm,
      );
      parseMethodPaymentAllSelectForm.dataPayment =
        parseResetPayment.servicePaymentMethod;
      this.props.applicationForm(parseMethodPaymentAllSelectForm);
      this.props.detailServices(parseResetPayment, false);
    }
  };

  handleResetAll = id => {
    this.props.getDetailServices(id, Response => {
      if (Response.code >= 200 && Response.code <= 299) {
        this.props.detailServices(Response.data.responseInfo, false);
        const formService2 = JSON.stringify(this.props.formChange);
        const parseFormService3 = JSON.parse(formService2);
        parseFormService3.center = {
          lat: Response.data.responseInfo.location.latitude,
          lng: Response.data.responseInfo.location.longitude,
        };
        this.props.applicationForm(parseFormService3);
      } else {
        this.props.errorDetailServices(Response.message);
      }
    });
  };

  validatePayment = data => {
    if (!data) {
      return false;
    }
    if (!(data.dataPayment.length > 0)) {
      data.errorDataPayment = lang.itsMandatoryToHaveAPaymentMethod;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    return true;
  };

  handleChangeCategories = value => {
    const detailService = JSON.stringify(this.props.detail);
    const parseDetailService = JSON.parse(detailService);
    parseDetailService.idCategory = value.id;
    this.props.detailServices(parseDetailService, false);
  };

  handleClickSubCategory = value => {
    const detailService = JSON.stringify(this.props.detail);
    const parseDetailService = JSON.parse(detailService);
    parseDetailService.idSubCategory = value.id;
    parseDetailService.nameSubCategory = value.name;
    parseDetailService.subColor = value.backgroundColor;
    this.props.detailServices(parseDetailService, false);
  };

  handleClickChangeCategories = () => {
    if (
      this.props.formChange.idCategory === this.props.detail.idCategory &&
      this.props.formChange.idSubCategory === this.props.detail.idSubCategory
    ) {
      this.setState({
        showCategory: false,
      });
    } else {
      this.setState({
        showCategory: false,
      });
      const formService = JSON.stringify(this.props.formChange);
      const parseFormService = JSON.parse(formService);
      parseFormService.idCategory = this.props.detail.idCategory;
      this.props.applicationForm(parseFormService);
      const data = {
        description: this.props.detail.description,
        idCategory: this.props.detail.idCategory,
        idService: this.props.detail.id,
        idSubCategory: this.props.detail.idSubCategory,
        nameService: this.props.detail.nameService,
        servicePicture: [
          {
            highQuality: this.props.detail.servicePicture[0].highQuality,
            lowQuality: this.props.detail.servicePicture[0].lowQuality,
            miniature: this.props.detail.servicePicture[0].miniature,
          },
        ],
        uid: this.props.detail.uid,
      };
      this.props.putDetailService(data, Response => {
        if (Response.code >= 200 && Response.code <= 299) {
          this.setState({
            showMessage: true,
            message: lang.theCategoryWasSuccessfullyChanged,
            errorMessage: '',
            editPayment: false,
          });
          this.handleGetServicesUser();
          this.props.getDetailServices(this.props.detail.id, result => {
            if (result.code >= 200 && result.code <= 299) {
              this.props.detailServices(result.data.responseInfo, false);
            }
          });
        } else {
          this.setState({
            showMessage: true,
            errorMessage: Response.message,
            message: '',
            editPayment: true,
          });
        }
      });
    }
  };

  handleChange = data => {
    this.setState({
      newAddress: data,
    });
  };

  handleSelect = address => {
    this.setState({
      newAddress: address,
      address: address,
    });
    const formService = JSON.stringify(this.props.formChange);
    const parseFormService = JSON.parse(formService);
    parseFormService.center = false;
    this.props.applicationForm(parseFormService);
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState({
          geolocalisation: latLng,
        });
        const formService = JSON.stringify(this.props.formChange);
        const parseFormService = JSON.parse(formService);
        parseFormService.center = {
          lat: latLng.lat,
          lng: latLng.lng,
        };
        this.props.applicationForm(parseFormService);
      })
      .catch(error => console.error('Error', error));
  };

  handleClickNewAddress = () => {
    if (this.state.address === this.props.detail.address) {
      this.setState({
        editChangeAddres: false,
      });
    } else if (
      this.state.newAddress === '' &&
      this.props.detail.address !== ''
    ) {
      const formService = JSON.stringify(this.props.formChange);
      const parseFormService = JSON.parse(formService);
      parseFormService.center = false;
      this.props.applicationForm(parseFormService);
      this.setState({
        editChangeAddres: false,
        address: this.props.detail.address,
      });
      this.handleResetAll(this.props.detail.id);
    } else {
      this.setState({
        editChangeAddres: false,
        address: this.state.newAddress,
      });
      let data = {
        latitude: this.state.geolocalisation.lat,
        longitude: this.state.geolocalisation.lng,
        id: this.props.detail.id,
        uid: this.props.detail.uid,
      };
      const detailService = JSON.stringify(this.props.detail);
      const parseDetailService = JSON.parse(detailService);
      parseDetailService.address = this.state.newAddress;
      parseDetailService.location = {
        latitude: this.props.formChange.center.lat,
        longitude: this.props.formChange.center.lng,
      };
      this.props.detailServices(parseDetailService);
      this.props.patchServiceLocation(data, Response => {
        if (Response.code >= 200 && Response.code <= 299) {
          this.setState({
            newAddress: '',
            message: lang.theAddressWasSuccessfullyChanged,
            errorMessage: '',
            showMessage: true,
            editChangeAddres: false,
          });
        } else {
          this.setState({
            showMessage: true,
            errorMessage: Response.message,
            message: '',
            editChangeAddres: true,
          });
        }
      });
    }
  };

  onChangeletters = e => {
    let key = e.keyCode || e.which;
    return /^[a-zA-záéíóúabcdefghijklmnñopqrstuvwxyz ]+$/.test(
      String.fromCharCode(key),
    );
  };

  handleClickArrowNext = () => {
    const indexValue = this.state.index + 1;
    const item = this.props.serviceList[indexValue - 1];
    this.handleDetailArrow(item, true, indexValue, this.props.totalItem);
  };

  handleClickArrowPrev = () => {
    const indexValue = this.state.index - 1;
    const item = this.props.serviceList[indexValue - 1];
    this.handleDetailArrow(item, true, indexValue, this.props.totalItem);
  };

  handleDetailArrow = (item, bool, index, totalItem) => {
    const diseableNextArrow = totalItem > index ? true : false;
    const diseablePrevArrow = index > 1 ? true : false;
    this.setState({
      loadDetail: true,
      show: true,
      prevArrow: diseablePrevArrow,
      nextArrow: diseableNextArrow,
      index,
    });
    this.props.getStartDetailServices(bool);
    this.props.getDetailServices(item.id, Response => {
      if (Response.code >= 200 && Response.code <= 299) {
        this.setState({
          loadDetail: false,
          errorMessage: '',
        });
        this.props.detailServices(Response.data.responseInfo, false);
      } else {
        this.setState({
          show: false,
          showMessage: true,
          errorMessage: Response.message,
          loadDetail: false,
        });
        this.props.errorDetailServices(Response.message);
      }
    });
  };

  render() {
    return (
      <div style={{ cursor: 'pointer' }}>
        <Container
          onClick={this.handleClickDetail(
            this.props.item,
            true,
            this.props.index,
            this.props.totalItem,
          )}
        >
          <Image
            style={
              this.props.item && this.props.item.servicePicture
                ? {
                    backgroundImage: `url(${
                      this.props.item.servicePicture &&
                      this.props.item.servicePicture[0]
                        ? this.props.item.servicePicture[0].highQuality
                        : ''
                    })`,
                  }
                : null
            }
          >
            <WrapText>
              <Text color="#FFF" fontFamily="Roboto" fontWeight="600px">
                {this.props.item && this.props.item.nameService}
              </Text>
            </WrapText>
            <WrapTextBottom>
              <WrapIconText
                style={{
                  width: '170px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Icon src="/assets/images/icon-location.svg" alt="icon" />
                <Text
                  color="#FFF"
                  fontFamily="Roboto"
                  fontWeight="400px"
                  fontSize="14px"
                  textAlign="right"
                >
                  {this.props.item && this.props.item.address
                    ? this.props.item.address.substr(0, 18) + '...'
                    : null}
                </Text>
              </WrapIconText>
              <Text color="#FFF" fontFamily="Roboto-Bold">
                {lang.price} {this.props.item && this.props.item.minPrice}$
              </Text>
            </WrapTextBottom>
          </Image>
          <WrapDescription>
            <Description>
              {this.props.item && this.props.item.description}
            </Description>
          </WrapDescription>
        </Container>
        <Modal
          fullWidth
          show={this.state.show}
          onHide={this.hideModal}
          arrowNext={this.handleClickArrowNext}
          arrowPrev={this.handleClickArrowPrev}
          boolPrevArrow={this.state.prevArrow}
          boolNextArrow={this.state.nextArrow}
        >
          <CloseResponsive onClick={() => this.hideModal()}>
            <MdClear color="#FFF" size={25}/>
          </CloseResponsive>
          {this.state.loadDetail ? (
            <div className="total-center" style={{ margin: '15px' }}>
              <CircularProgress />
            </div>
          ) : (
            <ContainerModal>
              <img
                src={
                  this.props.detail && this.props.detail.servicePicture
                    ? this.state.coverPicture
                    : ''
                }
                style={{
                  height: 'auto',
                  objectFit: 'cover',
                }}
                alt="background"
              />
              <div
                style={{
                  position: 'relative',
                  height: '120px',
                  marginTop: '-120px',
                }}
              >
                <WrapTextBottom
                  height="110px"
                  alignItems="flex-star"
                  padding="0px 0px 5px 10px"
                >
                  {this.state.editName ? (
                    <div>
                      <Tooltip
                        error={
                          this.props.formChange &&
                          this.props.formChange.errorNameService
                        }
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                        }}
                        message={
                          this.props.formChange &&
                          this.props.formChange.errorNameService
                        }
                      />
                      <div style={{ display: 'flex' }}>
                        <InputBase
                          value={
                            this.props.formChange &&
                            this.props.formChange.nameService
                              ? this.props.formChange.nameService
                              : ''
                          }
                          style={{
                            color: '#fff',
                            width:
                              this.props.formChange.editDescriptionText.length >
                              10
                                ? '240px'
                                : '100px',
                            fontSize: '28px',
                            fontFamily: 'Roboto',
                          }}
                          onChange={this.handleOnChangeService(
                            'nameService',
                            'errorNameService',
                          )}
                          autoFocus={true}
                        />
                        <ContainerCheck>
                          <div style={{ marginTop: '10px' }}>
                            <MdDone
                              className="opacity"
                              color="#fff"
                              size="30px"
                              onClick={this.handleClickNameService}
                            />
                          </div>
                        </ContainerCheck>
                      </div>
                    </div>
                  ) : (
                    <Text
                      color="#FFF"
                      fontSize="28px"
                      fontFamily="Roboto"
                      marginBottom="5px"
                    >
                      {this.props.detail && this.props.detail.nameService}
                      <span>
                        <MdEdit
                          className="opacity"
                          color="#FFF"
                          size="25px"
                          onClick={() => this.setState({ editName: true })}
                        />
                      </span>
                    </Text>
                  )}
                  <div>
                    <WrapIconText justifyContent="flex-start">
                      <ButtonLoader
                        backgroundColor={
                          this.props.detail && this.props.detail.subColor
                        }
                        height="30px"
                        width="auto"
                        widthB="auto"
                      >
                        {this.props.detail && this.props.detail.nameSubCategory
                          ? this.props.detail.nameSubCategory[lang.lang]
                              .description
                          : ''}
                      </ButtonLoader>
                      <span>
                        <MdEdit
                          className="opacity"
                          color="#FFF"
                          size="25px"
                          onClick={() => {
                            this.setState({ showCategory: true });
                          }}
                        />
                      </span>
                    </WrapIconText>
                    {this.state.showCategory ? (
                      <ContainerCategoty>
                        <BarTitle>{lang.categories}</BarTitle>
                        <div>
                          <ColumnWrap>
                            {this.props.categories &&
                            this.props.categories.length > 0
                              ? this.props.categories.map((item, key) => {
                                  if (item.parentId === null) {
                                    return (
                                      <ItemsCategory
                                        label={item.name[lang.lang].description}
                                        item={item}
                                        key={key}
                                        id={this.props.detail.idCategory}
                                        onChange={value =>
                                          this.handleChangeCategories(value)
                                        }
                                      />
                                    );
                                  }
                                  return null;
                                })
                              : null}
                          </ColumnWrap>
                        </div>
                        <BarTitle>{lang.subCategories}</BarTitle>
                        <div className="scroll">
                          <ColumnWrap marginBottom="30px">
                            {this.props.categories &&
                            this.props.categories.length > 0
                              ? this.props.categories.map((item, key) => {
                                  if (item.parentId !== null) {
                                    if (
                                      item.parentId ===
                                      this.props.detail.idCategory
                                    ) {
                                      return (
                                        <ButtonCategory
                                          key={key}
                                          color="#9072ba"
                                          id={this.props.detail.idSubCategory}
                                          item={item}
                                          backgroundColor={item.backgroundColor}
                                          onChange={value =>
                                            this.handleClickSubCategory(value)
                                          }
                                        >
                                          {item.name[lang.lang].description}
                                        </ButtonCategory>
                                      );
                                    }
                                  }
                                  return null;
                                })
                              : null}
                          </ColumnWrap>
                        </div>
                        <div className="total-center opacity">
                          <Text
                            color="#333333"
                            fontFamily="Roboto"
                            fontWeight="300"
                            onClick={this.handleClickChangeCategories}
                            fontSize="18px"
                          >
                            {lang.ok}
                          </Text>
                        </div>
                      </ContainerCategoty>
                    ) : null}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                      marginTop: ' 7px',
                    }}
                  >
                    <WrapIconText>
                      {this.props.detail && this.props.detail.profilePicture ? (
                        <Avatar
                          src={`${this.props.detail.profilePicture.highQuality}`}
                          alt="avatar"
                        />
                      ) : null}
                      <Text color="#FFF" fontSize="16px" marginLeft="5px">
                        {this.props.detail && this.props.detail.userName}
                      </Text>
                    </WrapIconText>
                    <Text
                      color="#FFF"
                      fontSize="12px"
                      fontFamily="Roboto"
                      textAlign="right"
                      onClick={() => document.getElementById('file_3').click()}
                    >
                      {lang.editPhoto}{' '}
                      <MdEdit className="opacity" color="#FFF" size="25px" />
                    </Text>
                  </div>
                  <input
                    type="file"
                    id="file_3"
                    accept="image/png,image/jpeg"
                    style={{ display: 'none' }}
                    onChange={obj => this.readURL3(obj.target)}
                  />
                </WrapTextBottom>
              </div>
              <ContainerInfo>
                <DescriptionModal>
                  {this.state.editDescription ? (
                    <div>
                      <Tooltip
                        error={
                          this.props.formChange &&
                          this.props.formChange.errorDescription
                        }
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                        }}
                        message={
                          this.props.formChange &&
                          this.props.formChange.errorDescription
                        }
                      />
                      <div style={{ display: 'flex' }}>
                        <InputBase
                          value={
                            this.props.formChange &&
                            this.props.formChange.editDescriptionText
                              ? this.props.formChange.editDescriptionText
                              : ''
                          }
                          inputProps={{ 'aria-label': 'naked' }}
                          style={{
                            color: '#58595b',
                            width:
                              this.props.formChange.editDescriptionText.length >
                              10
                                ? '100%'
                                : '100px',
                            fontFamily: 'Roboto',
                            fontSize: '16px',
                          }}
                          onChange={this.handleOnChangeService(
                            'editDescriptionText',
                            'errorDescription',
                          )}
                          multiline
                          rowsMax="4"
                          autoFocus={true}
                        />
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-end',
                              justifyContent: 'flex-end',
                              marginBottom: '10px',
                            }}
                          >
                            <MdDone
                              className="opacity"
                              color="#808080"
                              size="30px"
                              onClick={this.handleClickEditDescription}
                            />
                          </div>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                      }}
                    >
                      {this.props.detail.description}{' '}
                      {this.state.editDescription ? null : (
                        <div>
                          {/* {
                            this.props.detail && this.props.detail.description ?
                            this.props.detail.description.length > 150 ? <p>Ver mas</p> : <p></p>
                          } */}
                          <MdEdit
                            className="opacity"
                            color="#58595B"
                            size="25px"
                            onClick={() =>
                              this.setState({ editDescription: true })
                            }
                          />
                        </div>
                      )}
                    </div>
                  )}
                </DescriptionModal>
                <ColumnWrap
                  col="120px 1fr 20px 30px"
                  marginTop="20px"
                  marginBottom="20px"
                >
                  <Text color="#AAAAAA" fontFamily="Roboto-Bold">
                    {this.props.detail &&
                      moment(this.props.detail.activeDate).format('DD-MM-YYYY')}
                  </Text>
                  <Text color="#AAAAAA" fontFamily="Roboto-Bold">
                    {this.props.detail &&
                      moment(this.props.detail.activeDate).format('HH:mm')}
                  </Text>
                  <Text color="#AAAAAA" fontFamily="Roboto-Bold">
                    {this.props.detail && this.props.detail.quantityViewService}
                  </Text>
                  <img src="/assets/images/view.svg" alt="icon" height="15px" />
                </ColumnWrap>
                <div className="total-center">
                  <Divider backgroundColor="#909090" />
                  <Text
                    width="200px"
                    textAlign="center"
                    color="#AAAAAA"
                    fontFamily="Roboto-Bold"
                  >
                    {lang.location}
                  </Text>
                  <Divider backgroundColor="#909090" />
                </div>
                <SectionMaps>
                  {this.props.formChange && this.props.formChange.center ? (
                    <div style={{ minHeight: '30vh', width: '100%' }}>
                      <GoogleMaps
                        center={
                          this.props.formChange && this.props.formChange.center
                        }
                      />
                    </div>
                  ) : (
                    <div
                      className="total-center"
                      style={{
                        height: '240px',
                      }}
                    >
                      <CircularProgress />
                    </div>
                  )}
                  <div style={{ padding: '20px' }}>
                    {this.state.editChangeAddres ? (
                      <div style={{ display: 'flex' }}>
                        <img
                          src="/assets/images/location-on.svg"
                          alt="icon"
                          height="30px"
                        />
                        <div style={{ marginLeft: '10px' }}>
                          <PlacesAutocomplete
                            value={this.state.newAddress}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <InputBase
                                  style={{
                                    width: '300px',
                                    fontFamily: 'Roboto',
                                    fontSize: '14px',
                                    color: '#AAAAAA',
                                  }}
                                  {...getInputProps({
                                    placeholder: this.state.address,
                                    className: 'location-search-input',
                                  })}
                                  autoFocus={true}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                      ? 'suggestion-item--active'
                                      : 'suggestion-item';
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: '#fafafa',
                                          cursor: 'pointer',
                                        }
                                      : {
                                          backgroundColor: '#ffffff',
                                          cursor: 'pointer',
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </div>
                    ) : (
                      <BarLocation>
                        <img
                          src="/assets/images/location-on.svg"
                          alt="icon"
                          height="30px"
                        />
                        <Text
                          color="#AAAAAA"
                          fontFamily="Roboto"
                          fontWeight="300"
                        >
                          {this.props.detail && this.props.detail.address}
                        </Text>
                      </BarLocation>
                    )}
                  </div>
                </SectionMaps>
                <div style={{ textAlign: 'right', width: '100%' }}>
                  {this.state.editChangeAddres ? (
                    <MdDone
                      className="opacity"
                      color="rgb(128, 128, 128)"
                      size="30px"
                      onClick={this.handleClickNewAddress}
                    />
                  ) : (
                    <MdEdit
                      className="opacity"
                      color="#58595B"
                      size="25px"
                      onClick={() => this.setState({ editChangeAddres: true })}
                    />
                  )}
                </div>
                <div className="total-center">
                  <Divider backgroundColor="#909090" />
                  <Text
                    width="150px"
                    textAlign="center"
                    color="#AAAAAA"
                    fontFamily="Roboto-Bold"
                  >
                    {lang.price}
                  </Text>
                  <Divider backgroundColor="#909090" />
                </div>
                <div
                  className="total-center flex-column"
                  style={{ margin: '20px 0px' }}
                >
                 {/*  <img
                    src="/assets/images/dolar.svg"
                    alt="icon"
                    height="50px"
                  />*/}
                  {this.state.editPrice ? (
                    <BoxPrice>
                      <InputBase
                        value={
                          this.props.formChange && this.props.formChange.price
                            ? this.props.formChange.price
                            : ''
                        }
                        inputProps={{ 'aria-label': 'naked' }}
                        style={{
                          color: '#fff',
                          width: '50px',
                          marginLeft: '12px',
                        }}
                        onChange={this.handleOnChangeService(
                          'price',
                          'errorPrice',
                        )}
                      />
                      <div
                        style={{ position: 'absolute', marginLeft: '100px' }}
                      >
                        <MdDone
                          className="opacity"
                          color="#008000"
                          size="25px"
                          onClick={this.handleClickPatchPrice}
                        />
                      </div>
                    </BoxPrice>
                  ) : (
                    <BoxPrice>
                      {this.props.detail && this.props.detail.minPrice}
                      <div
                        style={{ position: 'absolute', marginLeft: '100px' }}
                      >
                       {/* <MdEdit
                          className="opacity"
                          color="#58595B"
                          size="25px"
                          // onClick={() => this.setState({ editPrice: true })}
                        /> */}
                      </div>
                    </BoxPrice>
                  )}
                </div>
                <div className="total-center">
                  <Divider backgroundColor="#909090" />
                  <Text
                    width="300px"
                    textAlign="center"
                    color="#AAAAAA"
                    fontFamily="Roboto-Bold"
                  >
                    {lang.paymentMethods}
                  </Text>
                  <Divider backgroundColor="#909090" />
                </div>
                <SectionCripto>
                  <div className="total-center">
                    <ColumnWrap
                      col=" 1fr 1fr 1fr "
                      maxWidth="300px"
                      justifyItems="center"
                      alignItems="center"
                      marginTop="20px"
                    >
                      {this.props.payment && this.props.payment.length > 0
                        ? this.props.payment.map((element, key) => {
                            if (
                              element.name === 'Efectivo' ||
                              element.name === 'Débito' ||
                              element.name === 'Crédito' ||
                              element.name === 'Criptomoneda' ||
                              element.name === 'Gratis' 
                              //element.name === 'Mastercard'||
                              //element.name === 'Visa' 
                            ) {
                              return (
                                <div key={key}>
                                  <ItemsPayments
                                    activeAll={this.state.activeAll}
                                    onClick={value =>
                                      this.handleClickSelect(value, element)
                                    }
                                    edit={this.state.editPayment}
                                    select={element.select}
                                  >
                                    <img
                                      src={element.logo}
                                      alt="icon"
                                      height="45px"
                                      width="75px"
                                      style={{
                                        objectFit: 'contain',
                                      }}
                                    />
                                  </ItemsPayments>
                                </div>
                              );
                            }
                            return null;
                          })
                        : null}
                    </ColumnWrap>
                  </div>
                  <div className="total-center">
                    <div
                      style={{
                        display: 'flex',
                        marginTop: 20,
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      {/* {this.props.payment && this.props.payment.length > 0
                        ? this.props.payment.map((element, key) => {
                            if (
                              //element.name === 'Ethereum' ||                              
                              //element.name === 'Monero' ||
                              //element.name === 'Ripple' ||
                              //element.name === 'Dash' ||
                              //element.name === 'Bitcoin'
                            ) {
                              return (
                                <div
                                  style={{ minWidth: 60, minHeight: 60 }}
                                  key={key}
                                >
                                  <ItemsCripto
                                    activeAll={this.state.activeAll}
                                    onClick={value =>
                                      this.handleClickSelect(value, element)
                                    }
                                    edit={this.state.editPayment}
                                    select={element.select}
                                  >
                                    <img
                                      src={element.logo}
                                      alt="icon"
                                      height="30px"
                                      style={{
                                        objectFit: 'contain',
                                      }}
                                    />
                                  </ItemsCripto>
                                </div>
                              );
                            }
                            return null;
                          })
                        : null} */}
                    </div>
                  </div>
                  {this.state.editPayment ? null : (
                    <div
                      onClick={this.handleEditPayment}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginRight: '10px',
                      }}
                    >
                      <MdEdit className="opacity" color="#58595B" size="25px" />
                    </div>
                  )}
                  <div>
                    {this.state.editPayment ? (
                      <div>
                        <Text
                          color="#ccc"
                          fontSize="12px"
                          fontFamily="Roboto"
                          textAlign="right"
                          onClick={() =>
                            this.handleClickSelectAll(!this.state.activeAll)
                          }
                        >
                          {lang.selectAll}
                          <MdCheckCircle
                            color={this.state.activeAll ? '#66bbff' : '#999999'}
                            fontSize="20px"
                          />
                        </Text>
                        <Divider
                          backgroundColor="#E6E6E6"
                          width="100%"
                          marginTop="10px"
                          marginBottom="20px"
                        />
                        <div className="total-center">
                          <ItemsPayments
                            onClick={value => this.handleClickSelect(value)}
                            activeAll={this.state.activeAll}
                          >
                           {/* <img
                              src="/assets/images/fibypay.svg"
                              alt="icon"
                              width="150px"
                            /> */}
                          </ItemsPayments>
                        </div>
                        <div className="total-center">
                          {this.props.formChange.errorDataPayment ? (
                            <p
                              className="error-message"
                              style={{ margin: '5px' }}
                            >
                              {this.props.formChange.errorDataPayment}
                            </p>
                          ) : null}
                        </div>
                        <div className="total-center opacity">
                          <Text
                            color="#333333"
                            fontSize="18px"
                            fontFamily="Roboto"
                            marginTop="30px"
                            onClick={this.handleClickEditPayment}
                          >
                            {lang.ok}
                          </Text>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </SectionCripto>
              </ContainerInfo>
              {this.state.editTermsAndConditions ? (
                <div style={{ display: 'flex' }}>
                  <InputBase
                    value={
                      this.props.formChange &&
                      this.props.formChange.termsAndConditions
                        ? this.props.formChange.termsAndConditions
                        : ''
                    }
                    inputProps={{ 'aria-label': 'naked' }}
                    style={{
                      color: '#58595b',
                      padding: '20px',
                      width: '90%',
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                    }}
                    multiline
                    rowsMax="4"
                    onChange={this.handleOnChangeService(
                      'termsAndConditions',
                      'errorTermsAndConditions',
                    )}
                    autoFocus={true}
                  />
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <MdDone
                      className="opacity"
                      color="rgb(128, 128, 128)"
                      size="30px"
                      onClick={this.handleClickPatchTermsAndConditions}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="total-center"
                  onClick={() =>
                    this.setState({ editTermsAndConditions: true })
                  }
                >
                  <Text color="#333480" fontFamily="Roboto" marginTop="15vh">
                    {lang.termsAndConditions}{' '}
                    <span>
                      {' '}
                      <MdEdit className="opacity" color="#58595B" size="25px" />
                    </span>
                  </Text>
                </div>
              )}
            </ContainerModal>
          )}
        </Modal>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.showMessage}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <SnackbarContent
            style={
              this.state.errorMessage
                ? { backgroundColor: 'red' }
                : { backgroundColor: 'green' }
            }
            // className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
              // <span id="client-snackbar" className={classes.message}>
              <div>
                {this.state.message
                  ? this.state.message
                  : this.state.errorMessage}
              </div>
            }
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={this.handleClose}
              >
                <MdClear onClick={this.handleClose} />
              </IconButton>,
            ]}
          />
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = state => {
  // console.log(state.servicesStore.detailServices);
  return {
    loadDetail: state.servicesStore.detailServices.load,
    errorDetail: state.servicesStore.detailServices.error,
    detail: state.servicesStore.detailServices.detail,
    formChange: state.changeForm.form,
    uid: state.accessStore.loginStore.data,
    categories: state.generalStore.categories.data,
    payment: state.generalStore.payments.data,
    serviceList: state.servicesStore.servicesUser.list,
  };
};
export default connect(
  mapStateToProps,
  {
    getDetailServices,
    getStartDetailServices,
    detailServices,
    errorDetailServices,
    applicationForm,
    patchServicePrice,
    patchTermsAndConditions,
    putDetailService,
    getServicesUser,
    listServicesUser,
    errorListServicesUser,
    getListPayment,
    getMethodPayment,
    putMethodPayment,
    getSubCategoryUser,
    patchServiceLocation,
  },
)(CardServices);

const BarTitle = styled.div`
  width: 100%;
  height: 23px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #252525;
  font-family: 'Roboto-Bold';
  font-size: 13px;
  margin-bottom: 20px;
`;
const ContainerCategoty = styled.div`
  width: calc(100% - 20px);
  padding: 8px;
  max-width: 325px;
  margin-left: 85px;
  min-height: 300px;
  background-color: #fff;
  position: absolute;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  z-index: 9000000000000;
  @media (max-width: 480px) {
    margin-left: 5px;
    width: calc(95% - 20px);
  }
`;
const ContainerInfo = styled.div`
  width: calc(100% - 20px);
  padding: 10px;
`;
const SectionCripto = styled.div`
  width: 100%;
  min-height: 150px;
  border: solid 1px #333480;
  padding-bottom: 20px;
  margin: 10px 0px;
`;

const BoxPrice = styled.div`
  width: 100%;
  max-width: 150px;
  height: 30px;
  border-radius: 20px;
  background-color: #1d4e88;
  position: relative;
  margin-top: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;
const BarLocation = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  height: 30px;
  padding: 5px;
  width: calc(100% - 10px);
`;
const SectionMaps = styled.div`
  border: solid 1px #1D4E88;
  margin: 10px 0px;
`;
const Container = styled.div`
  width: 250px;
  max-width: 250px;
  height: 270px;
  background-color: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  padding: 0px;
  border-radius: 5px;
`;
const Image = styled.div`
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const DescriptionModal = styled.div`
  font-family: 'Roboto';
  font-size: 16px;
  color: #58595b;
  span {
    color: #333480;
  }
`;
const Description = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 18px;
  color: #58595b;
  margin-top: 10px;
`;
const WrapText = styled.div`
  width: calc(100% - 5px);
  height: 30px;
  background: rgba(0, 0, 0, 1);
  display: flex;
  align-items: center;
  padding-left: 5px;

  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(231, 56, 39, 0.06) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 1)),
    color-stop(100%, rgba(231, 56, 39, 0.06))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(231, 56, 39, 0.06) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(231, 56, 39, 0.06) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(231, 56, 39, 0.06) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(231, 56, 39, 0.06) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#e73827', GradientType=0 );
`;
const WrapTextBottom = styled.div`
  width: calc(100% - 10px);
  min-height: ${props => props.height || '50px'};
  background: rgba(0, 0, 0, 1);
  display: flex;
  align-items: ${props => props.alignItems || 'flex-end'};
  padding: 5px;
  flex-direction: column;
  justify-content: space-around;
  padding: ${props => props.padding};
  background: rgba(255, 255, 255, 0);
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.53) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(100%, rgba(0, 0, 0, 0.53))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.53) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.53) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.53) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.53) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
`;
const WrapIconText = styled.div`
  display: flex;
  justify-items: ${props => props.justifyItems};
  align-items: ${props => props.alignItems || 'center'};
`;
const Icon = styled.img`
  width: auto;
  height: 15px;
  margin-right: 5px;
`;
const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  object-fit: cover;
`;
const ContainerModal = styled.div`
  width: 450px;
  min-height: ${props => props.minHeight || '150px'};
  /* padding: 20px 0px; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media (max-width: 620px) {
    width: 90vw;
  }
`;
const WrapDescription = styled.div`
  width: calc(100% - 20px);
  padding: 0px 10px;
  max-height: 85px;
  overflow: hidden;
`;
const ContainerCheck = styled.span`
  display: 'flex';
  justify-content: 'flex-end';
  align-items: 'flex-end';
  margin-bottom: '5px';
`;
const CloseResponsive = styled.div`
  background: rgba(0, 0, 0, 0.53);
  position: absolute;
  margin-top: 5px;
  margin-left: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: center;
  @media (max-width: 620px) {
    display: flex;
  }
`;
