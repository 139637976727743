import React, { Fragment } from 'react'
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Autocomplete from 'react-google-autocomplete';
import Geocode from "react-geocode";
import './Map.css';

const apiKey = "AIzaSyC_zg_8vc1B_BVJ73VOOqwMXnxUIE5v5mM";
Geocode.setApiKey(apiKey);
Geocode.enableDebug();
class Map extends React.Component {
constructor( props ){
  super( props );
  this.state = {
   address: '',
   city: '',
   area: '',
   state: '',
   mapPosition: {
    lat: this.props.center.lat,
    lng: this.props.center.lng
   },
   markerPosition: {
    lat: this.props.center.lat,
    lng: this.props.center.lng
    }
  }
 }

/**
  * Get the current address from the default map position and set those values in the state
  */
 componentDidMount() {

  Geocode.fromLatLng( this.state.mapPosition.lat , this.state.mapPosition.lng ).then(
   response => {
    
    const address = response.results[0].formatted_address;
     const addressArray =  response.results[0].address_components;
     const city = this.getCity( addressArray );
     const area = this.getArea( addressArray );
     const state = this.getState( addressArray );

     this.props.onMapChange({lat:this.state.mapPosition.lat,lng:this.state.mapPosition.lng,address:address});

    this.setState( {
     address: ( address ) || '',
     area: ( area ) || '',
     city: ( city ) || '',
     state: ( state ) || '',
    } )
   },
   error => {
    console.error(error);
   }
  );
 }
;
/**
  * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
  *
  * @param nextProps
  * @param nextState
  * @return {boolean}
  */
 shouldComponentUpdate( nextProps, nextState ){
  if (
   this.state.markerPosition.lat !== nextState.markerPosition.lat ||
   this.state.address !== nextState.address ||
   this.state.city !== nextState.city ||
   this.state.area !== nextState.area ||
   this.state.state !== nextState.state
  ) {
   return true
  } if ( this.props.center.lat === nextProps.center.lat ){
   return false
  }
 }

/**
  * Get the city and set the city input value to the one selected
  *
  * @param addressArray
  * @return {string}
  */
 getCity = ( addressArray ) => {
  let city = '';
  if (!addressArray) return city;

  for( let i = 0; i < addressArray.length; i++ ) {
   if ( addressArray[ i ].types[0] && addressArray[ i ].types[0] === 'administrative_area_level_2' ) {
    city = addressArray[ i ].long_name;
    return city;
   }
  }
 };

/**
  * Get the area and set the area input value to the one selected
  *
  * @param addressArray
  * @return {string}
  */
 getArea = ( addressArray ) => {
  let area = '';
  if (!addressArray) return area;

  for( let i = 0; i < addressArray.length; i++ ) {
   if ( addressArray[ i ].types[0]  ) {
    for ( let j = 0; j < addressArray[ i ].types.length; j++ ) {
     if ( addressArray[ i ].types[j] === 'sublocality_level_1' || addressArray[ i ].types[j] === 'locality' ) {
      area = addressArray[ i ].long_name;
      return area;
     }
    }
   }
  }
 };

/**
  * Get the address and set the address input value to the one selected
  *
  * @param addressArray
  * @return {string}
  */
 getState = ( addressArray ) => {
  let state = '';
  if (!addressArray) return state;
  for( let i = 0; i < addressArray.length; i++ ) {
   for( let i = 0; i < addressArray.length; i++ ) {
    if ( addressArray[ i ].types[0] && addressArray[ i ].types[0] === 'administrative_area_level_1' ) {
     state = addressArray[ i ].long_name;
     return state;
    }
   }
  }
 };

/**
  * And function for city,state and address input
  * @param event
  */
 onChange = ( event ) => {
  this.setState({ [event.target.name]: event.target.value });
 };

/**
  * This Event triggers when the marker window is closed
  *
  * @param event
  */
 onInfoWindowClose = ( event ) => {
};

/**
  * When the user types an address in the search box
  * @param place
  */
 onPlaceSelected = ( place ) => {
   if (!place || place===null || typeof place ==='undefined') return;
   if (!place.geometry || place.geometry===null || typeof place.geometry ==='undefined') return;

   const address = place.formatted_address;
   const addressArray =  place.address_components;
   const city = this.getCity( addressArray );
   const area = this.getArea( addressArray );
   const state = this.getState( addressArray );
   const latValue = place.geometry.location.lat();
   const lngValue = place.geometry.location.lng();

   this.props.onMapChange({lat:latValue,lng:lngValue,address: address});

// Set these values in the state.
  this.setState({
   address: ( address ) || '',
   area: ( area ) || '',
   city: ( city ) || '',
   state: ( state ) || '',
   markerPosition: {
    lat: latValue,
    lng: lngValue
   },
   mapPosition: {
    lat: latValue,
    lng: lngValue
   },
  })
 };

/**
  * When the marker is dragged you get the lat and long using the functions available from event object.
  * Use geocode to get the address, city, area and state from the lat and lng positions.
  * And then set those values in the state.
  *
  * @param event
  */
 onMarkerDragEnd = ( event ) => {
  const newLat = event.latLng.lat();
   const newLng = event.latLng.lng();
   const addressArray = [];
   

Geocode.fromLatLng( newLat , newLng ).then(
   response => {

     const address = response.results[0].formatted_address;
     const addressArray =  response.results[0].address_components;
     const city = this.getCity( addressArray );
     const area = this.getArea( addressArray );
     const state = this.getState( addressArray );
     this.props.onMapChange({lat:newLat,lng:newLng,address: address});
     
    this.setState( {
     address: ( address ) || '',
     area: ( area ) || '',
     city: ( city ) || '',
     state: ( state ) || '',
     mapPosition: {
        lat: newLat,
        lng: newLng
       },
    markerPosition: {
        lat: newLat,
        lng: newLng
    }
    } )
   },
   error => {
    console.error(error);
   }
  );
 };

render(){
const AsyncMap = withScriptjs(
   withGoogleMap(
    props => (<div>
    <Autocomplete
       style={{
        width: '98%',
        border: 0,
        padding: '5px',
        position: 'relative',
        top: this.props.searchTextTop || '0',
        color: '#808080',
        outline: false
       }}
       onPlaceSelected={ this.onPlaceSelected }
       types={['(regions)']}
      />

     <GoogleMap
      google={this.props.google}
      defaultZoom={this.props.zoom}
      defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
     >

        <Marker google={this.props.google}
            icon={{
              url: 'assets/images/service/marker.png'
            }}
            name=""
            draggable
            onDragEnd={ this.onMarkerDragEnd }
            position={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }}
        />
        <Marker />

        <InfoWindow
            onClose={this.onInfoWindowClose}
            position={{ lat: ( this.state.markerPosition.lat + 0.0010 ), lng: this.state.markerPosition.lng }}
        >
            <div>
                <span style={{ padding: 0, margin: 0 }}>{ this.state.address }</span>
            </div>
        </InfoWindow>

    </GoogleMap>
    </div>
    )
    )
);

  let map;
  if( this.props.center.lat !== undefined ) {
   map =
        <AsyncMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`}
        loadingElement={
            <div style={{ height: "280px", width:"100%" }} />
        }
        containerElement={
            <div style={
                {
                  marginTop:"5px",
                  height: 'calc(this.props.height+25)',
                  width:"100%"
                }
              } />
        }
        mapElement={
            <div style={{ height: this.props.height, width:"100%", bottom:'-10px'}} />
        }
        />
    } else {
        map = <div style={{ height: this.props.height, width:"100%"}} />
  }
  return ( map )
 }
}



export default Map
