import styled from 'styled-components';

const Divider = styled.div`
  background-color: ${props => props.backgroundColor || '#1D4E88'};
  width: ${props => props.width || '90%'};
  max-width: ${props => props.maxWidth};
  height: ${props => props.height || '1px'};
  margin: ${props => props.marginX};
  margin-top: ${props => props.marginTop};
  margin-right: ${props => props.marginRight};
  margin-left: ${props => props.marginLeft};
  margin-bottom: ${props => props.marginBottom};
  padding-bottom: ${props => props.paddingBottom};
  padding-left: ${props => props.paddingLeft};
  @media (max-width: ${props => props.sizeSm || '992px'}) {
    display: ${props => props.displayN};
  }
  @media (max-width: ${props => props.sizeMd || '768px'}) {
    display: ${props => props.displayMd};
  }
  @media (max-width: ${props => props.sizeXs || '620px'}) {
    display: ${props => props.displayXs};
  }
  @media (max-width: ${props => props.sizeXxs || '420px'}) {
    display: ${props => props.displayXxs};
  }
`;

export default Divider;
