import React from 'react';
import styled from 'styled-components';

const ArrowBack = props => {
  return (
    <Container onClick={props.onClick} className="opacity">
      <img src="/assets/images/arrow-back.svg" alt="arrow back" width="10px" height="auto"/>
    </Container>
  );
};
const Container = styled.div`
        position: absolute;
    margin-top: -60px;
    /* top: 20px;
    left: 20px; */
    width: 15px;
    height: 15px;
    border-radius: 10px
`;
export default ArrowBack;
