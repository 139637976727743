import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import DurationTrigger from '../DurationTrigger';
import Text from '../../../../components/generics/Text';
import * as COLORS from '../../../../util/colors';
import * as UTILS from '../../../../util/utils';

const DurationTimer = (props) => {
  const [hours,setHours] = useState(UTILS.range(0,24));
  const [hour,setHour] = useState(props.hour || hours[0]);
  const [minutes,setMinutes] = useState(props.minutesRange);
  const [minute,setMinute] = useState(props.minute || minutes[0]);

  useEffect (()=>{
    props.onChange && props.onChange(hour,minute);
  },[hour,minute]);

  useEffect (()=>{
    setHour(props.hour || hours[0]);
    setMinute(props.minute || minutes[0]);

  },[props]);

  return (
    <Container width={props.width}>
      <DurationTrigger 
        key={UTILS.randomStr(10)}
        selected={hour} 
        width={'100%'}
        options={hours} 
        onChange={(selected)=>{setHour(selected);}} />
        <Text
        fontFamily='Roboto'
        fontSize='16px'
        textAlign='center'
        marginTop={'10px'}
        color={COLORS.darkGray}      
      >hrs.</Text>
      <DurationTrigger 
        key={UTILS.randomStr(10)}
        selected={minute} 
        width={'100%'}
        options={minutes} 
        onChange={(selected)=>{setMinute(selected);}} /> 
      <Text
        fontFamily='Roboto'
        fontSize='16px'
        textAlign='center'
        marginTop={'10px'}
        color={COLORS.darkGray}      
      >min.</Text>         
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: ${props => props.width || '100%' };
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
`;

export default DurationTimer;
