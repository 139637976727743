import React, { Component } from 'react';
import styled from 'styled-components';
import { MdCheckCircle } from 'react-icons/md';

class ItemsPayments extends Component {
  state = {
    active: this.props.activeAll || this.props.select,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeAll !== this.props.activeAll) {
      this.setState({
        active: nextProps.activeAll,
      });
    }
    if (nextProps.active !== this.props.active) {
      this.setState({
        active: nextProps.active,
      });
    }
  }

  handleOnChangeState = value => () => {
    if (value === true) {
      this.setState({ active: false }, () => {
        this.props.onClick(this.state.active);
      });
    } else {
      this.setState({ active: !this.state.active }, () => {
        this.props.onClick(this.state.active);
      });
    }
  };

  render() {
    return (
      <Wrapper>
        <Container
          onClick={
            this.props.edit ? this.handleOnChangeState(this.props.select) : null
          }
        >
          {this.props.children}
        </Container>
        {this.props.select && this.props.edit === false ? (
          <WrapIcon>
            <MdCheckCircle
              color={this.props.select ? '#66bbff' : '#999999'}
              fontSize="20px"
            />
          </WrapIcon>
        ) : null}
        {this.props.edit ? (
          <WrapIcon>
            <MdCheckCircle
              color={
                this.state.active || this.props.select ? '#66bbff' : '#999999'
              }
              fontSize="20px"
            />
          </WrapIcon>
        ) : null}
      </Wrapper>
    );
  }
}
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Container = styled.div`
  width: auto;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const WrapIcon = styled.div`
  /* margin-left: 10px; */
  height: 22px;
  width: 22px;
  /* background-color: rebeccapurple; */
  position: sticky;
  margin-left: -5px;
`;
export default ItemsPayments;
