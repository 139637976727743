import LocalizedStrings from 'react-localization';
import { es } from './es';
import { en } from './en';

const lang = new LocalizedStrings({
  es,
  en,
});

// lang.setLanguage('en')
//console.log("lenguaje de interfaz " + lang.getInterfaceLanguage())
//console.log("lenguaje mostrado "+ lang.getLanguage())

export default lang;
