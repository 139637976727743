import React, { Component, useState } from 'react';
import styled from 'styled-components';
import {Grid, Container} from '@material-ui/core';
import * as COLORS from '../../../../util/colors';
import Text from '../../../../components/generics/Text';
import InputArea from '../../../../components/generics/InputArea';
import Button from '../../components/Button';
let placeHolderText='Describe cuales son los términos, condiciones, límites y/o normativas de tu servicio.';
placeHolderText+='Informa a los usuarios todo lo que deben saber sobre él y ofrece una grata experiencia';

const Terms = (props) => {
  const [terms, setTerms] = useState('');

  const stepValid = () => {
    return (
      terms.length>=10 
      && terms.length<=600
      && !(/^\s/.test(terms)) 
      && !(/^([0-9])*$/.test(terms))             // No pueden ser todos numeros
      && !(/^([^A-za-z])*$/.test(terms))         // No pueden ser todos distintos de letras
      && props.saving===false);
  };

  const onSave = () => {
    
    if (!stepValid()) {
      const msg = {
        show: true,
        msg: 'Campo obligatorio. debe contener 10 caracteres coom minimo y contener letras',
        type: 'error'                  
      };

      props.onShowMessage(msg);

      return;
    }
    const dataChange= {terms};
    props.onChange && props.onChange(dataChange);
    console.log('actualizado trerm');
  };    

  const onChangeTerms = e => {
    if (e.keyCode==8) return true;

    let size = 0;
    if (terms) {
      size = terms.length;
    }
    size+=1;
      
    return  (size<=600); 
  };

  return (
    <Container style={{margin:'0 auto',width: '80%'}}>   
      <Grid container>
        <Grid item xs={12} >
          <Text
            fontFamily='Roboto'
            fontSize='13px'
            fontWeight='bold'
            padding={0}
            textAlign='center'
            color={COLORS.blue}
          >18* Términos y condiciones</Text> 
          <Text
          fontFamily='Roboto'
          fontSize='10px'
          color={COLORS.darkGray}
          textAlign='center'
          marginTop='2px'
        > Los términos y condiciones deben contener entre 10 y 600 caracteres.
         </Text>
          <InputArea
            minlength='10'
            maxlength='600'
            onKeyPress={e => {
              if (onChangeTerms(e)) {
              } else {
                e.preventDefault();
              }
            }}            
            onChange={(evt)=>setTerms(evt.target.value)}
            value={terms}
            fontColor={COLORS.darkGray}
            fontFamily='Roboto'
            placeholder={placeHolderText}
            border={`1px solid ${COLORS.purple}`}
            rows={50}
            width="100%"
            height="350px"
          />                                 
        </Grid>
      </Grid> 
      <Grid container >
        <Grid item xs={6} style={{textAlign:'center'}}>
          <Button
            disabled={props.saving}
            context={'primary'}
            onClick={props.previousStep}
          >
                  Anterior
          </Button>
        </Grid>

        <Grid item xs={6} style={{textAlign:'center'}}>
          <Button
            disabled={!stepValid()}
            context={stepValid()?'primary':''}
            onClick={onSave}
          >
                Guardar
          </Button>
        </Grid>
      </Grid> 
    </Container>  
  );
};

export default Terms;
