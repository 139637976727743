import reducers from './reducers';

export {
  getCountries,
  getState,
  getlistGender,
  getListCategories,
  getListPayment,
} from './actions';
export {
  getCountry,
  getGender,
  getCategories,
  getMethodPayment,
} from './operations';

export default reducers;
