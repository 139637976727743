import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import Divider from './../generics/Divider';
import ColumnWrap from '../generics/ColumnWrap';
import Text from '../generics/Text';
import lang from '../../lang'

class ItemsPlan extends PureComponent {
  render() {
    return (
      <div style={{ marginTop: '10px', marginBottom: '20px' }}>
        <Divider backgroundColor="#6E00BF" width="100%" />
        {this.props.loadPlan ? (
          <div className="total-center" style={{ margin: '10px' }}>
            <CircularProgress />
          </div>
        ) : (
          <ColumnWrap
            col="1fr 1fr 1fr 1fr"
            colN="1fr 1fr 1fr"
            sizeXs="480px"
            colXs="1fr"
          >
            
            <ContainerPlan>
              <Icon src="/assets/images/service.svg" alt="icon" />
              <div>
                <Title>{lang.currentPlan}</Title>
                <Text
                  color="#6E00BF"
                  textAlign="center"
                  fontSize="16px"
                  fontFamily="Roboto"
                >
                  {this.props.planUser && this.props.planUser.name}
                </Text>
              </div>          
            </ContainerPlan>

            <ContainerPlan border-right='solid 1px #6E00BF' borderMd="none">
              <Icon src="/assets/images/servicesqty.png" alt="icon" />
              <div>
                <Title>
                  {lang.totalServices}:{' '}
                  {this.props.planUser && this.props.planUser.quantityServices}
                </Title>
                <Text
                  textAlign="center"
                  fontFamily="Roboto"
                  fontWeight="300"
                  color="#59595B"            
                >
                {lang.available}:{' '}
                  {this.props.planUser &&
                    this.props.planUser.balanceQuantityServices}
                </Text>
              </div>
            </ContainerPlan>

            <ContainerPlan borderMd="none">
              <Icon src="/assets/images/plazas.svg" alt="icon" />
              <div>
                <Title>
                  {lang.totalSeats}:{' '}
                  {this.props.planUser && this.props.planUser.quantityPlaces}
                </Title>
                <Text
                  textAlign="center"
                  fontFamily="Roboto"
                  fontWeight="300"
                  color="#59595B"
                >
                  {lang.available}:{' '}
                  {this.props.planUser &&
                    this.props.planUser.balanceQuantityPlaces}
                </Text>
              </div>
            </ContainerPlan>
            <ContainerPlan border="none" borderMd="none">
              <Icon src="/assets/images/pass.svg" alt="icon" />
              <div>
                <Title>
                {lang.totalPasses}:{' '}
                  {this.props.planUser && this.props.planUser.quantityPasses}
                </Title>
                <Text
                  textAlign="center"
                  fontFamily="Roboto"
                  fontWeight="300"
                  color="#59595B"
                >
                {lang.available}:{' '}
                  {this.props.planUser &&
                    this.props.planUser.balanceQuantityPasses}
                </Text>
              </div>
            </ContainerPlan>
         
            
            {/* <ContainerPlan border="none" borderMd="none">
              <Icon src="/assets/images/present.svg" alt="icon" />
              <div>
                <Title>
                  {lang.totalGift}:{' '}
                  {this.props.planUser && this.props.planUser.quantityGifts}
                </Title>
                <Text
                  textAlign="center"
                  fontFamily="Roboto"
                  fontWeight="300"
                  color="#59595B"
                  fontSize="13px"
                >
                {lang.available}:{' '}
                  {this.props.planUser &&
                    this.props.planUser.balanceQuantityGifts}
                </Text>
              </div>
            </ContainerPlan> */}
          </ColumnWrap>
        )}
        <Divider backgroundColor="#6E00BF" width="100%" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loadPlan: state.planStore.planUser.load,
    planUser: state.planStore.planUser.plan,
  };
};
export default connect(
  mapStateToProps,
  {},
)(ItemsPlan);

const ContainerPlan = styled.div`
  width: 100%;
  height: 80px;
  margin: 5px 0px;
  border-right: ${props => props.border || 'solid 1px #6E00BF'};
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  grid-gap: 20px;
  align-items: center;
  /* justify-items: center */
  @media (max-width: 992px) {
    border-right: ${props => props.borderMd || 'solid 1px #6E00BF'};
  }
  @media (max-width: 480px) {
    border-right: none;
  }
`;

const Icon = styled.img`
  width: auto;
  height: 50px;
  justify-self: end;
`;
const Title = styled.p`
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 16px;
  color: #1D4E88;
  text-align: center;
  margin-bottom: 5px;
`;
