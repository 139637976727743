import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { MdMenu } from 'react-icons/md';
import { MdClose } from 'react-icons/md';
import Text from '../generics/Text';
import { logout } from '../../ducks/access';
import lang from '../../lang';

class Header extends Component {
  state = {
    menuResponsive: false,
  };

  handleClickLogout = () => {
    this.props.logout();
    this.props.history.push('/');
  };

  render() {
    return (
      <Wrap>
        <Container>
          <Logo
            src="/assets/images/logo-small.svg"
            alt="logo"
            onClick={() => this.props.history.push('/dashboard')}
          />
          <OptionUser onClick={this.handleClickLogout}>
            {this.props.picture && this.props.picture.responseInfo ? (
              <Avatar
                src={
                  this.props.picture.responseInfo.user &&
                  this.props.picture.responseInfo.user.profilePicture
                    ? this.props.picture.responseInfo.user.profilePicture
                        .highQuality
                    : ''
                }
                alt="logo"
              />
            ) : null}
            <Text color="#FFF" fontFamily="Roboto" fontWeight="400">
              {lang.logOut}
            </Text>
          </OptionUser>
          <MenuResponsive>
            {this.state.menuResponsive ? (
              <MdClose
                onClick={() =>
                  this.setState({ menuResponsive: !this.state.menuResponsive })
                }
              />
            ) : (
              <MdMenu
                onClick={() =>
                  this.setState({ menuResponsive: !this.state.menuResponsive })
                }
              />
            )}
          </MenuResponsive>
        </Container>
        <LineGradient />
        {this.state.menuResponsive ? (
          <BoxOption>
            <Text
              className="opacity"
              onClick={this.handleClickLogout}
              color="#FFF"
              fontFamily="Roboto"
              fontWeight="400"
            >
              {lang.logOut}
            </Text>
          </BoxOption>
        ) : null}
      </Wrap>
    );
  }
}

const mapStateToProps = state => {
  return {
    picture: state.accessStore.loginStore.data,
  };
};

export default connect(
  mapStateToProps,
  { logout },
)(Header);
const Wrap = styled.div`
  position: fixed;
  width: 100%;
  top: 0px;
`;
const Logo = styled.img`
  width: auto;
  height: 40px;
`;
const Container = styled.div`
  background-color: #1e1e1e;
  width: calc(100% - 60px);
  padding: 0px 30px;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LineGradient = styled.div`
  width: 100%;
  height: 5px;
  background: #333480;
  background: -moz-linear-gradient(
    left,
    #DA0458 0%,
    #6E00BF 25%,
    #6E00BF 75%,
    #333480 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #DA0458),
    color-stop(25%, #6E00BF),
    color-stop(75%, #6E00BF),
    color-stop(100%, #333480)
  );
  background: -webkit-linear-gradient(
    left,
    #DA0458 0%,
    #6E00BF 25%,
    #6E00BF 75%,
    #333480 100%
  );
  background: -o-linear-gradient(
    left,
    #DA0458 0%,
    #6E00BF 25%,
    #6E00BF 75%,
    #333480 100%
  );
  background: -ms-linear-gradient(
    left,
    #6E00BF 0%,
    #6E00BF 25%,
    #333480 75%,
    #333480 100%
  );
  background: linear-gradient(
    to right,
    #DA0458 0%,
    #6E00BF 25%,
    #6E00BF 75%,
    #333480 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80b42d', endColorstr='#6596f1', GradientType=1 );
`;
const Avatar = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 8px;
  object-fit: cover;
  background:linear-gradient(#ccc, #ccc) padding-box,
  linear-gradient(90deg, rgba(51,52,128,1) 0%, rgba(110,0,191,1) 100%) border-box;
  border: solid 3px transparent;
`;
const OptionUser = styled.div`
  width: 100%;
  max-width: 150px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  :hover {
    opacity: 0.5;
    cursor: pointer;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  @media (max-width: 620px) {
    display: none;
  }
`;
const MenuResponsive = styled.div`
  /* width: 100%;
    max-width: 50px; */
  height: 100%;
  display: none;
  @media (max-width: 620px) {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 35px;
  }
`;
const BoxOption = styled.div`
  width: calc(50% - 40px);
  max-width: 300px;
  min-height: 20px;
  background-color: #1e1e1e;
  position: absolute;
  z-index: 99999999999999;
  padding: 20px;
  display: grid;
  grid-auto-rows: 20px;
  grid-gap: 20px;
  align-items: center;
  right: 5px;
  margin-top: 5px;
  border-radius: 5px;
`;
