import React, { Component } from 'react';
import 'date-fns';
import firebase from 'firebase/app';
import 'firebase/auth';
import { connect } from 'react-redux';
import { DropdownList } from 'react-widgets/lib/';
import 'react-widgets/dist/css/react-widgets.css';
import moment from 'moment';
import styled from 'styled-components';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { CircularProgress } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import Text from '../../components/generics/Text';
import Input from '../../components/generics/Input';
import InputPass from '../../components/generics/InputPass';
import ButtonLoader from '../../components/generics/Button';
import Divider from '../../components/generics/Divider';
import ColumnWrap from '../../components/generics/ColumnWrap';
import RadioButton from '../../components/generics/RadioButton';
import SteppersDots from '../../components/generics/SteppersDots';
import ArrowBack from '../../components/generics/ArrowBack';
import { applicationForm } from '../../ducks/changeForm';
import {
  getCountry,
  getCountries,
  getState,
  getGender,
  getlistGender,
  getCategories,
  getListCategories,
} from '../../ducks/general';
import {
  registerProviderCompany,
  registerProviderPerson,
  loginGoogle,
  errorLoginGoogle,
  checkEmailAvailability,
  loginInit,
  postLogin,
  getValidateUserExist,
  validateExistEmail,
} from '../../ducks/access';
import { getUserId } from '../../ducks/user';
import Tooltip from '../../components/generics/Tooltip';
import lang from '../../lang';

class Register extends Component {
  constructor(props) {
    super(props);
    const obj = {
      email:
        props.dataGoogle && props.dataGoogle.email
          ? props.dataGoogle.email
          : '',
      errorEmail: '',
      usuario:
        props.dataGoogle && props.dataGoogle.usuario
          ? props.dataGoogle.usuario
          : '',
      errorUsuario: '',
      password:
        props.dataGoogle && props.dataGoogle.password
          ? props.dataGoogle.password
          : '',
      errorPassword: '',
      selectRadius: '',
      errorSelectRadius: '',
      select: '',
      errorSelect: '',
      country: '',
      errorCountry: '',
      state: '',
      errorState: '',
      nameOrganitation: '',
      errorNameOrganitation: '',
      address: '',
      errorAddres: '',
      zidCode: '',
      errorZidCode: '',
      createDate: lang.creationDate,
      errorCreateDate: '',
      idOrganization: '',
      errorIdOrganization: '',
      phone: '',
      errorPhone: '',
      nameContact: '',
      errorNameContact: '',
      numberEmployee: '',
      errorNumberEmployee: '',
      usuarioCouch: '',
      errorUsuarioCouch: '',
      idSelectNumberEmploye: '',
      firstName: '',
      errorFirstName: '',
      lastName: '',
      errorLastName: '',
      birthday: '',
      errorBirthday: '',
      gender: '',
      errorGender: '',
    };
    props.applicationForm(obj);
    props.getState([]);
    this.state = {
      scene: 'organizacionStep1',
      selectedDate: lang.yearsOfCreation,
      colorButton: false,
      message: '',
      errorMessage: '',
      load: false,
      loadingValidate: false,
      nameSelect: '',
      nameCountry: '',
      nameState: '',
    };
  }

  componentWillMount() {
    this.handleGetCountries();
    this.handleGetGender();
    this.handleGetCategories();
  }

  handleGetCountries = () => {
    this.props.getCountry(Response => {
      if (Response.code >= 200 && Response.code <= 299) {
        if (
          Array.isArray(Response.data.responseInfo) &&
          Response.data.responseInfo.length > 0
        ) {
          this.props.getCountries(Response.data.responseInfo);
        } else {
          this.props.getCountries([]);
        }
      } else {
        this.props.getCountries([]);
      }
    });
  };

  handleGetCategories = () => {
    this.props.getCategories(Response => {
      if (Response.code >= 200 && Response.code <= 299) {
        if (
          Response.data.responseInfo &&
          Array.isArray(Response.data.responseInfo)
        ) {
          this.props.getListCategories(Response.data.responseInfo);
        } else {
          this.props.getListCategories([]);
        }
      } else {
        this.props.getListCategories([]);
      }
    });
  };

  handleGetGender = () => {
    this.props.getGender(Response => {
      if (Response.code >= 200 && Response.code <= 299) {
        if (
          Response.data.responseInfo &&
          Array.isArray(Response.data.responseInfo)
        ) {
          this.props.getlistGender(Response.data.responseInfo);
        } else {
          this.props.getlistGender([]);
        }
      } else {
        this.props.getlistGender([]);
      }
    });
  };

  handleDateChange = date => {
    const onChangeForm = JSON.stringify(this.props.formChange);
    const parseChangeForm = JSON.parse(onChangeForm);
    parseChangeForm['createDate'] = moment(date).format('DD-MM-YYYY');
    parseChangeForm['errorCreateDate'] = '';
    const obj = {
      ...parseChangeForm,
    };
    this.handleApplicationForm(obj);
  };

  handleBirthday = date => {
    const onChangeForm = JSON.stringify(this.props.formChange);
    const parseChangeForm = JSON.parse(onChangeForm);
    parseChangeForm['birthday'] = moment(date).format('DD-MM-YYYY');
    parseChangeForm['errorBirthday'] = '';
    const obj = {
      ...parseChangeForm,
    };
    this.handleApplicationForm(obj);
  };

  handleOnchange = (prop, errorProp) => event => {
    const onChangeForm = JSON.stringify(this.props.formChange);
    const parseChangeForm = JSON.parse(onChangeForm);
    if ('phone' === prop) {
      this.handleChangePhone(parseChangeForm, prop, event.target.value);
    } else if (prop === 'usuario') {
      if (event.target.value.length <= 2) {
        parseChangeForm[prop] = event.target.value;
        parseChangeForm[errorProp] = lang.mustBeAtLeast3Characters;
        const obj = {
          ...parseChangeForm,
        };
        this.handleApplicationForm(obj);
      } else if (event.target.value.length > 30) {
        parseChangeForm[prop] = event.target.value;
        parseChangeForm[errorProp] = lang.mustHaveaMaximumOf30Characters;
        const obj = {
          ...parseChangeForm,
        };
        this.handleApplicationForm(obj);
      } else {
        parseChangeForm[prop] = event.target.value;
        parseChangeForm[errorProp] = '';
        const obj = {
          ...parseChangeForm,
        };
        this.handleApplicationForm(obj);
      }
    } else if (prop === 'password') {
      const noValido = /\s/;
      if (noValido.test(event.target.value)) {
        parseChangeForm[prop] = parseChangeForm.password;
        parseChangeForm[errorProp] = lang.blankSpacesAreNotAllowed0;
        const obj = {
          ...parseChangeForm,
        };
        this.handleApplicationForm(obj);
      } else {
        if (event.target.value !== ' ') {
          parseChangeForm[prop] = event.target.value;
          parseChangeForm[errorProp] = '';
          const obj = {
            ...parseChangeForm,
          };
          this.handleApplicationForm(obj);
        }
      }
    } else if (prop === 'email') {
      parseChangeForm[prop] = event.target.value;
      parseChangeForm[errorProp] = '';
      const obj = {
        ...parseChangeForm,
      };
      this.handleApplicationForm(obj);
      if (event.target.value === '') {
        this.props.loginGoogle({}, false);
        this.props.errorLoginGoogle('', false);
        parseChangeForm.password = '';
        const obj = {
          ...parseChangeForm,
        };
        this.handleApplicationForm(obj);
      }
    } else if (prop === 'idOrganization') {
      parseChangeForm[prop] =
        event.target.value.charAt(0).toUpperCase() +
        event.target.value.slice(1);
      parseChangeForm[errorProp] = '';
      const obj = {
        ...parseChangeForm,
      };
      this.handleApplicationForm(obj);
    } else if (prop === 'nameContact') {
      if (!(event.target.value.split(' ').length - 1 > 1)) {
        parseChangeForm[prop] = event.target.value;
        parseChangeForm[errorProp] = '';
        const obj = {
          ...parseChangeForm,
        };
        this.handleApplicationForm(obj);
      }
    } else {
      parseChangeForm[prop] = event.target.value;
      parseChangeForm[errorProp] = '';
      const obj = {
        ...parseChangeForm,
      };
      this.handleApplicationForm(obj);
    }
    if (this.props.errorGoogle !== '') {
      this.props.errorLoginGoogle('', false);
    }
  };

  handleApplicationForm = obj => {
    this.props.applicationForm(obj);
  };

  onChangeData = event => {
    if (event.target.value === 'particularStep1') {
      this.setState({
        nameSelect: '',
      });
      const onChangeForm = JSON.stringify(this.props.formChange);
      const parseChangeForm = JSON.parse(onChangeForm);
      parseChangeForm.select = '';
      parseChangeForm.errorSelect = '';
      parseChangeForm.errorUsuario = '';
      parseChangeForm.errorPassword = '';
      parseChangeForm.selectRadius = '';
      parseChangeForm.errorSelectRadius = '';
      parseChangeForm.select = '';
      parseChangeForm.errorSelect = '';
      parseChangeForm.country = '';
      parseChangeForm.errorCountry = '';
      parseChangeForm.state = '';
      parseChangeForm.errorState = '';
      parseChangeForm.nameOrganitation = '';
      parseChangeForm.errorNameOrganitation = '';
      parseChangeForm.address = '';
      parseChangeForm.errorAddres = '';
      parseChangeForm.zidCode = '';
      parseChangeForm.errorZidCode = '';
      parseChangeForm.createDate = lang.creationDate;
      parseChangeForm.idOrganization = '';
      parseChangeForm.errorIdOrganization = '';
      parseChangeForm.phone = '';
      parseChangeForm.errorPhone = '';
      parseChangeForm.nameContact = '';
      parseChangeForm.errorNameContact = '';
      parseChangeForm.numberEmployee = '';
      parseChangeForm.errorNumberEmployee = '';
      parseChangeForm.usuarioCouch = '';
      parseChangeForm.errorUsuarioCouch = '';
      parseChangeForm.idSelectNumberEmploye = '';
      const obj = {
        ...parseChangeForm,
      };
      this.handleApplicationForm(obj);
    } else {
      this.setState({
        nameSelect: '',
      });
      const onChangeForm = JSON.stringify(this.props.formChange);
      const parseChangeForm = JSON.parse(onChangeForm);
      parseChangeForm.select = '';
      parseChangeForm.errorSelect = '';
      parseChangeForm.errorUsuario = '';
      parseChangeForm.errorPassword = '';
      parseChangeForm.selectRadius = '';
      parseChangeForm.errorSelectRadius = '';
      parseChangeForm.select = '';
      parseChangeForm.errorSelect = '';
      parseChangeForm.country = '';
      parseChangeForm.errorCountry = '';
      parseChangeForm.state = '';
      parseChangeForm.errorState = '';
      parseChangeForm.nameOrganitation = '';
      parseChangeForm.errorNameOrganitation = '';
      parseChangeForm.address = '';
      parseChangeForm.errorAddres = '';
      parseChangeForm.zidCode = '';
      parseChangeForm.errorZidCode = '';
      parseChangeForm.createDate = lang.creationDate;
      parseChangeForm.idOrganization = '';
      parseChangeForm.errorIdOrganization = '';
      parseChangeForm.phone = '';
      parseChangeForm.errorPhone = '';
      parseChangeForm.nameContact = '';
      parseChangeForm.errorNameContact = '';
      parseChangeForm.numberEmployee = '';
      parseChangeForm.errorNumberEmployee = '';
      parseChangeForm.usuarioCouch = '';
      parseChangeForm.errorUsuarioCouch = '';
      parseChangeForm.idSelectNumberEmploye = '';
      parseChangeForm.firstName = '';
      parseChangeForm.errorFirstName = '';
      parseChangeForm.lastName = '';
      parseChangeForm.errorLastName = '';
      parseChangeForm.birthday = '';
      parseChangeForm.errorBirthday = '';
      parseChangeForm.gender = '';
      parseChangeForm.errorGender = '';
      const obj = {
        ...parseChangeForm,
      };
      this.handleApplicationForm(obj);
    }
    this.setState({
      scene: event.target.value,
    });
  };

  handleClickOrganization = () => {
    const form = JSON.stringify(this.props.formChange);
    const formParse = JSON.parse(form);
    const validate = this.validateFormOneOrganization(formParse);
    if (validate) {
      this.setState({
        loadingValidate: true,
        errorValidate: '',
      });
      const data = {
        userEmail: this.props.formChange.email,
      };
      this.props.validateExistEmail(
        this.props.formChange.email,
        data,
        result => {
          if (result.code >= 200 && result.code <= 299) {
            if (result.data.responseInfo) {
              const form = JSON.stringify(this.props.formChange);
              const formParse = JSON.parse(form);
              formParse.errorEmail = lang.theEmailIsDuplicated;
              const errors = {
                ...formParse,
              };
              this.setState({
                loadingValidate: false,
              });
              this.props.applicationForm(errors);
            } else {
              this.props.getValidateUserExist(
                this.props.formChange.usuario,
                Response => {
                  if (Response.code >= 200 && Response.code <= 299) {
                    if (Response.data.responseMsg === 'User Exist') {
                      this.setState({
                        loadingValidate: false,
                      });
                      const form = JSON.stringify(this.props.formChange);
                      const formParse = JSON.parse(form);
                      formParse.errorUsuario = lang.theUserIsDuplicated;
                      const errors = {
                        ...formParse,
                      };
                      this.props.applicationForm(errors);
                    } else {
                      this.setState({
                        scene: 'organizacionStep2',
                        loadingValidate: false,
                      });
                    }
                  } else {
                    this.setState({
                      errorValidate: result.message,
                      loadingValidate: false,
                    });
                  }
                },
              );
            }
          } else {
            this.setState({
              errorValidate: result.message,
              loadingValidate: false,
            });
          }
        },
      );
    }
  };

  validateFormOneOrganization = data => {
    if (!data) {
      return false;
    }
    if (!data.select) {
      data.errorSelect = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    if (!data.email) {
      data.errorEmail = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    if (data.email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(data.email).toLowerCase())) {
        data.errorEmail = lang.enterValidEmailAddress;
        const errors = {
          ...data,
        };
        this.props.applicationForm(errors);
        return false;
      }
    }

    if (!data.usuario) {
      data.errorUsuario = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    if (data.usuario.length <= 2) {
      data.errorUsuario = lang.mustBeAtLeast3Characters;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    if (data.usuario.length > 30) {
      data.errorUsuario = lang.mustHaveaMaximumOf30Characters;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    const noValido = /[a-z]{2}/;
    const replaceCaracter = data.usuario
      .replace(/[^a-z0-9\s]/gi, '')
      .replace(/[_\s0-9]/g, '');
    if (!noValido.test(replaceCaracter)) {
      data.errorUsuario = lang.youMustHaveAtLeastTwoLetters;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    if (!data.password) {
      data.errorPassword = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    if (data.password.length <= 7) {
      data.errorPassword = lang.thePasswordMustBeAtLeast8;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    if (data.password.length > 20) {
      data.errorPassword = lang.thePasswordMustHaveAMaximumOf;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    const regex = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,20}$/;
    if (!regex.test(data.password)) {
      data.errorPassword = lang.thePasswordMustBeBetween8And20;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    return true;
  };

  handleClickOrganizationTwo = () => {
    const form = JSON.stringify(this.props.formChange);
    const formParse = JSON.parse(form);
    const validate = this.validateFormTwoOrganization(formParse);
    if (validate) {
      this.setState({
        scene: 'organizacionStep3',
      });
    }
  };

  validateFormTwoOrganization = data => {
    if (!data) {
      return false;
    }
    if (!data.nameOrganitation) {
      data.errorNameOrganitation = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (!data.country) {
      data.errorCountry = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (!data.state) {
      data.errorState = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (!data.address) {
      data.errorAddres = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (data.address.length >= 20) {
      data.errorAddres = lang.theAddressCannotHaveMoreThan20Characters;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (!data.zidCode) {
      data.errorZidCode = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (data.zidCode.length < 4) {
      data.errorZidCode = lang.theFieldMustHaveAMinimumOf4Characters;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (data.zidCode.length > 6) {
      data.errorZidCode = lang.theFieldMustHaveAMaximumOf6Characters;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    const noValido = /[0-9]/;
    if (!noValido.test(data.zidCode)) {
      data.errorZidCode = lang.onlyNumbersAreAllowed;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    return true;
  };

  handleClickOrganizationTree = () => {
    const form = JSON.stringify(this.props.formChange);
    const formParse = JSON.parse(form);
    const validate = this.validateFormTreeOrganization(formParse);
    if (validate) {
      this.setState({
        load: true,
        errorMessage: '',
      });
      let names = [];
      const valueNameContact = this.props.formChange.nameContact.split(' ');
      if (valueNameContact.length >= 2 || valueNameContact.length >= 1) {
        names = valueNameContact;
      }
      let categories = [];
      this.props.categories.forEach(item => {
        if (item.parentId === null) {
          categories.push(item.id);
        }
      });
      const data = {
        abouthMe:
          'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator',
        aceptedPrivacyVersion: '1.0',
        aceptedTermsVersion: '1.1',
        activeDate: `${moment().format('YYYY-MM-DD')}T${moment(
          new Date(),
        ).format('HH:mm:ss')}.485Z`,
        address: this.props.formChange.address,
        companyName: this.props.formChange.nameOrganitation,
        contactName: {
          first: names && names[0] ? names[0] : 'null',
          last: names && names[1] ? names[1] : 'null',
        },
        idCategories: categories,
        displayName: this.props.formChange.usuario,
        email: this.props.formChange.email,
        idCompany: this.props.formChange.idOrganization,
        idCountry: this.props.formChange.country,
        idState: this.props.formChange.state,
        idUserType: 2,
        idUserSubType: String(this.props.formChange.select),
        inactiveDate: null,
        language: 'es',
        lastLocation: {
          latitude: 0,
          longitude: 0,
        },
        phoneNumber: this.props.formChange.phone,
        privateAccount: false,
        quantityEmployees: this.props.formChange.numberEmployee,
        quantityFollowers: 0,
        quantityFollowing: 0,
        uidCoach: 'false',
        userAdditionalFunction: [
          {
            status: this.props.formChange.usuarioCouch !== '' ? true : false,
          },
        ],
        userLevel: 0,
        yearCreation: moment(
          this.props.formChange.createDate,
          'DD-MM-YYYY',
        ).format('YYYY'),
        zipCode: this.props.formChange.zidCode,
        userName: this.props.formChange.usuario,
        pwd: this.props.formChange.password,
      };
      if (this.props.dataGoogle && this.props.dataGoogle.photoURL) {
        data.coverPicture = {
          highQuality: this.props.dataGoogle.photoURL,
        };
      }
      if (this.props.dataGoogle && this.props.dataGoogle.photoURL) {
        data.profilePicture = {
          highQuality: this.props.dataGoogle.photoURL,
        };
      }
      this.props.registerProviderCompany(data, Response => {
        if (Response.code >= 200 && Response.code <= 299) {
          firebase
            .auth()
            .signInWithEmailAndPassword(
              Response.data.responseInfo.email,
              this.props.formChange.password,
            )
            .then(result => {
              const data = {
                token: result.user.ra,
                uid: result.user.uid,
              };
              localStorage.setItem('token_get_get_it', result.user.ra);
              this.props.postLogin(data, resultData => {
                if (resultData.code >= 200 && resultData.code <= 299) {
                  this.props.loginInit(resultData.data, false);
                  this.props.history.push('/dashboard');
                } else {
                  this.setState({
                    errorMessage: resultData.message,
                    load: false,
                  });
                }
              });
            })
            .catch(err => {
              this.setState({
                errorMessage: err.message,
                load: false,
              });
            });
        } else if (Response.data.responseInfo !== '') {
          if (
            'UserCreateException: Duplicate user email' ===
            Response.data.responseMsg
          ) {
            this.setState({
              scene: 'organizacionStep1',
            });
            this.setState({
              scene: 'organizacionStep1',
            });
            const form = JSON.stringify(this.props.formChange);
            const formParse = JSON.parse(form);
            formParse.errorEmail = lang.theEmailIsDuplicated;
            const errors = {
              ...formParse,
            };
            this.props.applicationForm(errors);
          }
          if (
            'UserCreateException: Duplicate Username' ===
            Response.data.responseMsg
          ) {
            this.setState({
              scene: 'organizacionStep1',
            });
            const form = JSON.stringify(this.props.formChange);
            const formParse = JSON.parse(form);
            formParse.errorUsuario = lang.theUserIsDuplicated;
            const errors = {
              ...formParse,
            };
            this.props.applicationForm(errors);
          }
          this.setState({
            load: false,
          });
        } else {
          this.setState({
            errorMessage: Response.message,
            load: false,
          });
        }
      });
    }
  };

  validateFormTreeOrganization = data => {
    if (!data) {
      return false;
    }
    if (data.createDate === lang.creationDate) {
      data.errorCreateDate = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (moment().diff(moment(data.createDate, 'DD-MM-YYYY'), 'days') < 0) {
      data.errorCreateDate = lang.theCreationDateMustBeLessThanTheCurrentOne;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (!data.idOrganization) {
      data.errorIdOrganization = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    const re = /^([VEJPGvejpg]{1})([0-9]{7,9})([0-9]{1}$)/g;
    if (!re.test(String(data.idOrganization).toLowerCase())) {
      data.errorIdOrganization = lang.iDIsIncorrect;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (!data.phone) {
      data.errorPhone = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    const regEx = /^\+?\d*$/;
    if (data.phone.match(regEx)) {
      if (data.phone.length < 9) {
        data.errorPhone = lang.thePhoneFieldMustHaveAMinimumOf11Characters;
        let errors = {
          ...data,
        };
        this.props.applicationForm(errors, false, '');
        return false;
      } else {
        data.errorPhone = '';
      }
    } else {
      data.errorPhone = lang.enterAValidPhoneNumber;
      let errors = {
        ...data,
      };
      this.props.applicationForm(errors, false, '');
      return false;
    }
    const regex = /\w+\s+\w+/;
    if (!data.nameContact) {
      data.errorNameContact = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (!regex.test(data.nameContact)) {
      data.errorNameContact = lang.mustContainNameAndSurname;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (!data.numberEmployee) {
      data.errorNumberEmployee = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    if (data.usuarioCouch !== '') {
      const patt = new RegExp(/[a-zA]\d{1,3}/);
      const res = patt.test(data.usuarioCouch);
      if (!res) {
        data.errorUsuarioCouch =
          lang.theFieldMustStartAtLeastInOneLetterAndHaveANumber;
        const errors = {
          ...data,
        };
        this.props.applicationForm(errors);
        return false;
      }
    }
    return true;
  };

  handleChangeEmployee = (value, id) => () => {
    const onChangeForm = JSON.stringify(this.props.formChange);
    const parseChangeForm = JSON.parse(onChangeForm);
    parseChangeForm['numberEmployee'] = value;
    parseChangeForm[`idSelectNumberEmploye`] = id;
    parseChangeForm['errorNumberEmployee'] = '';
    const obj = {
      ...parseChangeForm,
    };
    this.handleApplicationForm(obj);
  };

  handleChangePhone = (onChange, prop, event) => {
    onChange[prop] = event;
    if (prop === 'phone') {
      if (event.charAt(0) === '+') {
        if (!(event.length < 14)) {
          onChange[prop] = this.props.formChange.phone;
          onChange['errorPhone'] = '';
        }
      } else if (!(event.length < 12)) {
        onChange[prop] = this.props.formChange.phone;
        onChange['errorPhone'] = '';
      }
    } else if (event.charAt(0) === '+') {
      if (!(event.length < 14)) {
        onChange[prop] = this.props.formChange.phoneFamily;
        onChange['errorPhone'] = '';
      }
    } else if (!(event.length < 12)) {
      onChange[prop] = this.props.formChange.phoneFamily;
      onChange['errorPhone'] = '';
    }
    onChange['errorPhone'] = '';
    const obj = {
      ...onChange,
    };
    this.handleApplicationForm(obj);
  };

  handleClickParticular = () => {
    const form = JSON.stringify(this.props.formChange);
    const formParse = JSON.parse(form);
    const validate = this.validateParticularOne(formParse);
    if (validate) {
      this.setState({
        loadingValidate: true,
        errorValidate: '',
      });
      const data = {
        userEmail: this.props.formChange.email,
      };
      this.props.validateExistEmail(
        this.props.formChange.email,
        data,
        result => {
          if (result.code >= 200 && result.code <= 299) {
            if (result.data.responseInfo) {
              const form = JSON.stringify(this.props.formChange);
              const formParse = JSON.parse(form);
              formParse.errorEmail = lang.theEmailIsDuplicated;
              const errors = {
                ...formParse,
              };
              this.setState({
                loadingValidate: false,
              });
              this.props.applicationForm(errors);
            } else {
              this.props.getValidateUserExist(
                this.props.formChange.usuario,
                Response => {
                  if (Response.code >= 200 && Response.code <= 299) {
                    if (Response.data.responseMsg === 'User Exist') {
                      this.setState({
                        loadingValidate: false,
                      });
                      const form = JSON.stringify(this.props.formChange);
                      const formParse = JSON.parse(form);
                      formParse.errorUsuario = lang.theUserIsDuplicated;
                      const errors = {
                        ...formParse,
                      };
                      this.props.applicationForm(errors);
                    } else {
                      this.setState({
                        scene: 'particularStep2',
                        loadingValidate: false,
                      });
                    }
                  } else {
                    this.setState({
                      errorValidate: result.message,
                      loadingValidate: false,
                    });
                  }
                },
              );
            }
          } else {
            this.setState({
              errorValidate: result.message,
              loadingValidate: false,
            });
          }
        },
      );
    }
  };

  validateParticularOne = data => {
    if (!data) {
      return false;
    }

    if (!data.select) {
      data.errorSelect = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    if (!data.email) {
      data.errorEmail = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    if (data.email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(data.email).toLowerCase())) {
        data.errorEmail = lang.enterValidEmailAddress;
        const errors = {
          ...data,
        };
        this.props.applicationForm(errors);
        return false;
      }
    }
    if (!data.usuario) {
      data.errorUsuario = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    if (data.usuario.length <= 2) {
      data.errorUsuario = lang.mustBeAtLeast3Characters;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    if (data.usuario.length > 30) {
      data.errorUsuario = lang.mustHaveaMaximumOf30Characters;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    const noValido = /[a-z]{2}/;
    if (!noValido.test(data.usuario)) {
      data.errorUsuario = lang.youMustHaveAtLeastTwoLetters;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    if (!data.password) {
      data.errorPassword = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    if (data.password.length <= 7) {
      data.errorPassword = lang.thePasswordMustBeAtLeast8;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    if (data.password.length > 20) {
      data.errorPassword = lang.thePasswordMustHaveAMaximumOf;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    return true;
  };

  handleClickParticularTwo = () => {
    const form = JSON.stringify(this.props.formChange);
    const formParse = JSON.parse(form);
    const validate = this.validateParticularTwo(formParse);
    if (validate) {
      this.setState({
        scene: 'particularStep3',
      });
    }
  };

  validateParticularTwo = data => {
    if (!data) {
      return false;
    }
    if (!data.firstName) {
      data.errorFirstName = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (data.firstName.length < 1) {
      data.errorFirstName = lang.mustBeAtLeast2Characters;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (data.firstName.length > 15) {
      data.errorFirstName = lang.mustHaveaMaximumOf15Characters;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (!data.lastName) {
      data.errorLastName = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (data.lastName.length < 1) {
      data.errorLastName = lang.mustBeAtLeast2Characters;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (data.lastName.length > 15) {
      data.errorLastName = lang.mustHaveaMaximumOf15Characters;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (!data.birthday) {
      data.errorBirthday = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    const validateAdult = data.birthday.substr(6, 10000);
    const calculo =
      moment(new Date())
        .format('DD-MM-YYYY')
        .substr(6, 10000) - validateAdult;
    if (calculo < 18) {
      data.errorBirthday = lang.youMustBeOfLegalAge;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    if (!data.gender) {
      data.errorGender = lang.youMustSelectTheGender;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    return true;
  };

  handleGender = event => {
    let value = '';
    this.props.gender.forEach(element => {
      element.gender.forEach(gender => {
        if (gender.description === event.target.value) {
          value = element.id;
        }
      });
    });
    const onChangeForm = JSON.stringify(this.props.formChange);
    const parseChangeForm = JSON.parse(onChangeForm);
    parseChangeForm['gender'] = value;
    parseChangeForm['errorGender'] = '';
    const obj = {
      ...parseChangeForm,
    };
    this.handleApplicationForm(obj);
  };

  handleClickParticularTree = () => {
    const form = JSON.stringify(this.props.formChange);
    const formParse = JSON.parse(form);
    const validate = this.validateParticularTree(formParse);
    if (validate) {
      this.setState({
        load: true,
        errorMessage: '',
      });
      let categories = [];
      this.props.categories.forEach(item => {
        if (item.parentId === null) {
          categories.push(item.id);
        }
      });
      const data = {
        abouthMe:
          'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator',
        aceptedPrivacyVersion: '1.0',
        aceptedTermsVersion: '1.1',
        activeDate:
          moment(new Date()).format('YYYY-MM-DD') +
          'T' +
          moment(new Date()).format('HH:mm:ss') +
          '.489Z',
        birthDate: `${moment(
          this.props.formChange.birthday,
          'DD-MM-YYYY',
        ).format('YYYY-MM-DD')}`,
        idCategories: categories,
        name: {
          first: this.props.formChange.firstName,
          last: this.props.formChange.lastName,
        },
        displayName: this.props.formChange.usuario,
        email: this.props.formChange.email,
        idGender: this.props.formChange.gender,
        idCountry: this.props.formChange.country,
        idState: this.props.formChange.state,
        idUserSubType: String(this.props.formChange.select),
        idUserType: 1,
        language: 'es',
        lastLocation: {
          latitude: 0,
          longitude: 0,
        },
        phoneNumber: this.props.formChange.phone,
        privateAccount: false,
        quantityFollowers: 0,
        quantityFollowing: 0,
        userAdditionalFunction: [
          {
            status: this.props.formChange.usuarioCouch !== '' ? true : false,
          },
        ],
        userLevel: 0,
        uidCoach: 'false',
        zipCode: this.props.formChange.zidCode,
        userName: this.props.formChange.usuario,
        pwd: this.props.formChange.password,
        userProviderLevel: 0,
      };
      if (this.props.dataGoogle && this.props.dataGoogle.photoURL) {
        data.coverPicture = {
          highQuality: this.props.dataGoogle.photoURL,
        };
      }
      if (this.props.dataGoogle && this.props.dataGoogle.photoURL) {
        data.profilePicture = {
          highQuality: this.props.dataGoogle.photoURL,
        };
      }
      this.props.registerProviderPerson(data, Response => {
        if (Response.code >= 200 && Response.code <= 299) {
          firebase
            .auth()
            .signInWithEmailAndPassword(
              Response.data.responseInfo.email,
              this.props.formChange.password,
            )
            .then(result => {
              const data = {
                token: result.user.ra,
                uid: result.user.uid,
              };
              localStorage.setItem('token_get_get_it', result.user.ra);
              this.props.postLogin(data, resultData => {
                if (resultData.code >= 200 && resultData.code <= 299) {
                  this.props.loginInit(resultData.data, false);
                  this.props.history.push('/dashboard');
                } else {
                  this.setState({
                    errorMessage: resultData.message,
                    load: false,
                  });
                }
              });
            })
            .catch(err => {
              this.setState({
                errorMessage: err.message,
                load: false,
              });
            });
        } else if (Response.data.responseInfo !== '') {
          if (
            'UserCreateException: Duplicate user email' ===
            Response.data.responseMsg
          ) {
            this.setState({
              scene: 'particularStep1',
            });
            const form = JSON.stringify(this.props.formChange);
            const formParse = JSON.parse(form);
            formParse.errorEmail = lang.theEmailIsDuplicated;
            const errors = {
              ...formParse,
            };
            this.props.applicationForm(errors);
          }
          if (
            'UserCreateException: Duplicate Username' ===
            Response.data.responseMsg
          ) {
            this.setState({
              scene: 'particularStep1',
            });
            const form = JSON.stringify(this.props.formChange);
            const formParse = JSON.parse(form);
            formParse.errorUsuario = lang.theUserIsDuplicated;
            const errors = {
              ...formParse,
            };
            this.props.applicationForm(errors);
          }
          this.setState({
            load: false,
          });
        } else {
          this.setState({
            errorMessage: Response.message,
            load: false,
          });
        }
      });
    }
  };

  validateParticularTree = data => {
    if (!data) {
      return false;
    }
    if (!data.country) {
      data.errorCountry = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (!data.state) {
      data.errorState = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
    }

    if (!data.phone) {
      data.errorPhone = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }

    const regEx = /^\+?\d*$/;
    if (data.phone.match(regEx)) {
      if (data.phone.length < 9) {
        data.errorPhone = lang.thePhoneFieldMustHaveAMinimumOf11Characters;
        let errors = {
          ...data,
        };
        this.props.applicationForm(errors, false, '');
        return false;
      } else {
        data.errorPhone = '';
      }
    } else {
      data.errorPhone = lang.enterAValidPhoneNumber;
      let errors = {
        ...data,
      };
      this.props.applicationForm(errors, false, '');
      return false;
    }

    if (!data.zidCode) {
      data.errorZidCode = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (data.usuarioCouch !== '') {
      const patt = new RegExp(/[a-zA]\d{1,3}/);
      const res = patt.test(data.usuarioCouch);
      if (!res) {
        data.errorUsuarioCouch =
          lang.theFieldMustStartAtLeastInOneLetterAndHaveANumber;
        const errors = {
          ...data,
        };
        this.props.applicationForm(errors);
        return false;
      }
    }
    return true;
  };

  handleLoginGoogle = () => {
    this.props.loginGoogle({}, true);
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(Response => {
        this.props.checkEmailAvailability(Response.user.email, result => {
          if (result.code >= 200 && result.code <= 299) {
            if (result.data.responseInfo) {
              const data = {
                token: Response.user.ra,
                uid: Response.user.uid,
              };
              this.props.getUserId(data, Response => {
                Response.data.responseInfo = {
                  user: Response.data.responseInfo,
                };
                if (
                  Response.data.responseInfo.user.idUserType === 1 ||
                  Response.data.responseInfo.user.idUserType === 2
                ) {
                  localStorage.setItem('token_get_get_it', data.token);
                  this.props.loginGoogle({}, false);
                  this.props.loginInit(Response.data, false);
                  this.props.history.push('/dashboard');
                } else {
                  this.props.errorLoginGoogle(
                    lang.exclusiveIncomeForSuppliers,
                    false,
                    {},
                  );
                }
              });
            } else {
              const emailLength = Response.user.email.indexOf('@');
              const jsonForm = JSON.stringify(this.props.formChange);
              const parseForm = JSON.parse(jsonForm);
              parseForm.email = Response.user.email;
              parseForm.password = '12345678';
              parseForm.usuario = Response.user.email.substr(0, emailLength);
              parseForm.uid = Response.user.uid;
              this.props.applicationForm(parseForm);
              const user = {
                displayName: Response.user.displayName,
                email: Response.user.email,
                photoURL: Response.user.photoURL,
                phoneNumber: Response.user.phoneNumber,
                providerData: Response.user.providerData,
                uid: Response.user.uid,
                usuario: Response.user.email.substr(0, emailLength),
                password: '12345678',
              };
              this.props.loginGoogle(user, false);
            }
          } else {
            this.props.errorLoginGoogle(result.message, false, {});
          }
        });
      })
      .catch(err => {
        this.props.errorLoginGoogle('', false, {});
      });
  };

  handleSelectCategories = item => {
    const onChangeForm = JSON.stringify(this.props.formChange);
    let parseChangeForm = JSON.parse(onChangeForm);
    parseChangeForm['select'] = item.value;
    parseChangeForm['errorSelect'] = '';
    const obj = {
      ...parseChangeForm,
    };
    this.setState({
      nameSelect: item.name,
    });
    this.handleApplicationForm(obj);
  };

  handleSelectCountry = item => {
    this.setState({
      nameCountry: item.name,
    });
    const onChangeForm = JSON.stringify(this.props.formChange);
    const parseChangeForm = JSON.parse(onChangeForm);
    parseChangeForm.country = item.id;
    parseChangeForm.errorCountry = '';
    parseChangeForm[`state`] = '';
    this.props.countries.forEach(element => {
      if (element.id === item.id) {
        this.props.getState(element.states);
      }
    });
    const obj = {
      ...parseChangeForm,
    };
    this.handleApplicationForm(obj);
  };

  handleSelectState = item => {
    this.setState({
      nameState: item.name,
    });
    const onChangeForm = JSON.stringify(this.props.formChange);
    const parseChangeForm = JSON.parse(onChangeForm);
    parseChangeForm.state = item.id;
    parseChangeForm.errorState = '';
    const obj = {
      ...parseChangeForm,
    };
    this.handleApplicationForm(obj);
  };

  justifyPhone = e => {
    let key = e.keyCode || e.which;
    return /^\+?\d*$/.test(String.fromCharCode(key));
  };

  onChangeNumber = e => {
    let key = e.keyCode || e.which;
    return /^[0-9]+$/.test(String.fromCharCode(key));
  };

  onChangeletters = e => {
    let key = e.keyCode || e.which;
    return /^[a-zA-záéíóúabcdefghijklmnñopqrstuvwxyz]+$/.test(
      String.fromCharCode(key),
    );
  };

  onChangelettersUser = e => {
    let key = e.keyCode || e.which;
    return /^[a-z0-9.-]+$/.test(String.fromCharCode(key));
  };

  onChangelettersAndNumber = e => {
    let key = e.keyCode || e.which;
    return /^[a-zabcdefghijklmnopqrstuvwxyz0-9.-]+$/.test(
      String.fromCharCode(key),
    );
  };

  onChangelettersOfSpace = e => {
    let key = e.keyCode || e.which;
    return /^[a-zA-záéíóúabcdefghijklmnñopqrstuvwxyz ]+$/.test(
      String.fromCharCode(key),
    );
  };

  onChangelettersCaracterNumber = e => {
    let key = e.keyCode || e.which;
    return /^[ a-zA-záéíóúabcdefghijklmnñopqrstuvwxyz ,./: 0-9]+$/.test(
      String.fromCharCode(key),
    );
  };

  onChangeValidateRif = e => {
    let key = e.keyCode || e.which;
    return /^[VEJPGvejpg - 0-9]+$/.test(String.fromCharCode(key));
  };

  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Background>
          <HeaderLogo src="/assets/images/logo-small.svg" alt="logo" />
          <div className="total-center" style={{ height: '70vh' }}>
            <FormWrap>
              <div className="total-center">
                <img
                  src="/assets/images/logo.svg"
                  alt="logo"
                  width="auto"
                  height="35px"
                />
              </div>
              {/* ------------------------------ organizacion  ------------------------------ */}
              {this.state.scene === 'organizacionStep1' ? (
                <div>
                  <div style={{ minHeight: '435px' }}>
                    <AlignInfo>
                      <ColumnWrap justifyItems="center">
                        <RadioButton
                          label={lang.particular}
                          onChange={this.onChangeData}
                          value={'particularStep1'}
                        />
                        <RadioButton
                          label={lang.organization}
                          onChange={this.onChangeData}
                          value={'organizacionStep1'}
                          defaultChecked={true}
                        />
                      </ColumnWrap>
                      <div style={{ width: '100%' }}>
                        <Tooltip
                          error={
                            this.props.formChange &&
                            this.props.formChange.errorSelect
                          }
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                          }}
                          message={
                            this.props.formChange &&
                            this.props.formChange.errorSelect
                          }
                        />
                        <DropdownList
                          data={[
                            { name: lang.private, value: 1 },
                            { name: lang.public, value: 2 },
                            { name: lang.ong, value: 3 },
                          ]}
                          textField={'name'}
                          onChange={this.handleSelectCategories}
                          value={this.state.nameSelect}
                          // placeholder="Categorias"
                        />
                      </div>
                      <div>
                        <Input
                          placeholder={lang.email}
                          value={
                            this.props.formChange && this.props.formChange.email
                              ? this.props.formChange.email
                              : ''
                          }
                          onChange={this.handleOnchange('email', 'errorEmail')}
                          error={
                            this.props.formChange &&
                            this.props.formChange.errorEmail
                              ? true
                              : false
                          }
                          message={
                            this.props.formChange &&
                            this.props.formChange.errorEmail
                              ? this.props.formChange.errorEmail
                              : ''
                          }
                        />
                      </div>
                      <div>
                        <Input
                          placeholder={lang.user}
                          value={
                            this.props.formChange &&
                            this.props.formChange.usuario
                              ? this.props.formChange.usuario
                              : ''
                          }
                          onChange={this.handleOnchange(
                            'usuario',
                            'errorUsuario',
                          )}
                          error={
                            this.props.formChange &&
                            this.props.formChange.errorUsuario
                              ? true
                              : false
                          }
                          message={
                            this.props.formChange &&
                            this.props.formChange.errorUsuario
                              ? this.props.formChange.errorUsuario
                              : ''
                          }
                          onKeyPress={e => {
                            if (this.onChangelettersAndNumber(e)) {
                            } else {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                      {this.props.dataGoogle &&
                      this.props.dataGoogle.password ? null : (
                        <div>
                          <InputPass
                            placeholder={lang.password}
                            value={
                              this.props.formChange &&
                              this.props.formChange.password
                                ? this.props.formChange.password
                                : ''
                            }
                            onChange={this.handleOnchange(
                              'password',
                              'errorPassword',
                            )}
                            type="password"
                            error={
                              this.props.formChange &&
                              this.props.formChange.errorPassword
                                ? true
                                : false
                            }
                            message={
                              this.props.formChange &&
                              this.props.formChange.errorPassword
                                ? this.props.formChange.errorPassword
                                : ''
                            }
                          />
                        </div>
                      )}
                      {this.state.errorValidate ? (
                        <div>
                          <p style={{ color: 'red' }}>
                            {this.state.errorValidate}
                          </p>
                        </div>
                      ) : null}
                      {this.state.loadingValidate ? (
                        <div>
                          <CircularProgress />
                        </div>
                      ) : (
                        <Button onClick={this.handleClickOrganization}>
                          {lang.next}
                        </Button>
                      )}
                    </AlignInfo>
                    <div
                      className="total-center"
                      style={{ width: '100%', marginBottom: 15 }}
                    >
                      <Divider />
                      <div style={{ width: '200px' }}>
                        <Text
                          fontSize="15px"
                          color="#FFF"
                          textAlign="center"
                          width="150px"
                        >
                          {lang.loginWith}
                        </Text>
                      </div>
                      <Divider />
                    </div>
                    {this.props.loadingGoogle ? (
                      <div
                        className="total-center"
                        style={{ marginTop: '10px' }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <ButtonLoader
                        icon
                        backgroundColor="#d90458"
                        maxWidth="370px"
                        marginTop="20px"
                        onClick={this.handleLoginGoogle}
                      >
                        {lang.google}
                      </ButtonLoader>
                    )}
                    {this.props.errorGoogle ? (
                      <div
                        className="total-center"
                        style={{ marginTop: '10px' }}
                      >
                        <p className="error-message">
                          {this.props.errorGoogle}
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div className="total-center">
                    <SteppersDots step1 />
                  </div>
                </div>
              ) : null}
              {this.state.scene === 'organizacionStep2' ? (
                <div>
                  <div style={{ minHeight: '435px' }}>
                    <ArrowBack
                      onClick={() =>
                        this.setState({ scene: 'organizacionStep1' })
                      }
                    />
                    <AlignInfo>
                      <div>
                        <Input
                          placeholder={lang.organizationName}
                          onChange={this.handleOnchange(
                            'nameOrganitation',
                            'errorNameOrganitation',
                          )}
                          value={
                            this.props.formChange &&
                            this.props.formChange.nameOrganitation
                              ? this.props.formChange.nameOrganitation
                              : ''
                          }
                          onKeyPress={e => {
                            if (this.onChangelettersCaracterNumber(e)) {
                            } else {
                              e.preventDefault();
                            }
                          }}
                          error={
                            this.props.formChange &&
                            this.props.formChange.errorNameOrganitation
                              ? true
                              : false
                          }
                          message={
                            this.props.formChange &&
                            this.props.formChange.errorNameOrganitation
                          }
                        />
                      </div>
                      <div style={{ width: '100%' }}>
                        <Tooltip
                          error={
                            this.props.formChange &&
                            this.props.formChange.errorCountry
                              ? true
                              : false
                          }
                          message={
                            this.props.formChange &&
                            this.props.formChange.errorCountry
                          }
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                          }}
                        />
                        <DropdownList
                          data={this.props.countries}
                          textField={'name'}
                          onChange={this.handleSelectCountry}
                          value={this.state.nameCountry}
                          placeholder={lang.country}
                        />
                      </div>
                      <div style={{ width: '100%' }}>
                        <Tooltip
                          error={
                            this.props.formChange &&
                            this.props.formChange.errorState
                              ? true
                              : false
                          }
                          message={
                            this.props.formChange &&
                            this.props.formChange.errorState
                          }
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                          }}
                        />
                        <DropdownList
                          data={this.props.states}
                          textField={'name'}
                          onChange={this.handleSelectState}
                          value={this.state.nameState}
                          placeholder={lang.city}
                        />
                      </div>
                      <div>
                        <Tooltip
                          error={
                            this.props.formChange &&
                            this.props.formChange.errorAddres
                              ? true
                              : false
                          }
                          message={
                            this.props.formChange &&
                            this.props.formChange.errorAddres
                          }
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                          }}
                        />
                        <Textarea
                          placeholder={lang.address}
                          onChange={this.handleOnchange(
                            'address',
                            'errorAddres',
                          )}
                          value={
                            this.props.formChange &&
                            this.props.formChange.address
                              ? this.props.formChange.address
                              : ''
                          }
                        />
                      </div>
                      <div>
                        <Input
                          placeholder={lang.zipCode}
                          onChange={this.handleOnchange(
                            'zidCode',
                            'errorZidCode',
                          )}
                          value={
                            this.props.formChange &&
                            this.props.formChange.zidCode
                              ? this.props.formChange.zidCode
                              : ''
                          }
                          onKeyPress={e => {
                            if (this.onChangeNumber(e)) {
                            } else {
                              e.preventDefault();
                            }
                          }}
                          error={
                            this.props.formChange &&
                            this.props.formChange.errorZidCode
                              ? true
                              : false
                          }
                          message={
                            this.props.formChange &&
                            this.props.formChange.errorZidCode
                          }
                        />
                      </div>
                      <Button onClick={this.handleClickOrganizationTwo}>
                        Siguiente
                      </Button>
                    </AlignInfo>
                  </div>
                </div>
              ) : null}
              {this.state.scene === 'organizacionStep3' ? (
                <div>
                  {this.state.message ? (
                    <div style={{ minHeight: '400px' }}>
                      <div
                        className="total-center"
                        style={{ minHeight: '300px' }}
                      >
                        <p
                          style={{
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '18px',
                          }}
                        >
                          {this.state.message}
                        </p>
                      </div>
                      <div
                        className="total-center"
                        style={{ minHeight: '100px' }}
                      >
                        <Button onClick={() => this.props.history.push('/')}>
                          {lang.next}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div style={{ minHeight: '435px' }}>
                      <ArrowBack
                        onClick={() =>
                          this.setState({ scene: 'organizacionStep2' })
                        }
                      />
                      <AlignInfo>
                        <div>
                          <Input
                            placeholder={
                              this.props.formChange &&
                              this.props.formChange.createDate
                                ? this.props.formChange.createDate
                                : ''
                            }
                            onClick={() =>
                              document.getElementById('datePicker').click()
                            }
                            error={
                              this.props.formChange &&
                              this.props.formChange.errorCreateDate
                                ? true
                                : false
                            }
                            message={
                              this.props.formChange &&
                              this.props.formChange.errorCreateDate
                            }
                          />
                        </div>
                        <DatePicker
                          label="Basic example"
                          value={
                            this.props.formChange &&
                            this.props.formChange.createDate
                              ? this.props.formChange.createDate
                              : ''
                          }
                          onChange={this.handleDateChange}
                          animateYearScrolling
                          id="datePicker"
                          style={{ display: 'none' }}
                        />
                        <div>
                          <Input
                            placeholder={lang.organizationIdExample}
                            onChange={this.handleOnchange(
                              'idOrganization',
                              'errorIdOrganization',
                            )}
                            value={
                              this.props.formChange &&
                              this.props.formChange.idOrganization
                                ? this.props.formChange.idOrganization
                                : ''
                            }
                            error={
                              this.props.formChange &&
                              this.props.formChange.errorIdOrganization
                                ? true
                                : false
                            }
                            message={
                              this.props.formChange &&
                              this.props.formChange.errorIdOrganization
                            }
                          />
                        </div>
                        <div>
                          <Input
                            placeholder={lang.phone}
                            onChange={this.handleOnchange(
                              'phone',
                              'errorPhone',
                            )}
                            value={
                              this.props.formChange &&
                              this.props.formChange.phone
                                ? this.props.formChange.phone
                                : ''
                            }
                            onKeyPress={e => {
                              if (this.justifyPhone(e)) {
                              } else {
                                e.preventDefault();
                              }
                            }}
                            error={
                              this.props.formChange &&
                              this.props.formChange.errorPhone
                                ? true
                                : false
                            }
                            message={
                              this.props.formChange &&
                              this.props.formChange.errorPhone
                            }
                          />
                        </div>
                        <div>
                          <Input
                            placeholder={lang.nameContact}
                            onChange={this.handleOnchange(
                              'nameContact',
                              'errorNameContact',
                            )}
                            value={
                              this.props.formChange &&
                              this.props.formChange.nameContact
                                ? this.props.formChange.nameContact
                                : ''
                            }
                            onKeyPress={e => {
                              if (this.onChangelettersOfSpace(e)) {
                              } else {
                                e.preventDefault();
                              }
                            }}
                            error={
                              this.props.formChange &&
                              this.props.formChange.errorNameContact
                                ? true
                                : false
                            }
                            message={
                              this.props.formChange &&
                              this.props.formChange.errorNameContact
                            }
                          />
                        </div>
                        <div>
                          <Tooltip
                            error={
                              this.props.formChange &&
                              this.props.formChange.errorNumberEmployee
                                ? true
                                : false
                            }
                            message={
                              this.props.formChange &&
                              this.props.formChange.errorNumberEmployee
                            }
                            style={{
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'center',
                            }}
                          />
                          <Text
                            fontSize="15px"
                            color="#FFF"
                            textAlign="left"
                            width="100%"
                            maxWidth="370px"
                            fontFamily="Roboto"
                          >
                            {lang.numberOfEmployees}
                          </Text>
                          <ColumnWrap
                            col="1fr 1fr 1fr 1fr 1fr "
                            style={{ marginTop: '10px' }}
                          >
                            <ButtonNumber
                              onClick={this.handleChangeEmployee('1 - 10', 0)}
                              style={
                                this.props.formChange &&
                                this.props.formChange.idSelectNumberEmploye ===
                                  0
                                  ? {
                                      backgroundColor: '#66bbff',
                                    }
                                  : { backgroundColor: 'transparent' }
                              }
                            >
                              1 - 10
                            </ButtonNumber>
                            <ButtonNumber
                              onClick={this.handleChangeEmployee('11 - 20', 1)}
                              style={
                                this.props.formChange &&
                                this.props.formChange.idSelectNumberEmploye ===
                                  1
                                  ? {
                                      backgroundColor: '#66bbff',
                                    }
                                  : { backgroundColor: 'transparent' }
                              }
                            >
                              11 - 20
                            </ButtonNumber>
                            <ButtonNumber
                              onClick={this.handleChangeEmployee('21 - 50', 2)}
                              style={
                                this.props.formChange &&
                                this.props.formChange.idSelectNumberEmploye ===
                                  2
                                  ? {
                                      backgroundColor: '#66bbff',
                                    }
                                  : { backgroundColor: 'transparent' }
                              }
                            >
                              21 - 50
                            </ButtonNumber>
                            <ButtonNumber
                              onClick={this.handleChangeEmployee('51 - 100', 3)}
                              style={
                                this.props.formChange &&
                                this.props.formChange.idSelectNumberEmploye ===
                                  3
                                  ? {
                                      backgroundColor: '#66bbff',
                                    }
                                  : { backgroundColor: 'transparent' }
                              }
                            >
                              51 - 100
                            </ButtonNumber>
                            <ButtonNumber
                              onClick={this.handleChangeEmployee('+ 100', 4)}
                              style={
                                this.props.formChange &&
                                this.props.formChange.idSelectNumberEmploye ===
                                  4
                                  ? {
                                      backgroundColor: '#66bbff',
                                    }
                                  : { backgroundColor: 'transparent' }
                              }
                            >
                              {' '}
                              + 100
                            </ButtonNumber>
                          </ColumnWrap>
                        </div>
                        <div>
                          <Input
                            placeholder={lang.coachUser}
                            onChange={this.handleOnchange(
                              'usuarioCouch',
                              'errorUsuarioCouch',
                            )}
                            value={
                              this.props.formChange &&
                              this.props.formChange.usuarioCouch
                                ? this.props.formChange.usuarioCouch
                                : ''
                            }
                            onKeyPress={e => {
                              if (this.onChangelettersCaracterNumber(e)) {
                              } else {
                                e.preventDefault();
                              }
                            }}
                            error={
                              this.props.formChange &&
                              this.props.formChange.errorUsuarioCouch
                                ? true
                                : false
                            }
                            message={
                              this.props.formChange &&
                              this.props.formChange.errorUsuarioCouch
                            }
                          />
                        </div>
                        <Text
                          fontSize="15px"
                          color="#FFF"
                          textAlign="center"
                          width="100%"
                          maxWidth="370px"
                          fontFamily="Roboto"
                        >
                          {lang.byRegisteringYouWillBeAcceptingOur}
                          <br />
                          <span
                            style={{
                              color: '#66BBFF',
                              fontFamily: 'Roboto',
                              fontWeigth: 400,
                            }}
                          >
                            {' '}
                            <a href="https://eiry.app/terminos-y-condiciones/">
                            {lang.conditions}
                            </a>
                          </span>{' '}
                          {lang.and}
                          <span
                            style={{
                              color: '#66BBFF',
                              fontFamily: 'Roboto',
                              fontWeigth: 400,
                            }}
                          >
                            {' '}
                            <a href="https://eiry.app/politicas-de-privacidad/">
                            {lang.privacyPolicies}
                            </a>
                          </span>
                        </Text>
                        {this.state.load ? (
                          <div
                            className="total-center"
                            style={{ marginTop: '10px' }}
                          >
                            <CircularProgress />
                          </div>
                        ) : (
                          <Button onClick={this.handleClickOrganizationTree}>
                            {lang.next}
                          </Button>
                        )}
                        {this.state.errorMessage ? (
                          <div>
                            <p className="error-message">
                              {this.state.errorMessage}
                            </p>
                          </div>
                        ) : null}
                      </AlignInfo>
                    </div>
                  )}
                  <div className="total-center">
                    <SteppersDots step3 />
                  </div>
                </div>
              ) : null}
              {/* ------------------------------ Particular  ------------------------------ */}
              {this.state.scene === 'particularStep1' ? (
                <div>
                  <div style={{ minHeight: '435px' }}>
                    <AlignInfo>
                      <ColumnWrap justifyItems="center">
                        <RadioButton
                          label={lang.particular}
                          onChange={this.onChangeData}
                          value={'particularStep1'}
                          defaultChecked={true}
                        />
                        <RadioButton
                          label={lang.organization}
                          onChange={this.onChangeData}
                          value={'organizacionStep1'}
                        />
                      </ColumnWrap>
                      <div style={{ width: '100%' }}>
                        <Tooltip
                          error={
                            this.props.formChange &&
                            this.props.formChange.errorSelect
                          }
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                          }}
                          message={
                            this.props.formChange &&
                            this.props.formChange.errorSelect
                          }
                        />
                        <DropdownList
                          data={[
                            { name: lang.professional, value: 1 },
                            { name: lang.freelancer, value: 2 },
                            { name: lang.entrepreneur, value: 3 },
                            { name: lang.job, value: 4 },
                          ]}
                          textField={'name'}
                          onChange={this.handleSelectCategories}
                          value={this.state.nameSelect}
                          // placeholder="Categorias"
                        />
                      </div>
                      <Input
                        placeholder={lang.email}
                        value={
                          this.props.formChange && this.props.formChange.email
                            ? this.props.formChange.email
                            : ''
                        }
                        onChange={this.handleOnchange('email', 'errorEmail')}
                        error={
                          this.props.formChange &&
                          this.props.formChange.errorEmail
                        }
                        message={
                          this.props.formChange &&
                          this.props.formChange.errorEmail
                        }
                      />
                      <Input
                        placeholder={lang.user}
                        value={
                          this.props.formChange && this.props.formChange.usuario
                            ? this.props.formChange.usuario
                            : ''
                        }
                        onChange={this.handleOnchange(
                          'usuario',
                          'errorUsuario',
                        )}
                        error={
                          this.props.formChange &&
                          this.props.formChange.errorUsuario
                        }
                        message={
                          this.props.formChange &&
                          this.props.formChange.errorUsuario
                        }
                        onKeyPress={e => {
                          if (this.onChangelettersAndNumber(e)) {
                          } else {
                            e.preventDefault();
                          }
                        }}
                      />
                      {this.props.dataGoogle &&
                      this.props.dataGoogle.password ? null : (
                        <InputPass
                          placeholder={lang.password}
                          value={
                            this.props.formChange &&
                            this.props.formChange.password
                              ? this.props.formChange.password
                              : ''
                          }
                          onChange={this.handleOnchange(
                            'password',
                            'errorPassword',
                          )}
                          error={
                            this.props.formChange &&
                            this.props.formChange.errorPassword
                          }
                          message={
                            this.props.formChange &&
                            this.props.formChange.errorPassword
                          }
                          type={'password'}
                        />
                      )}
                      {this.state.errorValidate ? (
                        <div>
                          <p style={{ color: 'red' }}>
                            {this.state.errorValidate}
                          </p>
                        </div>
                      ) : null}
                      {this.state.loadingValidate ? (
                        <div>
                          <CircularProgress />
                        </div>
                      ) : (
                        <Button onClick={this.handleClickParticular}>
                          {lang.next}
                        </Button>
                      )}
                    </AlignInfo>
                    <div
                      className="total-center"
                      style={{ width: '100%', marginBottom: 15 }}
                    >
                      <Divider />
                      <div style={{ width: '200px' }}>
                        <Text
                          fontSize="15px"
                          color="#FFF"
                          textAlign="center"
                          width="150px"
                        >
                          {lang.logInWhith}
                        </Text>
                      </div>
                      <Divider />
                    </div>
                    {this.props.loadingGoogle ? (
                      <div
                        className="total-center"
                        style={{ marginTop: '10px' }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <ButtonLoader
                        icon
                        backgroundColor="#d90458"
                        maxWidth="370px"
                        marginTop="20px"
                        onClick={this.handleLoginGoogle}
                      >
                        {lang.google}
                      </ButtonLoader>
                    )}
                    {this.props.errorGoogle ? (
                      <div
                        className="total-center"
                        style={{ marginTop: '10px' }}
                      >
                        <p className="error-message">
                          {this.props.errorGoogle}
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div className="total-center">
                    <SteppersDots step1 />
                  </div>
                </div>
              ) : null}
              {this.state.scene === 'particularStep2' ? (
                <div>
                  <div style={{ minHeight: '435px' }}>
                    <ArrowBack
                      onClick={() =>
                        this.setState({ scene: 'particularStep1' })
                      }
                    />
                    <AlignInfo>
                      <Input
                        placeholder={lang.name}
                        value={
                          this.props.formChange &&
                          this.props.formChange.firstName
                            ? this.props.formChange.firstName
                            : ''
                        }
                        onChange={this.handleOnchange(
                          'firstName',
                          'errorFirstName',
                        )}
                        error={
                          this.props.formChange &&
                          this.props.formChange.errorFirstName
                        }
                        message={
                          this.props.formChange &&
                          this.props.formChange.errorFirstName
                        }
                        onKeyPress={e => {
                          if (this.onChangeletters(e)) {
                          } else {
                            e.preventDefault();
                          }
                        }}
                      />
                      <Input
                        placeholder={lang.lastName}
                        value={
                          this.props.formChange &&
                          this.props.formChange.lastName
                            ? this.props.formChange.lastName
                            : ''
                        }
                        onChange={this.handleOnchange(
                          'lastName',
                          'errorLastName',
                        )}
                        error={
                          this.props.formChange &&
                          this.props.formChange.errorLastName
                        }
                        message={
                          this.props.formChange &&
                          this.props.formChange.errorLastName
                        }
                        onKeyPress={e => {
                          if (this.onChangeletters(e)) {
                          } else {
                            e.preventDefault();
                          }
                        }}
                      />
                      <Input
                        placeholder={
                          this.props.formChange &&
                          this.props.formChange.birthday
                            ? this.props.formChange.birthday
                            : lang.dateOfBirth
                        }
                        onClick={() =>
                          document.getElementById('datePicker').click()
                        }
                        error={
                          this.props.formChange &&
                          this.props.formChange.errorBirthday
                        }
                        message={
                          this.props.formChange &&
                          this.props.formChange.errorBirthday
                        }
                      />
                      <DatePicker
                        label="Basic example"
                        value={
                          this.props.formChange &&
                          this.props.formChange.birthday
                            ? this.props.formChange.birthday
                            : ''
                        }
                        onChange={this.handleBirthday}
                        animateYearScrolling
                        id="datePicker"
                        style={{ display: 'none' }}
                      />
                      <div>
                        <Tooltip
                          error={
                            this.props.formChange &&
                            this.props.formChange.errorGender
                          }
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                          }}
                          message={
                            this.props.formChange &&
                            this.props.formChange.errorGender
                          }
                        />
                        <ColumnWrap
                          marginTop="20px"
                          marginBottom="30px"
                          col="0.5fr 0.5fr 0.5fr"
                          justifyItems="center"
                          onChange={this.handleGender}
                          value={this.props.formChange.gender}
                        >
                          <RadioButton label={lang.female} value={'Femenino'} />
                          <RadioButton label={lang.male} value={'Masculino'} />
                          <RadioButton label={lang.other} value={'Otros'} />
                        </ColumnWrap>
                      </div>
                      <Button onClick={this.handleClickParticularTwo}>
                        {lang.next}
                      </Button>
                    </AlignInfo>
                  </div>
                  <div className="total-center">
                    <SteppersDots step2 />
                  </div>
                </div>
              ) : null}
              {this.state.scene === 'particularStep3' ? (
                <div>
                  {this.state.message ? (
                    <div style={{ minHeight: '400px' }}>
                      <div
                        className="total-center"
                        style={{ minHeight: '300px' }}
                      >
                        <p
                          style={{
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '18px',
                          }}
                        >
                          {this.state.message}
                        </p>
                      </div>
                      <div
                        className="total-center"
                        style={{ minHeight: '100px' }}
                      >
                        <Button onClick={() => this.props.history.push('/')}>
                          {lang.next}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div style={{ minHeight: '435px' }}>
                      <ArrowBack
                        onClick={() =>
                          this.setState({ scene: 'particularStep2' })
                        }
                      />
                      <AlignInfo>
                        <div style={{ width: '100%' }}>
                          <Tooltip
                            error={
                              this.props.formChange &&
                              this.props.formChange.errorCountry
                            }
                            style={{
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'center',
                            }}
                            message={
                              this.props.formChange &&
                              this.props.formChange.errorCountry
                            }
                          />
                          <DropdownList
                            data={this.props.countries}
                            textField={'name'}
                            onChange={this.handleSelectCountry}
                            value={this.state.nameCountry}
                            placeholder={lang.country}
                          />
                        </div>
                        <div style={{ width: '100%' }}>
                          <Tooltip
                            error={
                              this.props.formChange &&
                              this.props.formChange.errorState
                            }
                            style={{
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'center',
                            }}
                            message={
                              this.props.formChange &&
                              this.props.formChange.errorState
                            }
                          />
                          <DropdownList
                            data={this.props.states}
                            textField={'name'}
                            onChange={this.handleSelectState}
                            value={this.state.nameState}
                            placeholder={lang.city}
                          />
                        </div>
                        <Input
                          placeholder={lang.phone}
                          onChange={this.handleOnchange('phone', 'errorPhone')}
                          value={
                            this.props.formChange && this.props.formChange.phone
                              ? this.props.formChange.phone
                              : ''
                          }
                          onKeyPress={e => {
                            if (this.justifyPhone(e)) {
                            } else {
                              e.preventDefault();
                            }
                          }}
                          error={
                            this.props.formChange &&
                            this.props.formChange.errorPhone
                          }
                          message={
                            this.props.formChange &&
                            this.props.formChange.errorPhone
                          }
                        />
                        <Input
                          placeholder={lang.zipCode}
                          onChange={this.handleOnchange(
                            'zidCode',
                            'errorZidCode',
                          )}
                          value={
                            this.props.formChange &&
                            this.props.formChange.zidCode
                              ? this.props.formChange.zidCode
                              : ''
                          }
                          onKeyPress={e => {
                            if (this.onChangeNumber(e)) {
                            } else {
                              e.preventDefault();
                            }
                          }}
                          error={
                            this.props.formChange &&
                            this.props.formChange.errorZidCode
                          }
                          message={
                            this.props.formChange &&
                            this.props.formChange.errorZidCode
                          }
                        />
                        <Input
                          placeholder={lang.coachUser}
                          onChange={this.handleOnchange(
                            'usuarioCouch',
                            'errorUsuarioCouch',
                          )}
                          value={
                            this.props.formChange &&
                            this.props.formChange.usuarioCouch
                              ? this.props.formChange.usuarioCouch
                              : ''
                          }
                          onKeyPress={e => {
                            if (this.onChangelettersCaracterNumber(e)) {
                            } else {
                              e.preventDefault();
                            }
                          }}
                          error={
                            this.props.formChange &&
                            this.props.formChange.errorUsuarioCouch
                          }
                          message={
                            this.props.formChange &&
                            this.props.formChange.errorUsuarioCouch
                          }
                        />
                        <Text
                          fontSize="15px"
                          color="#FFF"
                          textAlign="center"
                          width="100%"
                          maxWidth="370px"
                          fontFamily="Roboto"
                          marginTop="50px"
                        >
                          {lang.byRegisteringYouWillBeAcceptingOur}
                          <br />
                          <span
                            style={{
                              color: '#66BBFF',
                              fontFamily: 'Roboto',
                              fontWeigth: 400,
                            }}
                          >
                            {' '}
                            <a href="https://eiry.app/terminos-y-condiciones/">
                            {lang.conditions}
                            </a>
                          </span>{' '}
                          {lang.and}
                          <span
                            style={{
                              color: '#66BBFF',
                              fontFamily: 'Roboto',
                              fontWeigth: 400,
                            }}
                          >
                            {' '}
                            <a href="https://eiry.app/politicas-de-privacidad/">
                            {lang.privacyPolicies}
                            </a>
                          </span>
                        </Text>
                        {this.state.load ? (
                          <div
                            className="total-center"
                            style={{ margin: '10px' }}
                          >
                            <CircularProgress />
                          </div>
                        ) : (
                          <Button onClick={this.handleClickParticularTree}>
                            {lang.next}
                          </Button>
                        )}
                        {this.state.errorMessage ? (
                          <div>
                            <p className="error-message">
                              {this.state.errorMessage}
                            </p>
                          </div>
                        ) : null}
                      </AlignInfo>
                    </div>
                  )}
                  <div className="total-center">
                    <SteppersDots step3 />
                  </div>
                </div>
              ) : null}
            </FormWrap>
          </div>
        </Background>
      </MuiPickersUtilsProvider>
    );
  }
}

const mapStateToProps = state => {
  // console.log(state.generalStore);
  return {
    dataGoogle: state.accessStore.loginGoogle.data,
    formChange: state.changeForm.form,
    countries: state.generalStore.countries.data,
    states: state.generalStore.states.data,
    gender: state.generalStore.gender.data,
    loadingGoogle: state.accessStore.loginGoogle.load,
    errorGoogle: state.accessStore.loginGoogle.error,
    dataUserGoogle: state.accessStore.loginGoogle.data,
    categories: state.generalStore.categories.data,
  };
};
export default connect(
  mapStateToProps,
  {
    applicationForm,
    getCountry,
    getCountries,
    getState,
    registerProviderCompany,
    getGender,
    getlistGender,
    registerProviderPerson,
    loginGoogle,
    errorLoginGoogle,
    getCategories,
    getListCategories,
    checkEmailAvailability,
    getUserId,
    loginInit,
    postLogin,
    getValidateUserExist,
    validateExistEmail,
  },
)(Register);

const Background = styled.div`
  background-image: url('/assets/images/background.png');
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100vh;
`;

const HeaderLogo = styled.img`
  width: auto;
  height: 45px;
  position: relative;
  margin-top: 25px;
  margin-left: 40px;
  @media (max-width: 481px) {
    display: none;
  }
`;
const FormWrap = styled.div`
  margin-top: 20px;
  background: linear-gradient(#1e1e1e, #1e1e1e) padding-box,
  linear-gradient(90deg, rgba(51,52,128,1) 0%, rgba(110,0,191,1) 100%) border-box;
  width: 100%;
  padding-left: 32px !important;
  max-width: 400px;
  min-height: 480px;
  border-radius: 5px;
  border: 5px solid transparent;
  border-width: 2px;
  padding: 20px;
  padding-top: 40px;
  @media (max-width: 480px) {
    width: 80vw;
  }
`;

const AlignInfo = styled.div`
  /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90% */
  max-width: 370px;
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 10px;
  align-items: center;
  justify-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
`;
const Button = styled.button`
  width: 100%;
  max-width: 220px;
  height: 30px;
  border-radius: 15px;
  border: none;
  background: linear-gradient(90deg, rgba(51,52,128,1) 0%, rgba(110,0,191,1) 100%);
  color: #fff;
`;
const ButtonNumber = styled.button`
  background-color: ${props => props.backgroundColor};
  width: 100%;
  height: 30px;
  border-radius: 15px;
  border: solid 1px #66bbff;
  background-color: transparent;
  color: #fff;
  outline: none;
  :active {
    background-color: #66bbff;
  }
`;
const Textarea = styled.textarea`
  max-width: 370px;
  min-width: 370px;
  min-height: 80px;
  max-height: 80px;
  padding-left: 15px;
  border-radius: 5px;
  border: solid 1px #b7b8b8;
  outline: none;
  text-align: left;
  background-color: #fff;
  :focus {
    border-style: solid;
    border-color: #66bbff;
    border-width: 1px;
  }
  ::placeholder {
    font-family: 'Roboto-LightItalic';
    font-size: 16px;
    color: #808080;
  }
`;
