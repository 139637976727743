import React, { Component,useState } from 'react';
import { geolocated } from 'react-geolocated';
import {Grid, Container} from '@material-ui/core';
import InputArea from '../../../../components/generics/InputArea';
import Input from '../../../../components/generics/Input';
import Map from '../../components/Map';
import Button from '../../components/Button';
import ImageSelect from '../../components/ImageSelect';
import Select from '../../components/Select';
import * as COLORS from '../../../../util/colors';
import Text from '../../../../components/generics/Text';


const Detail = (props) => {

  const [data, setData] = useState({
    img:'',
    category: null,
    subcategory: null,
    name: '',
    detail: '',
    file: null,
    lat: 0,
    lng: 0,
    address: ''
  });

  const [categoryOpen,setCategoryOpen] = useState(false);

  const [subCategoryOpen, setSubCategoryOpen] = useState(false);

  const [allCategories, setAllCategories] = useState(props.categories.map((c)=>{return {backgroundColor: c.backgroundColor,parentId:c.parentId,id:c.id,text: c.name.filter((name)=>name['language']===props.user.language)[0].description}; }));

  const [categories,setCategories] = useState([]);

  const [subCategories, setSubCategories] = useState([]);


  const onImageChange = (img,file) => {
    
    if (file==null) return;
    if ( !(/image\/.+/.test(file.type))) {
      const msg = {
        show: true,
        msg: 'Imagen no válida',
        type: 'error'                  
      };

      props.onShowMessage(msg);     
      return;
    }
    let dataChange= Object.assign({},data);
    dataChange.file = file;
    dataChange.img = img;
    setData(dataChange);
  };

  const onCategoryChange = (item) =>{
    if (item && item.length) {
      let dataChange= Object.assign({},data);
      dataChange.category = item[0];
      dataChange.subcategory = null;
      setData(dataChange);
      setCategoryOpen(false);
      setSubCategories(allCategories.filter((c)=>c.parentId===item.id));
    }
  };

  const onSubCategoryChange = (item) =>{
    if (item && item.length) {
      let dataChange= Object.assign({},data);
      dataChange.subcategory = item[0];
      setData(dataChange);
      setSubCategoryOpen(false);
    }

  };

  const onInputChange = (event) => {
    let dataChange= Object.assign({},data);
    dataChange[event.target.name] = event.target.value;
    setData(dataChange);
  };

  const onMapChange = (coord) => {
    let dataChange= Object.assign({},data);
    dataChange.lat = coord.lat;
    dataChange.lng = coord.lng;
    dataChange.address = coord.address;
    setData(dataChange);
  };

  const onOpenCategory = (open) => {      
    setCategoryOpen(open);
    setSubCategoryOpen(false);
  };

  const onOpenSubCategory = (open) => {
    setCategoryOpen((open?false:categoryOpen));
    setSubCategoryOpen(open);
  };

  const isNameValid = () => {
    if (!data.name) return false;

    return  (data.name.length>=2 && data.name.length<=30) //Entre dos y 30 carectares
             && !(/^([0-9])*$/.test(data.name))             // No pueden ser todos numeros
             && !(/^([^A-za-z])*$/.test(data.name))         // No pueden ser todos distintos de letras
             && (/(.*[A-Za-z]){2,}/.test(data.name));        // Al menos debe tener dos letras 
  };

  const stepValid = () => {

    return (
      (data.lat>=-90 && data.lat<=90)
        && (data.lng>=-180 && data.lng<=180)
        && (data.img.length>0)
        && (data.category && data.category.id)
        && (data.subcategory && data.subcategory.id)
        && (isNameValid())
        && (data.detail.length>0)
    );
  };

  const onChangeName = e => {
    if (e.keyCode==8) return true;
    
    let size = 0;
    if (data.name) {
      size = data.name.length;
    }
    size+=1;
      
    return  (size<=30); 
  };

  const onSave = () => {
    const dataChange= {...data};
    props.onChange && props.onChange(dataChange);
    props.nextStep();
  };


  return (
    <Container>
      <Grid container >
        
        <Grid item xs={12} sm={12} md={6}>
        <Text
          fontFamily='Roboto'
          fontSize='13px'
          color="#DA0458"
          fontWeight="bold"        
        > 1* Imagen del servicio</Text> 
          <ImageSelect
            onChange={onImageChange}
            img={data.img}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
        <Text
          fontFamily='Roboto'
          fontSize='13px'
          color="#DA0458"
          fontWeight="bold"    
        > 2* Nombre del servicio</Text> 
          <Input
            name="name"
            type='text'
            minlength={2}
            maxlength={30}
            onKeyPress={e => {
              if (onChangeName(e)) {
              } else {
                e.preventDefault();
              }
            }}
            onChange={onInputChange}
            value={data.name}
            placeholder="*Colocar el nombre del servicio"
            border="0"
            fontColor='#808080'
            paddingLeft="5px"
            borderBottom="1px solid #b7b8b8"
            borderFocus="0"
            borderFocusBottom="1px solid #b7b8b8"
            borderRadius="0"
            width="95%"
            height="25px"
          
          />
          <Text
          fontFamily='Roboto'
          fontSize='10px'
          marginTop='2px'
          color={COLORS.darkGray}
        > El nombre debe contener al menos 2 letras y no exceder de 30 caracteres.
         </Text>
         
        <Text
          fontFamily='Roboto'
          fontSize='13px'
          color="#DA0458" 
          fontWeight="bold"                  
        > 3* Descripción del servicio</Text> 
          <InputArea
            name="detail"
            onChange={onInputChange}
            value={data.detail}
            fontColor='#808080'
            placeholder="* Escribe una descripcion"
            border="1px solid #b7b8b8"
            borderFocusBottom="1px solid #b7b8b8"
            marginTop="10px"
            paddingLeft="5px"
            rows={20}
            width="100%"
            height="50px"
          />
          <Text
          fontFamily='Roboto'
          fontSize='13px'
          color="#DA0458"
          fontWeight="bold"          
        > 4* Selecciona una categoría  </Text> 
          <Select
            onOpen={onOpenCategory}
            onChange={onCategoryChange}
            items={allCategories.filter((cat)=>cat.parentId===null)}
            selected={data.category?[data.category]:[] }
            fontColor='#808080'
            placeholder="* Categoría"
            opened ={categoryOpen}
            key={data.category?JSON.stringify(data.category):props.user.id}
          />
           <Text
          fontFamily='Roboto'
          fontSize='13px'
          color="#DA0458"  
          fontWeight="bold"        
        > 5* Selecciona una subcategoría  </Text> 
          <Select
            onOpen={onOpenSubCategory}
            onChange={onSubCategoryChange}
            items={data.category!==null?allCategories.filter((cat)=>cat.parentId===data.category.id):[]}
            selected={data.subcategory?[data.subcategory]:[]}
            fontColor='#808080'
            placeholder="* Subcategoría"
            opened ={subCategoryOpen}
            key={data.subcategory?JSON.stringify(data.subcategory):props.user.id+'0'}
          />
        </Grid>
      </Grid>
      <Text
          fontFamily='Roboto'
          fontSize='13px'
          color="#DA0458"
          fontWeight="bold">          
        6* Selecciona en el mapa la ubicación del servicio</Text> 

      <Grid container style={{marginTop: '15px'}}>
        <Grid item xs={12}>
          {props.coords ?
            <Map
              onMapChange={onMapChange}
              google={props.google}
              center={{lat:props.coords.latitude, lng: props.coords.longitude}}
              width="100%"
              height="150px"
              searchTextTop="-170px"
              zoom={15}
            />
            :null}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} style={{textAlign:'center'}}>
          <Button
            disabled={!stepValid()}
            context={stepValid()?'primary':''}
            onClick={onSave}
          >
                  Siguiente
          </Button>
        </Grid>
      </Grid>
      <Text
          marginTop='15px'
          fontFamily='Roboto'
          fontSize='10px'
          textAlign='center'
          color={COLORS.purple}
        > Las imagenes deben ser 1920 x 1080 o superior </Text> 
    </Container>
  );
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Detail);
