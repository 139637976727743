const GET_LIST_SERVICES_USER = 'GET_LIST_SERVICES_USER';
const GET_ERROR_LIST_SERVICES_USER = 'GET_ERROR_LIST_SERVICES_USER';
const GET_DETAIL_SERVICES_USER = 'GET_DETAIL_SERVICES_USER';
const GET_ERROR_DETAIL_SERVICES_USER = 'GET_ERROR_DETAIL_SERVICES_USER';
const GET_START_DETAIL_SERVICES_USER = 'GET_START_DETAIL_SERVICES_USER';
const POST_SERVICES_USER = 'POST_SERVICES_USER';
const POST_ERROR_SERVICES_USER = 'POST_ERROR_SERVICES_USER';
export {
  GET_LIST_SERVICES_USER,
  GET_ERROR_LIST_SERVICES_USER,
  GET_ERROR_DETAIL_SERVICES_USER,
  GET_DETAIL_SERVICES_USER,
  GET_START_DETAIL_SERVICES_USER,
  POST_SERVICES_USER,
  POST_ERROR_SERVICES_USER
};
