
import styled from 'styled-components';

const Button = styled.button`
  background-color:${props =>
    (props.context==='primary' && '#6E00BF') || 
      (props.context==='secondary' && '#fff') ||
      (props.context==='important' && '#fff') ||
      (props.context==='warning' && 'orange')   ||
      (props.context==='info' && 'blue')   ||
      (props.context==='danger' && 'red')   ||
      '#fff'
};
  color: ${props =>
    (props.context==='primary' && '#fff') || 
    (props.context==='secondary' && '#fff') ||
    (props.context==='important' && '#fff') ||
    (props.context==='warning' && '#fff')   ||
    (props.context==='info' && '#fff')   ||
    (props.context==='danger' && '#fff')   ||
    '#d5d5d5'
};
  font-family:${props => props.fontFamily || 'Roboto'};
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 1px solid ${props => props.primary ? '#6E00BF' : '#bcbcbc'};
  border-radius: ${props => props.borderRaius || '5px'};
  :hover {
    opacity: ${props => props.disabled ? 0.5: 0.8};
    cursor: ${props => props.disabled ? 'arrow': 'pointer'}
  }
  opacity: ${props => props.disabled ? 0.5: 1};
`;

export default Button;
