import { API } from '../../api';

export const getServicesUser = (uid, onSuccess) => () => {
  API.get(`/Services/user/${uid}`, {
    headers: {
      token: localStorage.getItem('token_get_get_it'),
      uidLoggedIn: uid,
      idChannel: 'web',
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

export const getDetailServices = (uid, onSuccess) => () => {
  API.get(`/Services/${uid}`, {
    headers: {
      token: localStorage.getItem('token_get_get_it'),
      uidLoggedIn: uid,
      idChannel: 'web',
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

export const patchServicePrice = (data, onSuccess) => () => {
  API.put('/Services', data, {
    headers: {
      idChannel: 'web',
      idService: data.id,
      uidLoggedIn: data.uid,
      token: localStorage.getItem('token_get_get_it'),
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

export const patchServiceLocation = (data, onSuccess) => () => {
  API.patch(`/Services/${data.id}/serviceLocation`, null, {
    headers: {
      idChannel: 'web',
      Latitude: data.latitude,
      Longitude: data.longitude,
      uidLoggedIn: data.uid,
      token: localStorage.getItem('token_get_get_it'),
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

export const patchTermsAndConditions = (data, onSuccess) => () => {
  API.patch(`/Services/${data.id}/termsAndConditions`, null, {
    headers: {
      termsAndConditions: data.termsAndConditions,
      idChannel: 'web',
      uidLoggedIn: data.uid,
      token: localStorage.getItem('token_get_get_it'),
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

export const putDetailService = (data, onSuccess) => () => {
  API.put('/Services', data, {
    headers: {
      idChannel: 'web',
      uidLoggedIn: data.uid,
      token: localStorage.getItem('token_get_get_it'),
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

export const putMethodPayment = (id, uid, data, onSuccess) => () => {
  API.put(`/Services/${id}/paymentMethod`, data, {
    headers: {
      idChannel: 'web',
      uidLoggedIn: uid,
      token: localStorage.getItem('token_get_get_it'),
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

export const getSubCategoryUser = (id, onSuccess) => () => {
  API.get(`/dmz/categories/${id}`, {
    headers: {
      idChannel: 'web',
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

export const postService = (data, onSuccess) => () => {
  API.post('/Services', data, {
    headers: {
      idChannel: 'web',
      uidLoggedIn: data.uid,
      token: localStorage.getItem('token_get_get_it'),
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};