import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {isImage} from '../../../../util/utils';

const ImageSelect=(props) => {
  const [img,setImg] = useState(props.img);
  const [file,setFile] = useState(null);

  useEffect( () =>{
    props.onChange && props.onChange(img,file);
  },[img,file]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files.length>0) {
      if (!isImage(event.target.files[0])) return;
      setFile(event.target.files[0]);
      setImg(URL.createObjectURL(event.target.files[0]));
    }

  };

  return (<Fragment>
    <label htmlFor={props.id || 'itemSelect'}>
      <CoverImg img={img } />
    </label>
    <input accept="image/*" style={{width:0}} id={props.id || 'itemSelect'} type="file" onChange={onImageChange}/>
  </Fragment>);
};

const CoverImg = styled.div`
  width: 200px;
  height: 200px;
  border: 1px solid #d5d5d5;
  background-image: url(${props => props.img || 'assets/images/service/camera_background,png' });
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: ${props => props.img? 'cover' : 'auto' };
  border-radius: 5px;
  :hover {
    opacity: 0.8;
  }

  @media (max-width: 767px) { margin: 0 auto; }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) { }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {  }   
`;

ImageSelect.propTypes = {
  img: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id:  PropTypes.string,
};

export default ImageSelect;
