const BASE_URL = 'https://mob.eiry.app/fts-back/';
const MAX_DISPONIBILITY_ITEMS = 10;
const MAX_SCHEDULES_ITEMS = 10;
const MIN_SERVICES_TIME = 15; //En minutos
const DEFAULT_COORD = {latitude: 10.4961961, longitude:-66.8510};

export { BASE_URL, 
  MAX_DISPONIBILITY_ITEMS, 
  MAX_SCHEDULES_ITEMS,
  MIN_SERVICES_TIME,
  DEFAULT_COORD };
