import styled from 'styled-components';

const Text = styled.p`
  width: ${props => props.width};
  display: ${props => props.inline ? 'inline-block': 'block'};
  clear: ${props => props.clearBoth ? 'both': 'none'};
  max-width: ${props => props.maxWidth};
  height: ${props => props.height};
  font-family: ${props => props.fontFamily || 'Metropolis-Bold'};
  font-weight: ${props => props.fontWeight || '400'};
  font-size: ${props => props.fontSize || '14px'};
  color: ${props => props.color || '#000'};
  background-color: ${props => props.backGroundColor};
  margin: ${props => props.margin};
  margin-top: ${props => props.marginTop || 'auto'};
  margin-right: ${props => props.marginRight};
  margin-left: ${props => props.marginLeft};
  padding: ${props => props.padding};
  margin: ${props => props.marginX};
  margin-top: ${props => props.marginTop};
  margin-right: ${props => props.marginRight};
  margin-left: ${props => props.marginLeft};
  padding: ${props => props.paddingX};
  padding-bottom: ${props => props.paddingBottom};
  padding-left: ${props => props.paddingLeft};
  margin-bottom: ${props => props.marginBottom};
  line-height: ${props => props.lineHeight};
  opacity: ${props => props.opacity};
  position: ${props => props.position};
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  cursor: ${props => props.cursor};
  text-align: ${props => props.textAlign || 'left'};
  @media (max-width: ${props => props.sizeSm || '768px'}) {
    text-align: ${props => props.textAlignMd};
    font-size: ${props => props.fontSizeMd};
  }
  @media (max-width: ${props => props.sizeXs || '620px'}) {
    text-align: ${props => props.textAlignXs};
    font-size: ${props => props.fontSizeXs};
  }
  @media (max-width: ${props => props.sizeXs || '420px'}) {
    text-align: ${props => props.textAlignXf};
    font-size: ${props => props.fontSizeXf};
  }
  @media (max-width: ${props => props.sizeXs || '380px'}) {
    text-align: ${props => props.textAlignXx};
    font-size: ${props => props.fontSizeXx};
  }
`;

export default Text;
