
import moment from 'moment';

export const range = (start, edge, step) => {
  
  
  // Validate edge/start
  edge = edge || 0;
  step = step || 1;
  
  // Create array of numbers, stopping before the edge
  let arr = [];
  for (arr; (edge - start) * step > 0; start += step) {
    arr.push(start+'');
  }
  return arr;
};


export const days = () => {


  const shortDays= moment.weekdays().map((wd,index)=>{
    let tId = '';
    switch (index) {
      case 0:
        tId = 'sunday';
        break;
      case 1:
        tId = 'monday';
        break;
      case 2:
        tId = 'tuesday';
        break; 
      case 3:
        tId = 'wednesday';
        break;
      case 4:
        tId = 'thursday';
        break;
      case 5:
        tId = 'friday';
        break; 
      case 6:
        tId = 'saturday';
        break;                                       
    }
    return {
      id: tId,
      text: wd.substr(0,1).toUpperCase(),
      checked: true,
      n:index, 
      ltext: wd.charAt(0).toUpperCase() + wd.slice(1) };
  });
  
  return shortDays;
};

export const randomStr = (length) =>{
  var text = '';
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   
  for (var i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
   
  return text;  
};

export const isImage = (file) =>{
  const type= file.type;
  return (
    /^image\/[a-z0-9]+$/.test(type)
  );
};