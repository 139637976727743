export const es = {
  lang: 0,
  downloadSummaryPdf: 'Descargar resumen PDF',
  doClickInCreateService: 'Haz clic en "Crear servicio"',
  toEnhanceBooking: 'para potenciar las oportunidades de reserva',
  createService: 'Crear servicio',
  services: 'Servicios',
  AchieveWhatYouImagineToReach: 'Logra lo que imaginas alcanzar.',
  OfferYourServices: '¡Oferta tus servicios!',
  save: 'Guardar',
  theFieldIsRequired: 'El campo es requerido',
  passwordChanged: 'Contraseña cambiada',
  thePasswordMustBeAtLeast: 'La contraseña debe tener mínimo 6 caracteres',
  passwordsDoNotMatch: 'Las contraseñas no coinciden',
  enterNewPassword: 'Ingresa nueva contraseña',
  confirmNewPassword: 'Confirma nueva contraña',
  enterValidEmailAddress: 'Ingrese una dirección de correo válida',
  exclusiveIncomeForSuppliers: 'Ingreso exclusivo para proveedores',
  email: 'Correo electrónico',
  loginWith: 'Ingresar con',
  change: 'Cambiar',
  google: 'Google',
  password: 'Contraseña',
  enter: 'Entrar',
  forgotMyPassword: 'Olvidé mi contraseña',
  emailIsNotRegistered: 'El correo electrónico no se encuentra registrado',
  registerMe: 'Registrarme',
  logInWhith: 'Ingresar con',
  youStillDontHaveAnAccount: '¿Todavía no tienes cuenta?',
  signUp: 'Regístrate',
  follow: 'Segir',
  next: 'Siguiente',
  weHaveSentYouAnEmail:
    'Te hemos enviado un correo electrónico para restablecer tu contraseña. Si no lo has recibido haz clic',
  emailDoesNotExist: 'El correo electronico no existe',
  here: 'aquí.',
  restorePassword: 'Restablecer contraseña',
  send: 'Enviar',
  mustBeAtLeast2Characters: 'Debe tener mínimo 2 caracteres',
  mustBeAtLeast3Characters: 'Debe tener mínimo 3 caracteres',
  mustHaveaMaximumOf15Characters: 'Debe tener máximo 15 caracteres',
  mustHaveaMaximumOf30Characters: 'Debe tener máximo 30 caracteres',
  blankSpacesAreNotAllowed: 'No se permiten espacios en blanco',
  creationDate: 'Fecha de creación',
  yearsOfCreation: 'Años de creacion',
  theEmailIsDuplicated: 'El email esta duplicado',
  theUserIsDuplicated: 'El usuario esta duplicado',
  youMustHaveAtLeastTwoLetters: 'Debe contar con al menos dos letras',
  thePasswordMustBeAtLeast8:
    'La contraseña debe tener como mínimo 8 caracteres',
  thePasswordMustHaveAMaximumOf:
    'La contraseña debe tener como máximo 20 caracteres',
  thePasswordMustBeBetween8And20:
    'La contraseña debe tener al entre 8 y 20 caracteres, al menos un dígito, al menos una letra minúscula y al menos una letra mayúscula',
  theAddressCannotHaveMoreThan20Characters:
    'La dirección no puede tener mas de 20 caracteres',
  theFieldMustHaveAMinimumOf2Characters:
    'El campo debe tener mínimo 2 caracteres',
  theFieldMustHaveAMinimumOf4Characters:
    'El campo debe tener mínimo 4 caracteres',
  theFieldMustHaveAMaximumOf6Characters:
    'El campo debe tener máximo 6 caracteres',
  theFieldMustHaveAMaximumOf10Characters:
    'El campo debe tener máximo 10 caracteres',
  theFieldMustHaveAMaximumOf30Characters:
    'El campo debe tener máximo 30 caracteres',
  onlyNumbersAreAllowed: 'Solo se permiten números',
  theCreationDateMustBeLessThanTheCurrentOne:
    'La fecha de creación debe ser menor a la actual',
  iDIsIncorrect: 'El ID es incorrecto',
  thePhoneFieldMustHaveAMinimumOf11Characters:
    'El campo Teléfono debe tener mínimo 9 caracteres',
  enterAValidPhoneNumber: 'Ingrese un Numero de Teléfono valido*',
  mustContainNameAndSurname: 'Debe contener (Nombre y Apellido)',
  theFieldMustStartAtLeastInOneLetterAndHaveANumber:
    'El campo debe debe empezar almenos en una letra y tener un número',
  youMustBeOfLegalAge: 'Debe ser mayor de edad',
  youMustSelectTheGender: 'Debe seleccionar el genero',
  user: 'Usuario',
  country: 'Pais',
  city: 'Ciudad',
  address: 'Dirección',
  zipCode: 'Código postal',
  phone: 'Teléfono',
  nameContact: 'Nombre persona contacto',
  organizationIdExample: "ID Organización 'Ejemplo: Jxxxxxxxx'",
  numberOfEmployees: 'Número de Empleados',
  byRegisteringYouWillBeAcceptingOur:
    'Al registrarte estarás aceptando nuestras',
  conditions: 'Condiciones',
  and: 'y',
  privacyPolicies: 'Política de privacidad',
  professional: 'Profesional',
  freelancer: 'Freelancer',
  entrepreneur: 'Emprendedor',
  job: 'Oficio',
  particular: 'Particular',
  organization: 'Organización',
  name: 'Nombre',
  lastName: 'Apellido',
  dateOfBirth: 'Fecha de nacimiento',
  female: 'Femenino',
  male: 'Masculino',
  other: 'Otro',
  coachUser: 'Usuario coach (Opcional)',
  organizationName: 'Nombre de la organización',
  logOut: 'Cerrar sesión',
  theProfileImageWasSuccessfullyChanged:
    'La imagen de perfil fue cambiada exitosamente',
  theBackgroundImageWasSuccessfullyChanged:
    'La imagen de fondo fue cambiada exitosamente',
  editAbouthMe:
    'Sobre mi cambiado exitosamente',
  editPhoto: 'Editar Foto',
  joinedIn: 'Se unió en',
  editCoverPhoto: 'Editar foto de portada',
  currentPlan: 'Plan Actual',
  totalSeats: 'Total de plazas',
  available: 'Disponible',
  totalPasses: 'Total de pases',
  totalServices: 'Total de servicios',
  totalGift: 'Total de regalos',
  changePlan: 'Cambiar plan',
  yourTermsAndConditionsWereSuccessfullyChanged:
    'Sus términos y condiciones fueron cambiados exitosamente',
  theDescriptionWasEditedSuccessfully:
    'La descripción fue editada exitosamente',
  theNameOfTheServiceWasSuccessfullyEdited:
    'El nombre del servicio fue editado exitosamente',
  theImageWasSuccessfullyEdited: 'La imagen fue editada exitosamente',
  yourPaymentMethodWasSuccessfullyEdited:
    'Su método de pago fue editado exitosamente',
  itsMandatoryToHaveAPaymentMethod: 'Es obligatoria tener un metodo de pago',
  theCategoryWasSuccessfullyChanged: 'La categoría fue cambiada exitosamente',
  theAddressWasSuccessfullyChanged: 'La dirección fue cambiada exitosamente',
  ok: 'OK',
  location: 'Ubicación',
  price: 'Precio',
  paymentMethods: 'Métodos de pago',
  termsAndConditions: 'Términos y Condiciones',
  simultaneousService: 'Servicio simultáneo',
  simultaneousServices: 'Servicio simultáneos',
  placesPerMonth: 'Plazas por mes',
  passesPerMonth: 'Pases por mes',
  giftPerMonth: 'Regalos por mes',
  dailyPostProgramming: 'Programación de post diaria',
  limitedStatistics: 'Estadisticas limitadas',
  detailedStatistics: 'Estadisticas detalladas',
  timeToReserve: 'Tiempo para reservar',
  monthly: 'Mensual',
  annual: 'Anual',
  homeDisplay: 'Visualizacion en el home',
  followers: 'Seguidores',
  seatsPassesAndAdditionalGifts: 'Plazas, pases y regalos adicionales',
  notApply: 'No aplica',
  apply: 'Aplica',
  quarterlyCost: 'Costo trimestral',
  semesterCost: 'Costo semestral',
  annualCost: 'Costo anual',
  month: 'mes',
  followersAndCityOfRegistration: 'Seguidores y ciudad de registro',
  acquirePlan: 'Adquirir Plan',
  tryForFree: 'Probar gratis',
  howDoYouWantToMakeYourPayment: '¿Cómo deseas realizar tu pago?',
  onlinePayment: 'Pago online',
  bankPayment: 'Pago bancario',
  attachImage: 'Adjuntar imagen',
  iAgree: 'Aceptar',
  myPlan: 'Mi Plan',
  myServices: 'Mis Servicios',
  categories: 'Categorías',
  subCategories: 'Subcategorías',
  selectAll: 'Seleccionar todo',
  paymentRemarks: 'Observaciones de pago',
  passwordErrorMessage: 'La contraseña es inválida',
  networkErrorMessage: 'Por favor revise su conexión a internet',
  private: 'Privada',
  ong: 'O.N.G',
  public: 'Pública',
  serviceError: 'No tienes servicios disponibles'
};
