import styled from 'styled-components';
import * as COLORS from '../../../../util/colors';

const IconOk = styled.button`
    color: ${props=> props.variant  || COLORS.ok};
    background-color: ${props=> props.backVariant  || COLORS.white};
    border-radius: ${props=> props.borderRadius};
    width:${props=> props.size || '20px'};
    height: ${props=> props.size || '20px'};
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer;
    position: relative;
    font-size: 150%;
    border: 1px solid ${props=> props.variant  || COLORS.ok};
    ::before{
      content: '✔';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%)       
    }
`;

const IconInfo = styled.button`
    color: ${props=> props.variant  || COLORS.info};
    background-color: ${props=> props.backVariant  || COLORS.white};
    border-radius: ${props=> props.borderRadius};
    width:${props=> props.size || '20px'};
    height: ${props=> props.size || '20px'};
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer;
    position: relative;
    font-size: 150%;
    border: 1px solid ${props=> props.variant  || COLORS.info};
    ::before{
      content: '！';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%)       
    }
`;

const IconError = styled.button`
    color: ${props=> props.variant  || COLORS.red};
    background-color: ${props=> props.backVariant  || COLORS.white};
    border-radius: ${props=> props.borderRadius};
    width:${props=> props.size || '20px'};
    height: ${props=> props.size || '20px'};
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer;
    position: relative;
    font-size: 150%;
    border: 1px solid ${props=> props.variant  || COLORS.red};
    ::before{
      content: '✘';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%)       
    }
`;

const IconDown = styled.button`
    color: ${props=> props.variant  || COLORS.purple};
    background-color: ${props=> props.backVariant  || COLORS.white};
    border-radius: ${props=> props.borderRadius || '8px'};
    width:${props=> props.size || '20px'};
    height: ${props=> props.size || '20px'};
    float: ${props=> props.float};
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer;
    position: relative;
    font-size: 150%;
    border: 0px solid ${props=> props.variant  || COLORS.darkGray};
    ::before{
      content: '˅';      
      position: absolute;
      left: 50%;
      top: 50%;  
      transform: translate(-50%,-50%)       
    }
`;

const IconUp = styled.button`
    color: ${props=> props.variant  || COLORS.purple};
    background-color: ${props=> props.backVariant  || COLORS.white};
    border-radius: ${props=> props.borderRadius || '8px'};
    width:${props=> props.size || '20px'};
    height: ${props=> props.size || '20px'};
    float: ${props=> props.float};
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer;
    position: relative;
    font-size: 150%;
    border: 0px solid ${props=> props.variant  || COLORS.darkGray};
    ::before{
      content: '˄';           
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%)        
    }
`;

const IconDelete = styled.button`
    color: ${props=> props.variant  || COLORS.darkGray}
    background-color: ${props=> props.backVariant  || COLORS.white};
    border-radius: ${props=> props.borderRadius || '8px'};
    width:${props=> props.size || '20px'};
    height: ${props=> props.size || '20px'};
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer;
    position: relative;
    font-size: 150%;
    float: ${props=> props.float};
    border: 1px solid ${props=> props.variant  || COLORS.white};
    ::before{
      content: '✕';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%)       
    }
`;

const IconWarning = styled.button`
    color: ${props=> props.variant  || COLORS.warning};
    background-color: ${props=> props.backVariant  || COLORS.white};
    border-radius: ${props=> props.borderRadius || '8px'};
    width:${props=> props.size || '20px'};
    height: ${props=> props.size || '20px'};
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer;
    position: relative;
    font-size: 150%;
    border: 1px solid ${props=> props.variant  || COLORS.warning};
    ::before{
      content: '◬';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%)       
    }
`;

export  {IconError, IconInfo, IconOk, IconDown, IconUp, IconDelete, IconWarning };