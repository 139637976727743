import { API } from '../../api';

export const postLogin = (data, onSuccess) => () => {
  API.post('/login/authenticate', data, {
    headers: {
      token: data.token,
      uidLoggedIn: data.uid,
      deviceToken: 'null',
      idChannel: 'web',
      'Content-Type': 'application/json',
    },
  }).then(response => {
    console.log(response)
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

export const putChangePassword = (data, onSuccess) => () => {
  API.put(`/dmz/users/pwd/${data.uid}`, null, {
    headers: {
      uid: data.uid,
      idChannel: 'web',
      pwd: data.pwd,
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

export const getEmail = (userEmail, onSuccess) => () => {
  API.get('/dmz/login/users/email', {
    headers: {
      email: userEmail,
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

export const checkEmailAvailability = (userEmail, onSuccess) => () => {
  API.get(`/dmz/register/users/exist/email`, { headers: { userEmail } }).then(
    response => {
      if (response.code >= 200 && response.code <= 299) {
        onSuccess(response);
      } else {
        onSuccess(response);
      }
    },
  );
};

export const registerProviderCompany = (data, onSuccess) => () => {
  API.post('/dmz/register/provider-company', data).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

export const registerProviderPerson = (data, onSuccess) => () => {
  API.post('/dmz/register/provider-person', data).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

export const getValidateUserExist = (user, onSuccess) => () => {
  API.get(`/dmz/register/users/exist/username/${user}`, {
    headers: {
      idChannel: 'web',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

export const getValidateEmailExist = (data, onSuccess) => () => {
  API.get(`/dmz/register/users/exist/email`, data, {
    body: data,
    headers: {
      idChannel: 'web',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};
export const validateExistEmail = (email, data, onSuccess) => () => {
  API.get(`/dmz/register/users/exist/email?userEmail=${email}`, {
    headers: {
      userEmail: email,
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};
