import * as types from './types';

const listServicesUser = (list, load) => {
  return {
    type: types.GET_LIST_SERVICES_USER,
    payload: {
      list,
      load,
    },
  };
};

const errorListServicesUser = error => {
  return {
    type: types.GET_ERROR_LIST_SERVICES_USER,
    payload: error,
  };
};

const getStartDetailServices = load => {
  return {
    type: types.GET_START_DETAIL_SERVICES_USER,
    payload: load,
  };
};

const detailServices = (detail, load) => {
  return {
    type: types.GET_DETAIL_SERVICES_USER,
    payload: {
      detail,
      load,
    },
  };
};

const errorDetailServices = error => {
  return {
    type: types.GET_ERROR_DETAIL_SERVICES_USER,
    payload: error,
  };
};

const createService = (service) => {
  return {
    type: types.POST_SERVICES_USER,
    payload: {
      service
    },
  };
};

const errorCreateService = error => {
  return {
    type: types.POST_ERROR_SERVICES_USER,
    payload: error
  };  
};

export {
  listServicesUser,
  errorListServicesUser,
  detailServices,
  getStartDetailServices,
  errorDetailServices,
  createService,
  errorCreateService,
};
