import React from 'react';
import styled from 'styled-components';

const RadioButton = props => {
  return (
    <Container>
      <Label>{props.label}</Label>
      <input
        type="radio"
        name="radios"
        value={props.value}
        onChange={props.onChange}
        defaultChecked={props.defaultChecked}
      />
      {props.input ? <Input type="text" /> : null}
    </Container>
  );
};
const Container = styled.div`
  display: flex;
`;
const Label = styled.p`
  margin-right: 10px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  text-align: center;
`;
const Input = styled.input`
  width: 100%;
  background: none;
  border: none;
  border-bottom: solid 1px #fff;
  margin-left: 5px;
  outline: none;
  :focus {
    border-bottom: solid 1px #6E00BF;
  }
`;
export default RadioButton;
