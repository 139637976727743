import { combineReducers } from 'redux';
import * as types from './types';

const initialState = {
  plan: {},
  load: false,
  error: '',
};

const initialStateList = {
  list: [],
  load: false,
  error: '',
};

const planUser = (state = initialState, action) => {
  switch (action.type) {
    case types.START_GET_PLAN_USER:
      return {
        plan: {},
        error: '',
        load: action.payload,
      };
    case types.DETAIL_GET_PLAN_USER:
      return {
        error: '',
        load: action.payload.load,
        plan: action.payload.planUser,
      };
    case types.ERROR_GET_PLAN_USER:
      return {
        error: action.payload,
        load: false,
        plan: {},
      };
    default:
      return state;
  }
};

const listPlan = (state = initialStateList, action) => {
  switch (action.type) {
    case types.START_GET_PLAN_lIST:
      return {
        list: [],
        error: '',
        load: action.payload,
      };
    case types.LIST_GET_PLAN:
      return {
        error: '',
        load: action.payload.load,
        list: action.payload.list,
      };
    case types.ERROR_GET_PLAN_LIST:
      return {
        error: action.payload,
        load: false,
        list: [],
      };
    default:
      return state;
  }
};

const planStore = combineReducers({
  planUser,
  listPlan,
});

export default planStore;
