import { combineReducers } from 'redux';
import * as types from './types';

const initialState = {
  data: {},
  load: false,
  error: '',
};

const initialStateDetail = {
  data: {},
  error: '',
  load: false,
};

const initialStateGoogle = {
  data: {},
  error: '',
  load: false,
};

const loginStore = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        data: action.payload.data,
        load: action.payload.load,
        error: '',
      };
    case types.ERROR_LOGIN_SUCCESS:
      return {
        data: {},
        laod: action.payload.load,
        error: action.payload.error,
      };
    case types.LOGOUT:
      return {
        data: {},
        load: false,
        error: '',
      };
    default:
      return state;
  }
};

const detailStore = (state = initialStateDetail, action) => {
  switch (action.type) {
    case types.GET_DETAIL_USER:
      return {
        data: action.payload.data,
        error: '',
        load: action.payload.load,
      };
    case types.ERROR_GET_DETAIL_USER:
      return {
        error: action.payload.error,
        load: action.payload.load,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

const loginGoogle = (state = initialStateGoogle, action) => {
  switch (action.type) {
    case types.LOGIN_GOOGLE:
      return {
        data: action.payload.data,
        error: '',
        load: action.payload.load,
      };
    case types.ERROR_LOGIN_GOOGLE:
      return {
        error: action.payload.error,
        load: action.payload.load,
        data: {},
      };
    default:
      return state;
  }
};

const accessStore = combineReducers({
  loginStore,
  detailStore,
  loginGoogle,
});

export default accessStore;
