import React, { Component} from 'react';
import { connect } from 'react-redux';
import {Grid} from '@material-ui/core';
import styled from 'styled-components';
import StepWizard from 'react-step-wizard';
import * as firebase from 'firebase/app';
import "firebase/storage";
import moment from 'moment';
import Text from '../../components/generics/Text';
import Detail from './containers/Detail';
import PaymentMethod from './containers/PaymentMethod';
import Schedule from './containers/Schedule';
import Disponibility from './containers/Disponibility';
import Resume from './containers/Resume';
import Terms from './containers/Terms';
import StepPagination from './components/StepPagination';
import {IconOk, IconError,IconWarning} from './components/Icons';
import Button from './components/Button';
import Modal from '../../components/generics/Modal';
import * as COLORS from '../../util/colors';
import {randomStr} from '../../util/utils';
import {DEFAULT_COORD} from '../../constants';

import {
  getMethodPayment,
  getListPayment,
  getCategories,
  getListCategories,
} from '../../ducks/general';

import {
  createService,
  postService,
  errorCreateService,
  getServicesUser,
  listServicesUser,
  errorListServicesUser  
} from '../../ducks/services';

import {
  getUserPlan,
  errorPlanUser,
  getDetailPlan,
  detailPlanUser
} from '../../ducks/plan';

class Service extends Component {
    state= {
      service: {
        schedules: []
      },
      schedule: {id: randomStr(20)},
      currentStep:1,
      steps: ["Personalizar","Métodos de pago","Horario","Clasificación y precio","Resumen","Términos y condiciones"],
      pageSize: 3,
      saving: false,
      editSchedule: false,
      message: {show: false, msg: '',type:'info'},
      confirm: {show: false, msg: '',type:'info',callback: ()=>{}}
    };

    componentWillMount() {
      this.handleGetPayment();
      this.handleGetCategories();
      this.handleGetPlanUser();
    }

    isFibyPay = () => {
        const {service} = this.state;
       
        return !(
          service
          && service.payment
          && service.payment.paymentOption
          && service.payment.paymentOption.length>0
        );
    }

    onShowMessage =(msg) => {
      this.setState({message: msg});
    }
    onShowConfirmMessage =(msg) => {
      this.setState({confirm: msg});
    }

    onCloseMessage = () => {
      this.setState({message: {show:false, msg: '', type:'info'}});
    }

    onCloseConfirmMessage = () => {
      this.setState({confirm: {show:false, msg: '', type:'info',callback: ()=>{}}});
    }

    confirmMessage = () =>{
      const {confirm} = this.state;

      return (<Modal
              contentStyle={{textAlign:'center'}}
              show={confirm.show}
              onHide={this.onCloseMessage}
            >
              {/* {confirm.type==='info'?<IconOk size='64px' />:''} */}
              {confirm.type==='info'?<img src="/assets/images/ServicioCreado.png" width="80px" height="80px"/>:''}
              {/* {confirm.type==='error'?<IconError size='64px'/>:''} */}
              {confirm.type==='error'?<img src="/assets/images/ServicioNoCreado.png" width="80px" height="80px"/>:''}
              {/* {confirm.type==='warning'?<IconWarning size='64px'/>:''} */}
              {confirm.type==='warning'?<img src="/assets/images/ServicioNoCreado.png" width="80px" height="80px"/>:''}
              <Text
                    textAlign={'center'}
                    fontFamily='Roboto'
                    color={COLORS.darkGray}
                    margin='0 auto'
                    marginTop= '20px'
                    >
                    {confirm.msg}                    
              </Text> 
              <Grid container >
                <Grid item xs={6}>                  
                <Button                 
                  context={'primary'}                
                  onClick={()=>{confirm.callback(true);this.onCloseConfirmMessage();}}
                >
                  Aceptar
                </Button> 
                </Grid>
                {<Grid item xs={6}>
                <Button
                  context={'primary'}                
                  onClick={()=>{confirm.callback(false);this.onCloseConfirmMessage();}}
                >
                  Cancelar
                </Button> 
                </Grid> }

              </Grid>

                                                
        </Modal>)
    }

    renderMessage = () =>{
      const {message} = this.state;

      return (<Modal
              contentStyle={{textAlign:'center'}}
              show={message.show}
              onHide={this.onCloseMessage}
            >
              {/* {message.type==='info'?<IconOk size='64px' />:''} */}
              {message.type==='info'?<img src="/assets/images/Load1.png" width="80px" height="80px"/>:''}
              {/* {message.type==='error'?<IconError size='64px'/>:''} */}
              {message.type==='error'?<img src="/assets/images/ServicioNoCreado.png" width="80px" height="80px"/>:''}
              <Text
                    textAlign={'center'}
                    fontFamily='Roboto'
                    color={COLORS.darkGray}
                    margin='0 auto'
                    marginTop= '20px'
                    >
                    {message.msg}                    
              </Text>  
              <Button
                  context={'primary'}                
                  onClick={this.onCloseMessage}
                >
                  Aceptar
                </Button>                                
        </Modal>)
    }

    handleGetServicesUser = () => {
      this.props.getServicesUser(
        this.props.user.id,
        Response => {
          if (Response.code >= 200 && Response.code <= 299) {
            if (
              Array.isArray(Response.data.responseInfo) &&
              Response.data.responseInfo.length > 0
            ) {
              this.props.listServicesUser(Response.data.responseInfo, false);
            } else {
              this.setState({
                service: true,
              });
              this.props.listServicesUser([], false);
            }
          } else {
            this.props.errorListServicesUser(Response.message);
          }
        },
      );
    };

    handleGetCategories = () => {
      this.props.getCategories(Response => {
        if (Response.code >= 200 && Response.code <= 299) {
          if (
            Response.data.responseInfo.length &&
            Array.isArray(Response.data.responseInfo)
          ) {
            this.props.getListCategories(Response.data.responseInfo);
          } else {
            this.props.getListCategories([]);
          }
        } else {
          this.props.getListCategories([]);
        }
      });
    };
  
    handleGetPayment = () => {
      const data = {
        uid: this.props.user.id,
      };
      this.props.getMethodPayment(data, Response => {
        if (Response.code >= 200 && Response.code <= 299) {
          if (
            Response.data.responseInfo.length > 0 &&
            Array.isArray(Response.data.responseInfo)
          ) {
            this.props.getListPayment(Response.data.responseInfo);
          } else {
            this.props.getListPayment([]);
          }
        } else {
          this.props.getListPayment([]);
        }
      });
    };

    handleGetPlanUser = () => {
      const data = {
        uid: this.props.user.id,
      };
      this.props.getUserPlan(data, Response => {
        if (Response.code >= 200 && Response.code <= 299) {
          let data = {
            id: Response.data && Response.data.responseInfo.idPlan,
            uid: Response.data && Response.data.responseInfo.uid,
          };
          if (data.id !== '') {
            this.props.getDetailPlan(data, result => {
              if (result.code >= 200 && result.code <= 299) {
                Response.data.responseInfo[`name`] =
                  result.data.responseInfo.name;
                this.props.detailPlanUser(Response.data.responseInfo, false);
              } else {
                this.props.errorPlanUser(result.message);
              }
            });
          }
        } else {
          this.props.errorPlanUser(Response.message);
        }
      });
    };

    onClickBack = () => {
      const {currentStep} = this.state;
      this.setState({ currentStep: currentStep-1 });
    }

    onStepChange = (e) => {
        this.setState({ currentStep: e.activeStep });
    }

    onDetailChange = (data) => {
      const {service} = this.state;
      this.setState({service: {...service,detail: data}});
      console.log('onDetailChange',data);
    } 

    onPaymenChange = (data) => {
      const {service} = this.state;
      this.setState({service: {...service,payment: data}});
      console.log('onPaymenChange',data);
    } 

    onScheduleChange = (data) => {
      const {schedule} = this.state;
      let newSchedule = {...schedule};
      newSchedule['schedule'] = data;
      console.info(newSchedule);
      this.setState({'schedule': newSchedule});
    } 

    onDisponibilityChange = (data) => {
      const {service, schedule} = this.state;
      let newSchedule = {...schedule};
      newSchedule['disponibility'] = data;
      const {schedules} = service;
      let newSchedules = [...schedules];
      console.info(newSchedule);
      if (newSchedules.length>0) {
          const found = newSchedules.findIndex((item)=>item.id?newSchedule.id===item.id:false);
          if (found>=0) {
            newSchedules[found] = newSchedule;
          }
          else {
            newSchedules.push(newSchedule);
          }
      }
      else {
        newSchedules.push(newSchedule);
      }
      this.setState({service: {...service,schedules: newSchedules},schedule:newSchedule,editSchedule:false});
      console.log('onDisponibilityChange',data);
    } 

    onTermsChange = (data) => {
      const {service} = this.state;
      this.setState({service: {...service,terms: data}},
      ()=>{
          this.onSaveService();
      });
      console.log('onTermsChange',data);
    } 
    
    onResumeChange = (data) => {
      console.log('onResumeChange',data);
    } 
    

    onEditSchedule = (schedule) => {
      let newSchedule = {...schedule};
      if (!newSchedule.hasOwnProperty('id')) {
        newSchedule['id']=randomStr(20);
      }
      this.setState({schedule: {...newSchedule},editSchedule:true});
    }

    onRemoveSchedule =(index) => {
      const {service, schedule} = this.state;
      let newSchedule = {...schedule};
      const {schedules} = service;
      let newSchedules = [...schedules];
      newSchedules = newSchedules.filter( (item,idx)=>idx!==index);
      if (newSchedules.length===0) {
        newSchedule = {'id': randomStr(20)};
      }

      this.setState({
        schedule: newSchedule,
        service: {...service,schedules: newSchedules}});

    }
    onSaveService = () => {
      const {service} = this.state;

      this.setState({message: {
        show: true,
        msg: 'Tu servicio está siendo procesado, por favor espera un momento. Si cierras la ventana el servicio no se guardará.',
        type: 'info'          
      }, saving:true});

      const that = this;
      const metadata = {
        contentType: service.detail.file.type
      };

      const uploadTask = firebase
                .storage()
                .ref()
                .child('service/images/' + service.detail.file.name)
                .put(service.detail.file, metadata);

      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              default:
                  console.log('test1');
                  break;
          }
      }, function (error) {
        that.setState({message: {
            show: true,
            msg: error.code,
            type: 'error'                  
          },saving:false});

          switch (error.code) {
              case 'storage/unauthorized':
                  // User doesn't have permission to access the object
                  break;
              case 'storage/canceled':
                  // User canceled the upload
                  break;
              case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse
                  break;
              default:
                  console.log('test2');
                  break;
          }
    }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            service.detail.img = downloadURL;
            const data = that.createFeToBe();
            that.props.postService(data, Response => {
              if (Response.code >= 200 && Response.code <= 299) {                
                
                that.setState({saving:false});

                const msg = {
                  show: true,
                  msg: 'Servicio registrado exitosamente',
                  type: 'info',
                  callback: (isOk) =>{
                    that.handleGetServicesUser();
                    that.props.onClose();
                  }
                };
            
                 that.onShowConfirmMessage(msg);
                 //that.onShowMessage(msg);
                
                
              } else {
                that.setState({message: {
                  show: true,
                  msg: (Response && Response.data && Response.data.responseMsg) || 'Ha ocurrido un error, por favor intente de nuevo',
                  type: 'error'                  
                },saving:false});
              }
            });            
        });
      });


      console.log('onSaveService');
    }   
    
    createFeToBe = () =>{
      const {service} = this.state;
      
      let data =  {
        "uid": this.props.user.id,
        "username": this.props.user.username,
        "address": service.detail.address,
        "checkOut": !service.payment.checkinSelected,
        "description": service.detail.detail,
        "serviceDuration": moment().set(
          {hour: service.payment.hour,
            minute: service.payment.minute,
          second: 0})
          .format('HH:mm:ss'),
        "serviceEnable": true,
        "idCategory": service.detail.category.id,
        "idSubCategory": service.detail.subcategory.id,
        "location": {
          "latitude": service.detail.lat,
          "longitude": service.detail.lng
        },
        "nameService": service.detail.name,
        "termsAndConditions": service.terms.terms,
        "servicePicture": [
          {
            "highQuality": service.detail.img,
            "lowQuality": service.detail.img,
            "miniature": service.detail.img
          }
        ],        
        "serviceSchedule": []
      };

      if (service.payment.paymentOption && service.payment.paymentOption.length>0) {
        data['servicePaymentMethod'] = service.payment.paymentOption.map((d)=>{return {enabled:true,"idPaymentMethod": d.id} });
      }
      else {
        data['ftsPayments'] = true;
        data['servicePaymentMethod'] = [{"enabled":false,"idPaymentMethod": ''}];
      }

      for(let i=0; i<service.schedules.length; i++) {
          let schedule = service.schedules[i].schedule;
          let disponibility = service.schedules[i].disponibility;
          let sch = {
            'endTime': moment().set(
              {hour:schedule.toTime.hour,
                minute:schedule.toTime.minute,
                second: 0})
              .format('HH:mm:ss'),
            'startTime': moment().set(
              {hour:schedule.fromTime.hour,
                minute:schedule.fromTime.minute,
                second: 0})
              .format('HH:mm:ss'),
            'scheduleType': schedule.singleChecked?0:1,
            'serviceAvailability': []
          }

          if (schedule.singleChecked) {
            sch['scheduleTypeDate'] = {
              "scheduleIncluded":  schedule.dates
            }
          }
          else {
            sch['scheduleTypeDateRange'] = {
              "endDate": schedule.toDate,
              "friday": schedule.days.filter((day)=>day.id==='friday')[0].checked,
              "monday": schedule.days.filter((day)=>day.id==='monday')[0].checked,
              "saturday": schedule.days.filter((day)=>day.id==='saturday')[0].checked,
              "scheduleExcluded": schedule.excludedDates,
              "startDate": schedule.fromDate,
              "sunday": schedule.days.filter((day)=>day.id==='sunday')[0].checked,
              "thursday": schedule.days.filter((day)=>day.id==='thursday')[0].checked,
              "tuesday": schedule.days.filter((day)=>day.id==='tuesday')[0].checked,
              "wednesday": schedule.days.filter((day)=>day.id==='wednesday')[0].checked,
            }
          }

          for(let j=0; j<disponibility.schedules.length; j++) {
              let disp = disponibility.schedules[j];
              let availability = {
                "nameAvailability": disp.name,
                "quantityPlace": disp.places,
                "quantityUnit": disp.units,
                "rateByUnit": disp.type==='unit',
                "serviceRates":   disp.rates.map((rate)=>{return {'nameRate':rate.name,'price': [{'price': rate.price}] };})              
              }

              sch['serviceAvailability'].push(availability);
          }
          data['serviceSchedule'].push(sch);
      }

      return data;

    }

    render() {
        const { currentStep,steps,pageSize,service, saving, schedule,editSchedule } = this.state;
        return (
          <ServiceWrapper>
            {this.renderMessage()}
            {this.confirmMessage()}
            
            <Text
                clearBoth
                width="100%"
                fontSize= "18px"
                color="#000"
                marginBottom='10px'
                marginLeft="40px"
                fontFamily="Roboto"
                fontWeight="bold">
              Crear servicio
            </Text>

            <Text
                clearBoth
                width="100%"
                fontSize= "10px"
                color="#DA0458"
                marginBottom='10px'
                marginLeft="40px"
                fontFamily="Roboto"
                fontWeight="bold">
              * Campos obligatorios
            </Text>

            <StepPagination
              steps={steps}
              size={pageSize}
              step={currentStep}
              />
            <StepWizard
              onStepChange={this.onStepChange}
              key={JSON.stringify(this.props.user)+'wizard'}
            >


               <Detail //1
                  key={JSON.stringify(this.props.user)+'detail'}
                  coords={DEFAULT_COORD}
                  onShowMessage={this.onShowMessage}
                  onShowConfirmMessage = {this.onShowConfirmMessage}
                  service={service}
                  user = {this.props.user}
                  onChange={this.onDetailChange} 
                  categories={this.props.categories || []}/> 
               <PaymentMethod //2
                key={JSON.stringify(this.props.user)+'payment'}
                onShowMessage={this.onShowMessage}
                onShowConfirmMessage = {this.onShowConfirmMessage}
                service={service}
                user = {this.props.user}
                onChange={this.onPaymenChange}
                paymentOptions={this.props.payments || []}
                /> 
                <Schedule //3
                  key={JSON.stringify(this.props.user)+'schedule'}
                  onShowMessage={this.onShowMessage}
                  onShowConfirmMessage = {this.onShowConfirmMessage}
                  user = {this.props.user}
                  planUser = {this.props.planUser}
                  onChange={this.onScheduleChange}
                  service={service}
                  schedule={schedule}
                  editSchedule={editSchedule}
                  /> 

              <Disponibility //4
                  key={JSON.stringify(this.props.user)+'disponibility'}
                  onShowMessage={this.onShowMessage}
                  onShowConfirmMessage = {this.onShowConfirmMessage}
                  user = {this.props.user}
                  onChange={this.onDisponibilityChange}
                  service={service}
                  schedule={schedule}
                  plan={this.props.planUser}
                  isFibyPay = {this.isFibyPay}
                  />  

                <Resume //5
                  key={JSON.stringify(this.props.user)+'resume'}
                  onShowMessage={this.onShowMessage}
                  onShowConfirmMessage = {this.onShowConfirmMessage}
                  user = {this.props.user}
                  onChange={this.onResumeChange} 
                  service={service}
                  onEditSchedule={this.onEditSchedule}
                  onRemoveSchedule={this.onRemoveSchedule}
                  />
                <Terms //6
                  key={JSON.stringify(this.props.user)+'terms'} 
                  onShowMessage={this.onShowMessage}
                  onShowConfirmMessage = {this.onShowConfirmMessage}
                  saving={saving}
                  service={service}
                  user = {this.props.user}
                  onChange={this.onTermsChange} 
                  onSave={this.onSaveService}/>
            </StepWizard>
          </ServiceWrapper>
        );
    }
}



const ServiceWrapper = styled.div`
  //width: 90%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  //margin: 0 auto;
  background-color: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 1);
  @media (max-width: 768px) { 
    width: 100%;        
    //overflow-y: auto;
    }  
`;

const mapStateToProps = state => {
  return {
    categories: state.generalStore.categories.data,
    payments: state.generalStore.payments.data,
    service: state.servicesStore.service || {},
    planUser: state.planStore.planUser.plan,
    loadingPlan: state.planStore.listPlan.load,
    errorPlan: state.planStore.listPlan.error,    
    user:
      state.accessStore.loginStore.data &&
      state.accessStore.loginStore.data.responseInfo
        ? state.accessStore.loginStore.data.responseInfo.user
        : {},
  };
};

export default connect(
  mapStateToProps,
  {
    getMethodPayment,
    getListPayment,
    getCategories,
    getListCategories,
    createService,
    postService,
    errorCreateService,
    getUserPlan,
    errorPlanUser,
    getDetailPlan,
    detailPlanUser,
    getServicesUser,
    listServicesUser,
    errorListServicesUser         
  },
)(Service);