const START_GET_PLAN_USER = 'START_GET_PLAN_USER';
const DETAIL_GET_PLAN_USER = 'DETAIL_GET_PLAN_USER';
const ERROR_GET_PLAN_USER = 'ERROR_GET_PLAN_USER';
const START_GET_PLAN_lIST = 'START_GET_PLAN_lIST';
const LIST_GET_PLAN = 'LIST_GET_PLAN';
const ERROR_GET_PLAN_LIST = 'ERROR_GET_PLAN_LIST';

export {
  START_GET_PLAN_USER,
  DETAIL_GET_PLAN_USER,
  ERROR_GET_PLAN_USER,
  START_GET_PLAN_lIST,
  LIST_GET_PLAN,
  ERROR_GET_PLAN_LIST,
};
