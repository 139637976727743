import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import { FaGoogle } from 'react-icons/fa';

const Button = styled.div`
  background: ${props => props.backgroundColor || 'linear-gradient(90deg, rgba(51,52,128,1) 0%, rgba(110,0,191,1) 100%)'};
  color: ${props => props.color || '#FFF'};
  font-family: ${props => props.fontFamily || 'Roboto'}, sans-serif;
  font-weight: ${props => props.fontWeight || '400'};
  font-size: 16px;
  width: ${props => props.width || '100%'};
  max-width: ${props => props.maxWidth};
  height: ${props => props.height || '40px'};
  border: ${props => props.border || '2px'};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};
  border-radius: ${props => props.borderRadius || '50px' } ;
  border-bottom-left-radius: ${props => props.borderBottom};
  border-top-left-radius: ${props => props.borderTop};
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  :hover {
    opacity: 0.7;  }
  :active {
    opacity: 0.1;
  }
  @media (max-width: ${props => props.sizeSm || '480px'}) {
    width: ${props => props.widthB || '280px'};
  }
`;

const Container = styled.div`
  max-width: ${props => props.maxWidth};
  height: ${props => props.height || '40px'};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${props => props.sizeSm || '480px'}) {
    width: ${props => props.widthB || '280px'};
  }
`;

const ButtonLoader = props =>
  props.loading ? (
    <Container {...props}>
      <CircularProgress />
    </Container>
  ) : (
    <Button {...props}>
      {props.icon ? (
        <div style={{ marginRight: '15px', marginTop: '4px' }}>
          <FaGoogle size={20} />
        </div>
      ) : null}

      {props.children}
    </Button>
  );
export default ButtonLoader;
