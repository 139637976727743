import { API } from '../../api';

const getUserId = (data, onSuccess) => () => {
  API.get(`/users/${data.uid}`, {
    headers: {
      token: data.token,
      uid: data.uid,
      idChannel: 'web',
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

const putUserId = (data, onSuccess) => () => {
  API.put(`/users/${data.uid}`, {
    headers: {
      token: data.token,
      uid: data.uid,
      idChannel: 'web',
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
}

const putEditProfilePictureUser = (data, onSuccess) => () => {
  API.put('/users/profile-picture/', null, {
    headers: {
      token: localStorage.getItem('token_get_get_it'),
      profilePicturehighQuality: data.profilePicturehighQuality,
      uidLoggedIn: data.uid,
      idChannel: 'web',
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

const putEditProfileCoverUser = (data, onSuccess) => () => {
  API.put('/users/cover-picture/', null, {
    headers: {
      token: localStorage.getItem('token_get_get_it'),
      coverPicturehighQuality: data.coverPicturehighQuality,
      uidLoggedIn: data.uid,
      idChannel: 'web',
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (response.code >= 200 && response.code <= 299) {
      onSuccess(response);
    } else {
      onSuccess(response);
    }
  });
};

export { getUserId, putEditProfilePictureUser, putEditProfileCoverUser, putUserId, };
