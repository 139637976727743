import * as types from './types';

const getCountries = data => {
  return {
    type: types.GET_LIST_COUNTRIES,
    payload: {
      data,
    },
  };
};

const getState = data => {
  return {
    type: types.GET_LIST_STATE,
    payload: {
      data,
    },
  };
};

const getlistGender = data => {
  return {
    type: types.GET_LIST_GENDER,
    payload: {
      data,
    },
  };
};

const getListCategories = data => {
  return {
    type: types.GET_LIST_CATEGORIES,
    payload: {
      data,
    },
  };
};

const getListPayment = data => {
  return {
    type: types.GET_LIST_PAYMENTS,
    payload: {
      data,
    },
  };
};

export {
  getCountries,
  getState,
  getlistGender,
  getListCategories,
  getListPayment,
};
