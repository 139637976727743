import styled from 'styled-components';

const ColumnWrap = styled.div`
  display: grid;
  grid-template-columns: ${props => props.col || '1fr 1fr'};
  grid-template-rows: ${props => props.rows};
  justify-items: ${props => props.justifyItems};
  align-items: ${props => props.alignItems};
  grid-gap: ${props => props.gridGrap || '10px'};
  width: ${props => props.width || '100%'};
  height: ${props => props.height};
  max-width: ${props => props.maxWidth};
  margin-top: ${props => props.marginTop};
  margin: ${props => props.marginx};
  padding: ${props => props.padding};
  margin-bottom: ${props => props.marginBottom};
  justify-items: ${props => props.justifyItems};
  background: ${props => props.backgroundColor};
  border-bottom: ${props => props.borderBottom};
  border-right: ${props => props.borderRight};
  border-top: ${props => props.borderTop};
  border-left: ${props => props.borderLeft};
  min-height: ${props => props.minHeight};
  align-items: ${props => props.alignItems};
  /* ------------Responsive------------ */
  @media (max-width: ${props => props.sizeSm || '992px'}) {
    grid-template-columns: ${props => props.colN};
  }
  @media (max-width: ${props => props.sizeSm || '768px'}) {
    grid-template-columns: ${props => props.colSm};
    justify-items: ${props => props.justifyItemsSm};
  }
  @media (max-width: ${props => props.sizeXs || '620px'}) {
    grid-template-columns: ${props => props.colXs};
    width: ${props => props.widthMy};
  }
  @media (max-width: ${props => props.sizeXs || '420px'}) {
    grid-template-columns: ${props => props.colXs};
    width: ${props => props.widthMx};
  }
`;

export default ColumnWrap;
