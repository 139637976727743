import * as types from './types';

const initialState = {
  form: {}
};

export default function applicationFormStore(state = initialState, action) {
  switch (action.type) {
    case types.ONCHANGE_FORM:
      return {
        ...state,
        form: action.payload.obj,
      };
    default:
      return state;
  }
}
