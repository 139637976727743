import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Login from './access/Login';
import RecoveryPassword from './access/RecoveryPassword';
import ChangePassword from './access/ChangePassword';
import Register from './access/Register';
import Dashboard from './home/Dashboard';


const Routes = ({ token, userStatus }) => (
  <div className="Routes">
    <Router>
      <div className="container">
        <Route exact path="/" component={Login} />
        <Route exact path="/recovery-password" component={RecoveryPassword} />
        <Route exact path="/change-password" component={ChangePassword} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/dashboard" component={Dashboard} />
      </div>
    </Router>
  </div>
);


export default Routes;
