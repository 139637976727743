import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Text from '../../../../components/generics/Text';
import {IconUp, IconDown} from '../Icons';
const Select = (props) => {
  
  const [selected, setSelected] = useState(props.selected || []);
  const [opened, setOpened] = useState(props.opened || false);
  const [multi, setMulti] = useState(props.multi || false);


  useEffect (() => {
    props.onOpen && props.onOpen(opened);
  },[opened]);  

  useEffect(() => setOpened(props.opened), [props.opened]);

  useEffect (() => {
    if (selected!==null) {
      props.onChange && props.onChange(selected);
    }
    
  },[selected]);

  const onSelectItem = (item) => {
    if (multi) {
      const found = selected.findIndex((d)=>item.id===d.id);
      if (found<0) {
        setSelected([...selected,item]);
      }
      else {
        let newSelected = [...selected];
        newSelected.splice(found,1);
        setSelected(newSelected);
      }
    }
    else {
      setSelected([item]);
      setOpened(false);
    }
    
  };

  console.log(selected);
  
  return(<Wrapper>
    <Input
      onClick={()=>{setOpened(!opened);}}
      fontColor= "#808080"
      placeholder={selected && selected.length>0?selected.map((s)=>s.text).join(' , '):props.placeholder}
      selected= {selected && selected.length>0?true:false} >
      <span style={{'position':'absolute','right':'2px'}}>
      {opened? 
        <IconUp  borderRadius='50%' onClick={()=>{setOpened(!opened);}}/>
        :<IconDown  borderRadius='50%'  onClick={()=>{setOpened(!opened);}} />}
      </span>
        
    </Input>
    <div style={{position: 'absolute',width:'100%',maxWidth: '100%'}}>
      <UlX
        opened={opened}
      >
        {props.items  && props.items.map((item)=>
          <LiX
            key={item.id}
            onClick = {()=>{onSelectItem(item);}}
          >

            <ExtText
              selected={selected && (selected.findIndex((d)=>item.id===d.id) >=0)?true:false}
              variant = {item.backgroundColor || '#808080'}
              border={`1px solid ${item.backgroundColor || '#808080'}`}  
              borderRadius='50px'            
              inline
              color= "#808080"
              fontFamily="Roboto">
              {item.text}
            </ExtText>
          </LiX>)}
        {props.items  && (props.items.length%2)!==0?<LiX />:''}
      </UlX>
    </div>


  </Wrapper>
  );};

const Wrapper = styled.div`
    margin-top: 5px;
    margin-bottom: 5px;
    display: block;
    width: 100%;
    position: relative;
`;

const UlX = styled.div`
    display: ${props => props.opened ? 'flex':'none' };
    flex-direction: row;
    flex-wrap: wrap;
    transition: all 100ms ease 0s;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid #d5d5d5;
    border-top: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 9999;
    padding: 2px;
    justify-content: space-between;
    position: relative;
    top: 0;
    width: ${props => props.textWidth || '100%' };
    max-width: ${props => props.textWidth || '100%' };
`;

const LiX = styled.div`
    flex: 45%;
    margin: auto;
    padding: 2px;
    height: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    position: relative;
    :hover {
        cursor: pointer;
    }
`;

const Input = styled.div`
    border-radius: ${props => props.textBorderRadius || '5px' };
    border: ${props => props.textBorder || '1px solid #b7b8b8' };
    width: ${props => props.textWidth || '100%' };
    max-width: ${props => props.textWidth || '100%' };
    height: ${props => props.textHeight || '25px' };
    color: ${props => props.fontColor};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
    :hover {
        cursor: pointer;
    };
    &:before {
        content: '${props => props.placeholder}';
        width: 90%;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;           
        font-family: ${props => props.selected?'Roboto':'Roboto-LightItalic'};
        font-size: 14px;
        color: #808080;
        padding: 2px;
    }
`;

const ExtText = styled(Text)`
    background-color: ${props => props.selected? props.variant: '#fff' };
    border-radius: ${props => props.borderRadius};
    border: ${props => props.border  };
    padding: 5px;
    width: 80%;
    text-align: center;
    color: ${props => props.selected? '#333480':props.color };
    &:after {
      content: '✓';
      display: ${props => props.selected? 'inline-block':'none' };
      color: #fff;
      position: absolute;
      left: 73%;
      top: 20%;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: #6e00be; 
    }
`;

export default Select;
