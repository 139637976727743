import { combineReducers } from 'redux';
import * as types from './types';

const initialState = {
  list: [],
  load: false,
  error: '',
  services: {
    serviceSchedule: []
  }
};

const initialStateDetail = {
  detail: {},
  load: false,
  error: '',
};

const initialStateService = {
  service: {}
};

const servicesUser = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_SERVICES_USER:
      return {
        list: action.payload.list,
        error: '',
        load: action.payload.load,
      };
    case types.GET_ERROR_LIST_SERVICES_USER:
      return {
        error: action.payload,
        load: false,
        list: [],
      };
    default:
      return state;
  }
};

const detailServices = (state = initialStateDetail, action) => {
  switch (action.type) {
    case types.GET_START_DETAIL_SERVICES_USER:
      return {
        detail: {},
        error: '',
        load: action.payload,
      };
    case types.GET_DETAIL_SERVICES_USER:
      return {
        detail: action.payload.detail,
        error: '',
        load: action.payload.load,
      };
    case types.GET_ERROR_DETAIL_SERVICES_USER:
      return {
        error: action.payload,
        load: false,
        detail: {},
      };
    case types.POST_SERVICES_USER:
      return {
        detail: action.payload.detail,
        error: '',
        load: action.payload.load,
      };
    case types.POST_ERROR_SERVICES_USER:
      return {
        error: action.payload,
        load: false,
        detail: {},
      };      
    default:
      return state;
  }
};

const createServices = (state = initialStateService, action) => {
  switch (action.type) {    
    case types.POST_SERVICES_USER:
      return {
        service: action.payload.service,
        error: ''
      };
    case types.POST_ERROR_SERVICES_USER:
      return {
        error: action.payload,
        load: false
      };      
    default:
      return state;
  }
};

const servicesStore = combineReducers({
  servicesUser,
  detailServices,
  createServices
});

export default servicesStore;
