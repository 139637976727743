export const lightGray='#f2f2f2';
export const darkGray ='#808080';
export const lightBlue= '#DA0458';
export const white= '#fff';
export const black= '#000';
export const greenApple= '#6E00BF';
export const warning= '#FF6600';
export const info= '#477dbc';
export const ok= '#6E00BF';
export const blue= '#DA0458';
export const green= '#6E00BF';
export const orange= '#1d4e88';
export const red= '#FF0000';
export const purple= '#333480';
