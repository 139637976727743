import React from 'react';
import styled from 'styled-components';

const SteppersDots = props => {
  return (
    <Container>
            
      {
        props.step1 ?
          <Dots backgroundColor="#66BAFF" size="10px"/>
          :
          <Dots/>
      }
      {
        props.step2 ?
          <Dots backgroundColor="#66BAFF" size="10px"/>
          :
          <Dots/>
      }
      {
        props.step3 ?
          <Dots backgroundColor="#66BAFF" size="10px"/>
          :
          <Dots/>
      }
    </Container>
  );
};
const Container = styled.div`
    display: flex;
    width: 50px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
`;
const Dots = styled.div`
    width: ${props => props.size || '6px'};
    height: ${props => props.size || '6px'};
    background-color: ${props => props.backgroundColor || '#E5E5E5'};
    border-radius: 5px
`;

export default SteppersDots;
