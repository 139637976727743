import React, { Component } from 'react';
import styled from 'styled-components';

class ButtonCategory extends Component {
  state = {
    active: false,
  };

  handleClickActivite = value => () => {
    this.setState({
      active: true,
    });
    this.props.onChange(value);
  };

  render() {
    return (
      <Container
        backgroundColor={
          this.props.id === this.props.item.id
            ? this.props.backgroundColor
            : null
        }
        onClick={this.handleClickActivite(this.props.item)}
        borderColor={this.props.backgroundColor}
        color={this.props.id === this.props.item.id ? '#FFF' : null}
      >
        {this.props.children}
      </Container>
    );
  }
}
const Container = styled.button`
  background-color: ${props => props.backgroundColor || 'transparent'};
  border-color: ${props => props.borderColor};
  border-style: solid;
  border-width: 1px;
  color: ${props => props.color || '#000'};
  font-family: ${props => props.fontFamily || 'Roboto'}, sans-serif;
  font-weight: ${props => props.fontWeight || '400'};
  font-size: 14px;
  width: ${props => props.width || '100%'};
  max-width: ${props => props.maxWidth};
  min-height: ${props => props.height || '30px'};
  border: ${props => props.border};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};
  border-radius: ${props => props.borderRadius || '20px'};
  border-bottom-left-radius: ${props => props.borderBottom};
  border-top-left-radius: ${props => props.borderTop};
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  :hover {
    opacity: 0.8;
  }
  :active {
    opacity: 0.1;
  }
`;

export default ButtonCategory;
