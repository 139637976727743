import React, { Component } from 'react';
import styled from 'styled-components';
import Text from '../generics/Text';
import { MdCheckCircle } from 'react-icons/md';

class ItemsCategory extends Component {
  state = {
    active: false,
  };

  handleClickActivite = value => () => {
    this.setState({
      active: true,
    });
    this.props.onChange(value);
  };

  render() {
    return (
      <Container
        className="opacity"
        onClick={this.handleClickActivite(this.props.item)}
      >
        <Text fontFamily="Roboto" color="#cbcbcb" fontSize="14px">
          {this.props.label}
        </Text>
        <WrapIcon>
          {this.props.id === this.props.item.id ? (
            <MdCheckCircle color="#66bbff" fontSize="20px" />
          ) : null}
        </WrapIcon>
      </Container>
    );
  }
}
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 20px;
`;
const WrapIcon = styled.div`
  /* display: flex;
  flex-wrap: wrap; */
  margin-left: 10px;
`;
export default ItemsCategory;
