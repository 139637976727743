import React, { Component } from 'react';
import styled from 'styled-components';
// import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/auth';
import Text from '../../components/generics/Text';
import Input from '../../components/generics/Input';
import ButtonLoader from '../../components/generics/Button';
import { applicationForm } from '../../ducks/changeForm';
import {
  getEmail,
  getDetailUser,
  getErrorDetailUser,
} from '../../ducks/access';
import ArrowBack from '../../components/generics/ArrowBack';
import lang from '../../lang'

class RecoveryPassword extends Component {
  constructor(props) {
    super(props);
    const obj = {
      email: props.detailUser && props.detailUser.email,
      errorEmail: '',
      message: '',
      errorMessage: '',
      loadMessage: false,
    };
    props.applicationForm(obj);
    props.getDetailUser(this.props.detailUser, false);
    this.state = {
      scene: 'emailError',
    };
  }

  handleOnchangeEmail = (prop, errorProp) => event => {
    const onChangeForm = JSON.stringify(this.props.formChange);
    const parseChangeForm = JSON.parse(onChangeForm);
    parseChangeForm[prop] = event.target.value;
    parseChangeForm[errorProp] = '';
    const obj = {
      ...parseChangeForm,
    };
    this.props.applicationForm(obj);
  };

  handleClickEmail = () => {
    const form = JSON.stringify(this.props.formChange);
    const formParse = JSON.parse(form);
    const validate = this.validate(formParse);
    if (validate) {
      this.setState({
        errorMessage: '',
        loadMessage: true,
      });
      this.props.getDetailUser({}, true);
      this.props.getEmail(this.props.formChange.email, Response => {
        if (Response.code >= 200 && Response.code <= 299) {
          if (Response.data !== '') {
            if (
              Response.data.responseInfo.idUserType === 1 ||
              Response.data.responseInfo.idUserType === 2
            ) {
              firebase
                .auth()
                .sendPasswordResetEmail(this.props.formChange.email)
                .then(result => {
                  this.setState({
                    message: lang.weHaveSentYouAnEmail,
                    loadMessage: false,
                    errorMessage: '',
                  });
                  this.props.getDetailUser({}, false);
                })
                .catch(e => {
                  this.props.getDetailUser({}, false);
                  this.setState({
                    errorMessage: e.message,
                    loadMessage: false,
                    message: '',
                  });
                  this.props.getDetailUser(this.props.detailUser, false);
                });
            } else {
              const form = JSON.stringify(this.props.formChange);
              const formParse = JSON.parse(form);
              formParse.errorEmail = lang.exclusiveIncomeForSuppliers;
              const obj = {
                ...formParse,
              };
              this.props.applicationForm(obj);
              this.props.getDetailUser(this.props.detailUser, false);
            }
          } else {
            const form = JSON.stringify(this.props.formChange);
            const formParse = JSON.parse(form);
            formParse.errorEmail = lang.emailDoesNotExist;
            const obj = {
              ...formParse,
            };
            this.props.applicationForm(obj);
            this.props.getDetailUser(this.props.detailUser, false);
          }
        } else {
          this.setState({
            errorMessage: Response.message,
          });
          this.props.getDetailUser(this.props.detailUser, false);
        }
      });
    }
  };

  validate = data => {
    if (!data) {
      return false;
    }
    if (!data.email) {
      data.errorEmail = lang.theFieldIsRequired;
      const errors = {
        ...data,
      };
      this.props.applicationForm(errors);
      return false;
    }
    if (data.email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(data.email).toLowerCase())) {
        data.errorEmail = lang.enterValidEmailAddress;
        const errors = {
          ...data,
        };
        this.props.applicationForm(errors);
        return false;
      }
    }
    return true;
  };

  render() {
    return (
      <Background>
        <HeaderLogo src="/assets/images/logo-small.svg" alt="logo" />
        <div className="total-center" style={{ height: '70vh' }}>
          <div>
            <Text fontSize="32px" color="#FFF" textAlign="center">
              {lang.AchieveWhatYouImagineToReach}
            </Text>
            <Text
              marginTop="10px"
              fontFamily="Metropolis-Medium"
              fontSize="22px"
              color="#FFF"
              textAlign="center"
            >
              {lang.OfferYourServices}
            </Text>
            <FormWrap>
              <div className="total-center">
                <img
                  src="/assets/images/logo.svg"
                  alt="logo"
                  width="auto"
                  height="35px"
                />
              </div>
              <ArrowBack onClick={() => this.props.history.push('/')} />
              {this.state.message ? (
                <div
                  className="total-center"
                  style={{ height: '100%', width: '100%' }}
                >
                  {this.state.loadMessage ? (
                    <div className="total-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    <Text
                      fontSize="18px"
                      color="#FFF"
                      textAlign="center"
                      width="100%"
                      maxWidth="370px"
                      fontFamily="Roboto"
                      marginBottom="30px"
                    >
                      {this.state.message}{' '}
                      <span
                        style={{
                          fontWeight: 'bold',
                          color: '#000',
                          cursor: 'pointer',
                        }}
                        onClick={this.handleClickEmail}
                      >
                        {lang.here}
                      </span>
                    </Text>
                  )}
                </div>
              ) : (
                <AlignInfo>
                  <Text
                    fontSize="15px"
                    color="#FFF"
                    textAlign="left"
                    width="100%"
                    maxWidth="370px"
                    fontFamily="Roboto"
                    marginBottom="30px"
                  >
                    {lang.restorePassword}
                  </Text>
                  <Input
                    placeholder={lang.email}
                    value={
                      this.props.formChange && this.props.formChange.email
                        ? this.props.formChange.email
                        : ''
                    }
                    onChange={this.handleOnchangeEmail('email', 'errorEmail')}
                    message={
                      this.props.formChange && this.props.formChange.errorEmail
                    }
                    error={
                      this.props.formChange && this.props.formChange.errorEmail
                    }
                  />
                  {this.state.errorMessage ? (
                    <div style={{ marginTop: '5px' }}>
                      <p style={{ color: 'red', textAlign: 'center' }}>
                        {this.state.errorMessage}
                      </p>
                    </div>
                  ) : null}
                  {this.props.loadingDetail ? (
                    <div className="total-center" style={{ marginTop: '20px' }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <ButtonLoader
                      maxWidth="370px"
                      marginTop="10px"
                      onClick={this.handleClickEmail}
                    >
                      {lang.send}
                    </ButtonLoader>
                  )}
                </AlignInfo>
              )}
            </FormWrap>
          </div>
        </div>
      </Background>
    );
  }
}

const mapStateToProps = state => {
  return {
    formChange: state.changeForm.form,
    detailUser: state.accessStore.detailStore.data,
    loadingDetail: state.accessStore.detailStore.load,
  };
};

export default connect(
  mapStateToProps,
  { applicationForm, getEmail, getDetailUser, getErrorDetailUser },
)(RecoveryPassword);

const Background = styled.div`
  background-image: url('/assets/images/background.png');
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100vh;
`;
const HeaderLogo = styled.img`
  width: auto;
  height: 45px;
  position: relative;
  margin-top: 25px;
  margin-left: 40px;
  @media (max-width: 480px) {
    height: 25px;
  }
`;
const FormWrap = styled.div`
  margin-top: 20px;
  background: linear-gradient(#1e1e1e, #1e1e1e) padding-box,
  linear-gradient(90deg, rgba(51,52,128,1) 0%, rgba(110,0,191,1) 100%) border-box;
  background-opacity: 0.1;
  /* width: calc(100% - 40px); */
  width: 100%;
  max-width: 460px;
  height: 340px;
  border-radius: 5px;
  border: 5px solid transparent;
  border-width: 2px;
  padding: 20px;
  padding-top: 40px;
  @media (max-width: 480px) {
    width: 80vw;
  }
`;
const AlignInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90%;
`;
